/* base styles*/
*, *:before, *:after {
  box-sizing: border-box !important;
  outline: none;
}

html {
  height: 100%;
}

body {
  font-family: "RobotoRegular", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  height: 100%;
  /* background-image: url("/images/other/main-bg.png"); */
  background-color: #f0f2f5;
  /* background-color: #eaf7fb; */
  background-repeat: repeat;
  margin: 0;
}

body.lock {
  /* touch-action: none !important; */
  /* position: fixed; */
  overflow: hidden;
  /* width: 100%; */
}

.global-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

svg path {
  transition: 0.3s;
}

a {
  color: #535353;
  text-decoration: none;
  transition: 0.3s;
}
a:hover {
  color: #507DAC;
}

img {
  display: block;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.video-wrap {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.video-wrap iframe,
.video-wrap video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main {
  flex: 1 1 auto;
}

.container {
  position: relative;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.container--bigger {
  max-width: 1440px;
}

.line {
  max-width: 310px;
  width: 100%;
  height: 1px;
  background-color: #FFFFFF;
}
@media (max-width: 500px) {
  .line {
    flex: 1 0 100%;
    position: relative;
  }
}

.blue {
  color: #2394E7;
}

.dark-blue {
  color: #2B4764;
}

.input-wrap {
  position: relative;
}
.input-wrap input {
  color: #2B4764;
  font-size: 18px;
}
.input-wrap input::-moz-placeholder {
  font-size: 14px;
}
.input-wrap input:-ms-input-placeholder {
  font-size: 14px;
}
.input-wrap input::placeholder {
  font-size: 14px;
}
.input-wrap input:focus {
  border: 2px solid #2B4764 !important;
}

button {
  background-color: unset;
  border: none;
}

.create-order.disabled {
  background: #BCBCBC !important;
  pointer-events: none;
}

.btn-share {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  background-color: #2B4764;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  -moz-column-gap: 4px;
  column-gap: 4px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  padding: 0 20px;
}
@media (max-width: 1150px) {
  .btn {
    padding: 0 10px;
  }
}
.btn span {
  text-transform: lowercase;
}
.btn_arapawa {
  background-color: #2B4764;
  color: #ffffff;
}
.btn_arapawa:hover {
  background-color: #6592C1;
  color: #ffffff;
}
.btn_matterhorn {
  color: #535353;
  border: 1px solid #535353;
}
.btn_matterhorn:hover {
  background-color: #9FB5CB;
  color: #535353;
}
.btn_white {
  color: #ffffff;
  border: 1px solid #ffffff;
}
.btn_dodgerBlue {
  color: #ffffff;
  background-color: #2394E7;
}
.btn_transparent {
  color: #2B4764;
  border: 1px solid #2B4764;
}

.title h1, .title h2,
.title h3, .title h4 {
  font-family: "RobotoBold";
  color: #2B4764;
  margin-top: 0;
}
.title_white h1, .title_white h2, .title_white h3, .title_white h4 {
  color: #ffffff;
}
.title h1 {
  font-size: 47px;
  line-height: 52px;
}
@media (max-width: 1000px) {
  .title h1 {
    font-size: 35px;
    line-height: 40px;
  }
}
@media (max-width: 500px) {
  .title h1 {
    font-size: 28px;
    line-height: 33px;
  }
}

.title h2 {
  font-size: 36px;
  line-height: 140%;
}

@media (max-width: 480px) {
  .title h2 {
    font-size: 22px;
  }
}
.title h3 {
  font-size: 18px;
  line-height: 25px;
}
.title_col-matterhorn h2 {
  color: #535353;
}

.section-title {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.section-title__number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 35px;
  padding: 6px;
  font-family: "RobotoLight";
  font-size: 18px;
  background-color: #2B4764;
  color: #ffffff;
}
.section-title__bg-column {
  width: 12px;
  height: 35px;
  background-color: #2B4764;
}
.section-title h1,
.section-title h2 {
  font-family: "RobotoMedium";
  font-size: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #2B4764;
  margin: 0 36px 0 18px;
}
@media (max-width: 500px) {
  .section-title h1,
.section-title h2 {
    margin: 0 18px 0 18px;
    letter-spacing: 0;
  }
}
.section-title .line {
  max-width: 340px;
  background-color: #2B4764;
}
.section-title_white h1, .section-title_white h2 {
  color: #ffffff;
}
.section-title_white .section-title__number {
  color: #2B4764;
  background-color: #FFFFFF;
}
.section-title_white .line {
  background-color: #FFFFFF;
}
.section-title__right {
  justify-content: flex-end;
}
.section-title__right h1,
.section-title__right h2 {
  margin: 0 18px 0 36px;
}

.share-svg {
  display: flex;
  justify-content: center;
  -moz-column-gap: 10px;
  column-gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  cursor: auto !important;
  transition: all 0.7s ease 0s;
}
@media(any-hover:hover){
  .share-svg {
    left: -100%;
  }
  .row-share:hover .share-svg {
    left: 0;
  }
}
.share-svg a {
  padding: 0 !important;
}
.share-svg li {
  background-color: #fff !important;
}
.share-svg svg {
  fill: #9FB5CB;
  width: 30px;
  height: 30px;
}
.share-svg svg:hover {
  fill: #2B4764;
}

.row-share {
  position: relative;
  overflow: hidden;
}


.share-profile {
  position: relative;
}
.share-profile .share-profile-btn {
  transition: all 0.4s ease 0s;
}
.share-profile .share-profile-btn svg {
  fill: #2B4764;
}
.share-profile .share-profile-btn:hover {
  background-color: #2B4764 !important;
}
.share-profile .share-profile-btn:hover svg {
  fill: #fff;
}
.share-profile.active .share-profile-btn {
  background-color: #2B4764 !important;
}
.share-profile.active .share-profile-btn svg {
  fill: #fff;
}
.share-profile .share-profile-list.active {
  transform: scale(1);
}

.share-profile-list {
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 10px;
  position: absolute;
  background: #FFFFFF;
  top: -6px;
  left: calc(100% + 10px);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 6px;
  transform: scale(0, 1);
  transform-origin: left;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767px) {
  .share-profile-list {
    left: auto;
    right: calc(100% + 10px);
    transform-origin: right;
    -moz-column-gap: 5px;
    column-gap: 5px;
  }
}
.share-profile-list svg {
  fill: #9FB5CB;
  width: 40px;
  height: 40px;
}
.share-profile-list svg:hover {
  fill: #2B4764;
}
.share-profile-list a {
  display: flex;
}

/*fonts*/
@font-face {
  font-family: "RobotoLight";
  src: url("/fonts/Roboto-Light.eot");
  src: url("/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("/fonts/Roboto-Light.woff") format("woff"), url("/fonts/Roboto-Light.ttf") format("truetype"), url("/fonts/Roboto-Light.svg#RobotoLight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RobotoRegular";
  src: url("/fonts/Roboto-Regular.eot");
  src: url("/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/Roboto-Regular.woff") format("woff"), url("/fonts/Roboto-Regular.ttf") format("truetype"), url("/fonts/Roboto-Regular.svg#RobotoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RobotoMedium";
  src: url("/fonts/Roboto-Medium.eot");
  src: url("/fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/Roboto-Medium.woff") format("woff"), url("/fonts/Roboto-Medium.ttf") format("truetype"), url("/fonts/Roboto-Medium.svg#RobotoMedium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RobotoBold";
  src: url("/fonts/Roboto-Bold.eot");
  src: url("/fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Roboto-Bold.woff") format("woff"), url("/fonts/Roboto-Bold.ttf") format("truetype"), url("/fonts/Roboto-Bold.svg#RobotoBold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/*/fonts*/
/* /base styles */