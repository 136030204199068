.listing {
  flex: 0 0 60%;
  padding: 30px 30px 0 25px;
}
@media (min-width: 1600px) {
  .listing {
    flex: 0 0 975px;
  }
}
.listing .grid-col {
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
}
@media (min-width: 630px) {
  .listing .grid-col {
    grid-template-columns: repeat(auto-fit, minmax(260px, 0.5fr));
  }
}
@media (min-width: 768px) {
  .listing .grid-col {
    grid-template-columns: repeat(auto-fit, minmax(260px, 0.5fr));
  }
}
@media (min-width: 1010px) {
  .listing .grid-col {
    grid-template-columns: repeat(auto-fit, minmax(260px, 0.5fr));
  }
}
@media (min-width: 1200px) {
  .listing .grid-col {
    grid-template-columns: repeat(auto-fit, minmax(290px, 0.5fr));
  }
}
.listing .faq__section-title {
  margin-right: -30px !important;
  margin-left: -30px !important;
}

.wants-map {
  position: relative;
  z-index: 10;
}

.listing-map {
  flex: 0 1 100%;
  position: fixed;
  z-index: 5;
  right: 0;
  bottom: 0;
  width: 40%;
  height: calc(100% - 100px);
}
@media (min-width: 1600px) {
  .listing-map {
    width: calc(100% - 975px);
  }
}
@media (max-width: 1023px) {
  .listing-map {
    height: calc(100% - 60px);
  }
}
@media (max-width: 1024px) {
  .listing-map {
    width: 100%;
  }
  .map-block.active {
    display: block;
  }
  .map-block {
    display: none;
  }
}

.listing-search .select__body {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}
.listing-search .select__body::before {
  content: "";
  position: absolute;
  right: 16px !important;
  top: 0px;
  display: block;
  width: 15px;
  height: 100%;
  background: url("/images/listing/arrow-gray.svg") center no-repeat;
  border: none;
  margin-right: 0;
  transform: rotate(0);
  transition: all 0.4s ease 0s;
  pointer-events: none;
}
.listing-search .select__body.active::before {
  transform: rotate(0);
  right: 0px;
  top: 0px;
}
.listing-search .select__field {
  text-align: left;
  width: 100%;
  background: transparent;
  border: none;
  font-family: "RobotoMedium";
  font-size: 14px;
  line-height: 140%;
  color: #535353;
  padding: 6px 15px 6px 16px;
  padding: 0;
  padding-left: 15px;
  min-height: 40px;
}
.listing-search .select__field--placeholder {
  color: #2B4764;
  font-size: 14px;
}
.listing-search .select__values {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
}
.listing-search .select__options {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 200px;
  border: none;
  margin-top: 1px !important;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}
.listing-search .select__option {
  text-align: left;
  background: #F8F8F8;
  border: none;
  font-family: "RobotoMedium";
  font-size: 14px;
  line-height: 140%;
  color: #535353;
  padding: 6px 8px;
  min-height: 40px;
  transition: font-weight 1s ease 0s, background 0.5s ease 0s;
}
@media (min-width: 576px) {
  .listing-search .select__option {
    padding: 6px 15px 6px 16px;
  }
}
.listing-search .select__option.active {
  color: #2F2F2F;
  background: #DCE6E8;
}
.listing-search .select__option:hover {
  background: #DCE6E8;
}
.listing-search .select__placeholder {
  color: #535353;
}

.filter-btn {
  display: block;
  padding: 10px 0;
  flex: 0 0 auto;
  position: relative;
  min-height: 40px;
}
@media (min-width: 576px) {
  .filter-btn {
    flex: 0 1 210px;
  }
}
.filter-btn::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 40px;
  height: 40px;
  background: url("../images/listing/filter.svg") center/25px 25px no-repeat;
}

.filter-field {
  display: block;
  background: #fff;
  border: 1px solid #E62D2D;
  border-radius: 33px;
  position: relative;
  border-color: transparent;
  padding: 10px 42px 10px 20px !important;
  transition: all 0.4s ease 0s;
}
.filter-field::before, .filter-field::after {
  content: "";
  display: block;
  position: absolute;
  right: 20px;
  background: #E62D2D;
  width: 11px;
  height: 1px;
  transition: all 0.4s ease 0s;
}
.filter-field::before {
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
}
.filter-field::after {
  top: 50%;
  transform: translate(0, -50%) rotate(-45deg);
}
.filter-field:hover {
  border: 1px solid #E62D2D;
  color: #E62D2D;
}
.filter-field:hover::before {
  transform: translate(0, -50%) rotate(45deg) scale(1.6);
}
.filter-field:hover::after {
  transform: translate(0, -50%) rotate(-45deg) scale(1.6);
}

.clear-all {
  padding: 10px 20px;
}

.faq-listing {
  background-color: #ebedf1 ;
}

.faq-listing.visible {
  margin: 0 -30px 0 -30px;
  background: url("../images/listing/img.png") 0 0/cover no-repeat;
}
.faq-listing .list-question {
  max-width: 405px;
  width: 100%;
  list-style: square;
  padding: 30px 0 70px;
  margin: 0 auto;
  background: #FBFBFB;
}
@media (min-width: 992px) {
  .faq-listing .list-question {
    margin: 0 50px 0 auto;
  }
}
.faq-listing .list-question .question.active .question__text {
  background-color: #DCE6E8;
}
.faq-listing .list-question .question:hover .question__text {
  background-color: #DCE6E8;
}
.faq-listing .list-question .question__text {
  position: relative;
  display: flex;
  align-items: unset;
  width: 100%;
  text-align: unset;
  padding: 15px 53px 15px 70px;
  background-color: unset;
  color: #414141;
}
@media (max-width: 700px) {
  .faq-listing .list-question .question__text {
    padding: 15px 20px 15px 40px;
  }
}
.faq-listing .list-question .question__text:before {
  content: "";
  position: absolute;
  top: 22px;
  left: 40px;
  width: 5px;
  height: 5px;
  background-color: #535353;
}
@media (max-width: 700px) {
  .faq-listing .list-question .question__text:before {
    left: 20px;
  }
}
.faq-listing .list-question .question__answer {
  font-family: "RobotoLight";
  padding: 0 110px 0 80px;
  color: #414141;
}
@media (max-width: 700px) {
  .faq-listing .list-question .question__answer {
    padding: 0 20px 0 40px;
  }
}

.filter-overlay {
  position: absolute;
  left: -30px;
  top: 0;
  width: calc(100% + 60px);
  height: 100%;
  background: rgba(236, 237, 241, 0.8);
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
}
.filter-overlay.active {
  opacity: 1;
  visibility: visible;
}
.filter-overlay.active .filter-settings {
  left: 0;
}

.filter-settings {
  max-width: 490px;
  position: relative;
  left: -100px;
  /* padding: 20px 30px 0; */
  min-height: 100%;
  background-color: #ECEDF1;
  transition: left 0.6s ease 0s;
}
.filter-settings .select__body {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}
.filter-settings .select__body::before {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 40px;
  height: 100%;
  background: #2B4764;
  border-bottom: none;
  border-right: none;
  margin-right: 0;
  transform: rotate(0deg);
  transition: all 0.4s ease 0s;
  pointer-events: none;
}
.filter-settings .select__body::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 40px;
  height: 100%;
  background: url("../images/profile/listing/select-arrow.svg") center no-repeat;
  transition: all 0.4s ease 0s;
  pointer-events: none;
}
.filter-settings .select__body.active::before {
  transform: rotate(0deg);
  right: 0px;
  top: 0px;
}
.filter-settings .select__body.active::after {
  transform: rotate(180deg);
}
.filter-settings .select__field {
  text-align: left;
  width: 100%;
  min-height: 40px;
  background: rgba(255, 255, 255, 0.74);
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 18px;
  font-family: "RobotoRegular";
  line-height: 140%;
  color: #535353;
  padding: 6px 46px 6px 16px;
}
.filter-settings .select__field--placeholder {
  color: #727272;
  font-size: 14px;
}
.filter-settings .select__values {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 100;
}
.filter-settings .select__options {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  border: none;
  margin-top: 10px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}
.filter-settings .select__option {
  text-align: left;
  background: #F8F8F8;
  border: none;
  font-size: 14px;
  font-family: "RobotoMedium";
  line-height: 140%;
  min-height: 40px;
  color: #434343;
  padding: 6px 15px 6px 24px;
  transition: font-weight 1s ease 0s, background 0.5s ease 0s;
}
.filter-settings .select__option.active {
  font-weight: 700;
  background: #DCE6E8;
}
.filter-settings .select__option:hover {
  background: #DCE6E8;
}
.filter-settings .select__placeholder {
  color: #727272;
}
.filter-settings .checkbox__element {
  display: inline;
  position: relative;
  left: 0;
  top: 0;
  padding-left: 0;
  padding-right: 25px;
  padding-top: 0;
  line-height: 140%;
  color: #878787;
  cursor: pointer;
}
.filter-settings .checkbox__element p {
  margin: 0;
}
.filter-settings .checkbox__element a {
  color: #2394E7;
  transition: all 0.4s ease 0s;
}
.filter-settings .checkbox__element a:hover {
  color: #30B9E4;
}
.filter-settings .checkbox__element::before {
  right: 0;
  left: auto;
  width: 16px;
  height: 16px;
  border-radius: 0;
  background: transparent;
  border: 1px solid #2B4764;
}
.filter-settings .checkbox__element::after {
  right: 1px;
  left: auto;
  width: 14px;
  height: 11px;
  background: url("../images/other/checkbox.svg") 0 0/14px no-repeat;
}
.filter-settings .checkbox__element.active::after {
  opacity: 1;
  visibility: visible;
}
.filter-settings .checkbox__element.active::before {
  background: #2B4764;
}

.filter-item input[type=number]::-webkit-outer-spin-button,
.filter-item input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.filter-item input[type=number] {
  -moz-appearance: textfield;
}
.filter-item input[type=number]:hover,
.filter-item input[type=number]:focus {
  -moz-appearance: number-input;
}

.input-fields {
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 10px;
}
.input-fields input {
  width: 100%;
  padding: 6px 16px;
  font-family: "RobotoMedium";
  border-radius: 0 !important;
  line-height: 140%;
  color: #535353;
  background: rgba(255, 255, 255, 0.74);
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.input-fields input::-moz-placeholder {
  color: #727272;
}
.input-fields input:-ms-input-placeholder {
  color: #727272;
}
.input-fields input::placeholder {
  color: #727272;
}

.range-fields {
  position: relative;
  margin-top: 20px;
}
.range-fields .range-line {
  width: 100%;
  height: 3px;
  background: rgba(134, 155, 177, 0.37);
  position: relative;
}
.range-fields .progress-part {
  position: absolute;
  left: 25%;
  right: 25%;
  height: 5px;
  background: #869BB1;
}

.field-range {
  position: absolute;
  left: 0;
  top: 0px;
  height: 3px;
  width: 100%;
  -webkit-appearance: none;
  pointer-events: auto;
  background: none;
}

.field-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 17px;
  height: 15px;
  cursor: pointer;
  z-index: 3;
  position: relative;
  background: #2E4965;
}

.field-range::-moz-range-thumb {
  -moz-appearance: none;
  width: 17px;
  height: 15px;
  cursor: pointer;
  z-index: 3;
  position: relative;
  background: #2E4965;
}

.wants-block {
  margin: 0 -30px 0 -25px;
}

.img-wants {
  display: flex;
}
@media (min-width: 992px) {
  .img-wants {
    display: none;
  }
}
@media (min-width: 1660px) {
  .img-wants {
    display: flex;
  }
}

.how-wants {
  background-color: #DCE6E8;
}

.how-wants.visible {
  position: relative;
  background: #0E4564 url("../images/wants/wants-list.png") left/cover no-repeat;
}

.how-wants-list li {
  position: relative;
  padding-right: 22px;
}
.how-wants-list li::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.btn-show-style{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(37, 71, 98, 0.31);
  border: 1px solid #FFFFFF;
  backdrop-filter: blur(13px);
  color: #FFFFFF;
  font-size: 18px;
  line-height: 140%;
  min-height: 60px;
  width: calc(100% - 50px);
  max-width: 335px;
}

.btn-show {
  display: none;
}
.btn-show.visible {
  display: flex;
}

.btns-show {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.btns-show.visible {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.btns-show.lock {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 992px) {
  .btns-show {
    display: none;
  }
}


.bg-echoBlue {
  background-color: #9fb5cb !important;
}

.card-location {
  font-size: 18px;
  line-height: 140%;
  margin: 0;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.listings-list.display-type-row .card {
  flex-direction: row;
  min-height: 133px;
}
.listings-list.display-type-row .card-image {
  border-left: 10px solid #2B4764;
  padding: 0;
  width: 45%;
  max-width: 183px;
}
.listings-list.display-type-row .card-image.wants-img {
  min-height: auto;
}
.listings-list.display-type-row .card-image.wants-img:hover img {
  transform: translate(-50%, -50%) scale(1.1);
}
.listings-list.display-type-row .card-image.wants-img img {
  position: absolute;
  width: auto;
  height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.listings-list.display-type-row .card-content {
  padding: 10px 14px 10px 20px;
  border: none;
  width: 100%;
  -moz-column-gap: 60px;
  column-gap: 60px;
}
@media (max-width: 991px) {
  .listings-list.display-type-row .card-content {
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 10px 5px 10px 5px;
  }
}
@media (max-width: 991px) {
  .listings-list.display-type-row .card-name {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .listings-list.display-type-row .card-location {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .listings-list.display-type-row .card-price {
    font-size: 16px;
  }
}
.listings-list.display-type-row .card-buttons {
  justify-content: center;
}
.listings-list.display-type-row .menu-list.menu-list-top {
  top: 0;
  right: 100%;
  bottom: auto;
  margin-right: 10px;
  margin-bottom: 0;
}
.listings-list.display-type-row .listings-container {
  grid-template-columns: 1fr !important;
  gap: 11px !important;
}

.btn-show-order {
  transition: background 0.4s ease 0s;
}
.btn-show-order span {
  transition: background 0.4s ease 0s;
}
.btn-show-order.order-grid {
  display: grid;
  align-items: center;
  padding: 10px;
  gap: 4px;
  grid-template-columns: repeat(2, 1fr);
}
.btn-show-order.order-row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 3px;
}
.btn-show-order:hover span {
  background: #2B4764 !important;
}
.btn-show-order.active {
  background: #2B4764 !important;
}
.btn-show-order.active span {
  background: #fff !important;
}

.card-members{
  right: 16px;
  top: 16px;
}

.listings-list.display-type-row .card-members {
  right: auto;
  top: auto;
  bottom: 0;
  left: 10px;
}

.card .tooltip-content{
  right: 0;
  left: auto;
  transform: none;
}
.card .tooltip-content::after{
  right: 15px;
  left: auto;
  transform: none;
}
.listings-list.display-type-row .card-members .tooltip-content {
  top: auto;
  left: 125%;
}
.listings-list.display-type-row .card-members .tooltip-content::after {
  right: auto;
  left: -10px;
  top: 50%;
  transform: translate(0, -50%);
  border-color: transparent #2B4764 transparent transparent;
}