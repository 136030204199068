.main-header {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
  background-color: #FFFFFF;
  position: -webkit-sticky;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.main-header__body {
  position: relative;
}
.main-header__body::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  z-index: 2;
}
.main-header__body.active .main-header__overlay {
  opacity: 1;
  visibility: visible;
}
.main-header__overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.4s ease 0s;
  cursor: pointer;
}
.main-header__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1816px;
  min-height: 100px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}
@media (max-width: 1023px) {
  .main-header__container {
    min-height: 60px;
  }
}
@media (max-width: 1200px) {
  .main-header__container {
    padding: 0 15px;
  }
}
.main-header .logo {
  position: relative;
  z-index: 3;
  flex-shrink: 0;
}
.main-header .logo img {
  max-width: 194px;
  width: 100%;
}
@media (max-width: 1250px) {
  .main-header .logo img {
    width: 130px;
    height: auto;
  }
}
@media (max-width: 1023px) {
  .main-header .logo img {
    width: 99px;
    height: auto;
  }
}
.main-header .nav-wrap {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -moz-column-gap: 16px;
  column-gap: 16px;
}
@media (max-width: 1200px) {
  .main-header .nav-wrap {
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
}
.main-header .nav-wrap__btns {
  display: flex;
  -moz-column-gap: 16px;
  column-gap: 16px;
}
@media (max-width: 1200px) {
  .main-header .nav-wrap__btns {
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
}
.main-header .nav-wrap__btns-wrap {
  display: flex;
  -moz-column-gap: 16px;
  column-gap: 16px;
  flex: 0 0 auto;
  align-items: center;
}
@media (max-width: 1200px) {
  .main-header .nav-wrap__btns-wrap {
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
}
.main-header .nav-wrap__profile-wrap {
  display: flex;
  align-items: center;
  -moz-column-gap: 6px;
  column-gap: 6px;
}
.main-header .nav-wrap__profile-wrap .btn-notify {
  display: flex;
  background: #DCE6E8;
  padding: 5px 8px;
  border-radius: 50%;
  position: relative;
}
.main-header .nav-wrap__profile-wrap .btn-notify::before {
  content: "";
  display: block;
  position: absolute;
  width: 11px;
  height: 11px;
  background-color: #DF4040;
  border: 2px solid #DCE6E8;
  border-radius: 50%;
  right: 9px;
  top: 2px;
  opacity: 0;
  visibility: hidden;
}
.main-header .nav-wrap__profile-wrap .btn-notify.new::before {
  opacity: 1;
  visibility: visible;
}
.main-header .nav-wrap__profile-wrap .btn-notify:hover svg {
  fill: #6592C1;
}
.main-header .nav-wrap__profile-wrap .btn-notify svg {
  fill: #2E4965;
}
.main-header .nav-wrap__profile-wrap .btn-profile {
  padding: 10px 0 10px 10px;
  cursor: pointer;
}
.main-header .nav-wrap__profile-wrap .btn-profile:hover .btn-profile__list {
  opacity: 1;
  visibility: visible;
}
.main-header .nav-wrap__profile-wrap .button-profile {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 40px;
  background: #DCE6E8;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  width: 40px;
}
.main-header .nav-wrap__profile-wrap .button-profile img {
  width: 40px;
  height: 40px;
}
.main-header .nav-wrap__profile-wrap .btn-profile__list {
  position: absolute;
  right: 0;
  top: 100%;
  min-width: 180px;
  background: #FFFFFF;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
  z-index: 3;
}
.main-header .nav-wrap__profile-wrap .btn-profile__list li {
  padding: 8px 30px;
  cursor: auto;
}
.main-header .nav-wrap__profile-wrap .btn-profile__list a {
  font-size: 18px;
  line-height: 140%;
  display: block;
  color: #535353;
}
.main-header .nav-wrap__profile-wrap .btn-profile__list a:hover {
  color: #507DAC;
}
.main-header .nav-wrap__profile-wrap .btn-profile__list button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0;
  background: transparent;
  font-size: 18px;
  line-height: 140%;
  display: block;
  color: #535353;
}
.main-header .nav-wrap__profile-wrap .btn-profile__list button:hover {
  color: #507DAC;
}
.main-header.profile-header .nav-list li:first-child {
  display: none;
  margin-bottom: 42px !important;
}
@media (max-width: 1023px) {
  .main-header.profile-header .nav-list li:first-child {
    display: block;
  }
}
.main-header.profile-header .nav-list li:first-child .nav-list__link {
  font-family: "RobotoBold";
  font-size: 26px !important;
}
.main-header .nav-list {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 16px;
  column-gap: 16px;
}
@media (max-width: 1220px) {
  .main-header .nav-list {
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
}
@media (max-width: 1120px) {
  .main-header .nav-list {
    -moz-column-gap: 5px;
    column-gap: 5px;
  }
}
.main-header .nav-list__link {
  display: block;
  text-transform: uppercase;
  padding: 12px 20px;
}
@media (max-width: 1500px) {
  .main-header .nav-list__link {
    padding: 6px 10px;
  }
}
@media (max-width: 1400px) {
  .main-header .nav-list__link {
    padding: 6px 2px;
  }
}

@media (max-width: 1100px) {
  .main-header .nav-list__link {
    font-size: 13px;
  }
}

.header__burger {
  display: none;
}

@media (max-width: 1023px) {
  .header__burger {
    display: block;
    width: 23px;
    height: 20px;
    position: relative;
    cursor: pointer;
    z-index: 3;
  }
  .header__burger span {
    background-color: #535353;
  }
  .header__burger span:nth-child(1), .header__burger span:nth-child(2), .header__burger span:nth-child(3), .header__burger span:nth-child(4), .header__burger span:nth-child(5) {
    position: absolute;
    height: 4px;
    transition: all 0.3s ease 0s;
  }
  .header__burger span:nth-child(1) {
    top: 0;
    left: 0;
    width: 50%;
  }
  .header__burger span:nth-child(2) {
    top: 0;
    right: 0;
    width: 50%;
  }
  .header__burger span:nth-child(3) {
    left: 0;
    top: 8px;
    width: 100%;
  }
  .header__burger span:nth-child(4) {
    left: 0;
    bottom: 0;
    width: 50%;
  }
  .header__burger span:nth-child(5) {
    right: 0;
    bottom: 0;
    width: 50%;
  }
  .header__burger.active span:nth-child(1) {
    top: 4px;
    left: 3px;
    transform: rotate(45deg);
  }
  .header__burger.active span:nth-child(2) {
    top: 4px;
    right: 3px;
    transform: rotate(-45deg);
  }
  .header__burger.active span:nth-child(3) {
    transform: scale(0);
  }
  .header__burger.active span:nth-child(4) {
    bottom: 6px;
    left: 3px;
    transform: rotate(-45deg);
  }
  .header__burger.active span:nth-child(5) {
    bottom: 6px;
    right: 3px;
    transform: rotate(45deg);
  }
}
@media (max-width: 1023px) {
  .main-header .nav-wrap {
    position: fixed;
    display: block;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    background-color: #fff;
    padding: 110px 10px 20px 10px;
    overflow: auto;
    transition: all 0.5s ease 0s;
    z-index: 1;
  }
  .main-header .nav-wrap__btns-wrap {
    display: inline-block;
  }
  .main-header .nav-wrap__btns-wrap .btn:first-child {
    margin: 22px 0;
  }
  .main-header .nav-wrap__btns {
    flex-direction: column;
  }
  .main-header .nav-wrap__profile-wrap {
    margin-top: 22px;
  }
  .main-header .nav-wrap__profile-wrap .btn-profile {
    width: 100%;
    padding: 0;
  }
  .main-header .nav-wrap__profile-wrap .button-profile {
    display: none;
  }
  .main-header .nav-wrap__profile-wrap .btn-profile__list {
    position: relative;
    visibility: visible;
    opacity: 1;
    width: 100%;
  }
  .main-header .nav-wrap__profile-wrap .btn-profile__list li {
    padding: 0;
  }
  .main-header .nav-wrap__profile-wrap .btn-profile__list li:first-child {
    display: none;
  }
  .main-header .nav-wrap__profile-wrap .btn-profile__list a {
    display: inline;
    font-size: 22px;
    text-transform: uppercase;
    padding: 6px 5px;
  }
  .main-header .nav-wrap__profile-wrap .btn-profile__list button {
    display: inline;
    font-size: 22px;
    text-transform: uppercase;
    padding: 6px 5px;
    background: transparent;
    transition: color 0.4s ease 0s;
  }
  .main-header .nav-wrap__profile-wrap .btn-profile__list button:hover {
    color: #507DAC;
  }
  .main-header .nav-wrap.active {
    left: 0;
  }
  .main-header .nav-list {
    display: block;
  }
  .main-header .nav-list li:not(:last-child) {
    margin-bottom: 22px;
  }
  .main-header .nav-list__link {
    font-size: 22px;
    display: inline-flex;
  }
}

@media (max-width: 1100px) {
  .main-header .nav-wrap__profile-wrap .btn-profile {
    padding: 10px 0 10px 5px;
  }
}

@media (min-width: 1024px) {
  .main-header .nav-wrap__profile-wrap .btn-profile__list {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
  }
}
@media (max-width: 1023px) {
  .main-header .nav-wrap__profile-wrap .notify-block {
    display: none;
  }
}
.notify-block {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.notify-block svg {
  fill: #2E4965;
}
.notify-block.active .notify-list {
  visibility: visible;
  opacity: 1;
}
.notify-block:hover svg {
  fill: #507DAC;
}
.notify-block .btn-notify {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 40px;
  height: 40px;
  background-color: #DCE6E8;
  border-radius: 50%;
  position: relative;
}
.notify-block .btn-notify::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  width: 11px;
  height: 11px;
  background-color: #DF4040;
  border: 2px solid #DCE6E8;
  border-radius: 50%;
  right: 9px;
  top: 2px;
  opacity: 0;
  visibility: hidden;
}
.notify-block .btn-notify.new::before {
  opacity: 1;
  visibility: visible;
}
.notify-block .notify-list {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  right: 0;
  top: calc(100% + 10px);
  width: 75vw;
  max-width: 550px;
  background: #FFFFFF;
  border: 1px solid #9FB5CB;
  transition: all 0.4s ease 0s;
  height: auto;
  max-height: 525px;
  min-height: 150px;
  overflow: auto;
  cursor: auto;
}
.notify-block .notify-list li {
  position: relative;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 14px 15px 14px 50px;
  font-family: "RobotoMedium";
  font-weight: 500;
  font-size: 18px;
  line-height: 117%;
  color: #535353;
}
@media (max-width: 576px) {
  .notify-block .notify-list li {
    font-size: 14px;
    padding: 10px 5px 10px 32px;
  }
}
.notify-block .notify-list li p {
  margin: 0;
}
.notify-block .notify-list li a {
  color: #2394E7;
  transition: color 0.4s ease 0s;
}
.notify-block .notify-list li a:hover {
  color: #507DAC;
}
.notify-block .notify-list li:not(:last-child) {
  border-bottom: 1px solid #9FB5CB;
}
.notify-block .notify-list li .notify-subtext {
  font-family: "RobotoRegular";
  color: #727272;
  font-size: 14px;
  line-height: 114%;
}
@media (max-width: 576px) {
  .notify-block .notify-list li .notify-subtext {
    font-size: 12px;
  }
}
.notify-block .notify-list li .notify-subtext a {
  color: #2B4764;
}
.notify-block .notify-list li .notify-subtext a:hover {
  color: #2394E7;
}
.notify-block .notify-list li::before {
  content: "";
  position: absolute;
  display: block;
  left: 7px;
  top: 7px;
  width: 36px;
  height: 36px;
}
@media (max-width: 576px) {
  .notify-block .notify-list li::before {
    width: 24px;
    left: 3px;
  }
}
.notify-block .notify-list li.attention::before {
  background: url("/images/header/notify-atn.svg") center no-repeat;
}
.notify-block .notify-list li.favorites::before {
  background: url("/images/header/notify-fav.svg") center no-repeat;
}
.notify-block .notify-list li.leads::before {
  background: url("/images/header/notify-leads.svg") center no-repeat;
}
.notify-block .notify-list li.messages::before {
  background: url("/images/header/notify-message.svg") center no-repeat;
}
.notify-block .notify-list li.succes::before {
  background: url("/images/header/notify-succes.svg") center no-repeat;
}
.mobile-settings {
  display: none;
}
@media(max-width:1023px){
  .mobile-settings {
    display: flex;
    align-items: center;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }
}

.notify-block .notify-list li.new:not(.messages) {
  padding: 14px 25px 14px 50px;
}
@media (max-width: 576px) {
  .notify-block .notify-list li.new:not(.messages) {
    padding: 10px 25px 10px 32px;
  }
}
.notify-block .notify-list li.new:not(.messages)::after {
  content: "";
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  background-color: #2B4764;
  border-radius: 50%;
}

.notify-block .notify-list li.messages.new {
  justify-content: space-between;
  padding: 14px 5px 14px 50px;
}
@media (max-width: 576px) {
  .notify-block .notify-list li.messages.new {
    padding: 10px 5px 10px 32px;
  }
}
.notify-block .notify-list li.messages.new .unread {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5px 8.5px;
  font-size: 12px;
  line-height: 140%;
  color: #fff;
  background: #2B4764;
  border-radius: 33px;
}

@media(max-width:1023px){
  .mt-btn-log{
    margin-top: 15px;
  }
}