.img-about-network {
  flex: 0 0 30%;
  height: 100%;
  width: 100%;
  max-width: 400px;
  position: relative;
  padding: 20% 0 0 0 ;
}
@media(max-width:991px){
  .img-about-network {
    padding: 40% 0 0 0 ;
  }
}

/* .img-about-network img {
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
} */

.textarea-news {
  display: none;
  resize: none; 
  width: 100%;
  border: none;
  height: 75px;
  overflow: hidden;
}

.news-post.editing .textarea-news {
  display: block!important;
}

.text-news {
  display: block;
}

.news-post.editing .text-news {
  display: none!important;
}

.date-label {
  /* display: none; */
}

.btns-label {
  top: 0;
}

.news-post.editing .btns-label {
  top: 0 !important;
}
.news-post.editing .static-btns {
  display: none !important;
}
.news-post.editing .editing-btns {
  display: flex !important;
}

@media (any-hover: hover) {
  .date-btns-anim .date-label {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    transition: top 0.4s ease 0s;
  }
  .date-btns-anim .btns-label {
    position: absolute;
    top: 100%;
    width: 100%;
    transition: top 0.4s ease 0s;
  }

  .news-post.editing .date-label {
    top: -100%;
  }

  .date-btns-anim:hover .date-label {
    top: -100%;
  }
  .date-btns-anim:hover .btns-label {
    top: 0;
  }
}


.network-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #2B4764;
  min-height: 40px;
  transition: background 0.4s ease 0s, color 0.4s ease 0s;
}
.network-tab.active {
  background: #2B4764;
  color: #fff;
}
.network-tab:hover:not(.active) {
  background: #6592C1;
  color: #fff;
}
@media (max-width: 991px) {
  .network-tab {
    white-space: nowrap;
    width: 100%;
  }
}

.tab-content {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
}
.tab-content.active {
  display: block;
  -webkit-animation: opening 0.4s linear 1 normal running 0s forwards;
  animation: opening 0.4s linear 1 normal running 0s forwards;
}

@-webkit-keyframes opening {
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes opening {
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.btn-invitations {
  transition: background 0.4s ease 0s;
}
.btn-invitations svg {
  fill: #2B4764;
}
.btn-invitations:hover {
  background: #cbe8ee !important;
}
.btn-invitations:hover svg.accept-network {
  fill: #6BE695;
}
.btn-invitations:hover svg.reject-network {
  fill: #C13838;
}

.tab-listing input {
  color: #434343;
  font-size: 18px;
}
.tab-listing input::-moz-placeholder {
  color: #727272;
  font-size: 14px;
}
.tab-listing input:-ms-input-placeholder {
  color: #727272;
  font-size: 14px;
}
.tab-listing input::placeholder {
  color: #727272;
  font-size: 14px;
}
.tab-listing .select__body {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}
.tab-listing .select__body::before {
  content: " ";
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 40px;
  height: 100%;
  background: #2E4965 url("/images/profile/listing/select-arrow.svg") center no-repeat;
  border-bottom: none;
  border-right: none;
  margin-right: 0;
  transform: rotate(0deg);
  transition: all 0.4s ease 0s;
  pointer-events: none;
}
.tab-listing .select__body.active::before {
  transform: rotate(0deg);
  right: 0px;
  top: 0px;
}
.tab-listing .select__field {
  text-align: left;
  width: 100%;
  min-height: 40px;
  background: rgba(255, 255, 255, 0.74);
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 18px;
  font-family: "RobotoRegular";
  line-height: 140%;
  color: #434343;
  padding: 6px 46px 6px 16px;
}
.tab-listing .select__field--placeholder {
  color: #727272;
  font-size: 14px;
}
.tab-listing .select__values {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
}
.tab-listing .select__options {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 120px;
  overflow: hidden;
  overflow-y: auto;
  border: none;
  margin-top: 10px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}
.tab-listing .select__option {
  text-align: left;
  background: #F8F8F8;
  border: none;
  font-size: 14px;
  font-family: "RobotoMedium";
  line-height: 140%;
  min-height: 40px;
  color: #535353;
  padding: 6px 15px 6px 24px;
  transition: font-weight 1s ease 0s, background 0.5s ease 0s;
}
.tab-listing .select__option.active {
  font-weight: 700;
  background: #9FB5CB;
}
.tab-listing .select__option:hover {
  background: #9FB5CB;
}
.tab-listing .select__placeholder {
  color: #727272;
}

.one-string {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
@media (min-width: 992px) {
  .one-string {
    -webkit-line-clamp: 1;
  }
}

.network-edit .modal__exit {
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 20px;
  height: 20px;
  background: transparent;
}
.network-edit .modal__exit::before, .network-edit .modal__exit::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  background: #787A80;
  transition: background 0.4s ease 0s;
}
.network-edit .modal__exit::after {
  bottom: 8px;
  transform: rotate(-45deg);
}
.network-edit .modal__exit::before {
  top: 9px;
  transform: rotate(45deg);
}
.network-edit .modal__exit:hover::before, .network-edit .modal__exit:hover::after {
  background: #6592C1;
}

.type-network {
  display: flex;
  margin-top: 10px;
}
.type-network .type-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2B4764;
  min-height: 40px;
  flex: 0 1 140px;
  padding: 5px 10px;
  background: transparent;
  color: #2B4764;
  transition: background 0.4s ease 0s, color 0.4s ease 0s;
}
.type-network .type-item.active {
  background: #2B4764;
  color: #fff;
}
.type-network .type-item:hover:not(.active) {
  background: #6592C1;
  color: #fff;
}

.send-invite.disabled {
  background: #DCE6E8 !important;
  color: #2B4764 !important;
  pointer-events: none;
}

.invitation-item {
  position: relative;
}
.invitation-item::before, .invitation-item::after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
}
.invitation-item::after {
  background: #C13838;
}
.invitation-item::before {
  background: #6BE695;
}
.invitation-item.accepting::before {
  -webkit-animation: blinking 0.4s linear 1 normal running 0s forwards;
  animation: blinking 0.4s linear 1 normal running 0s forwards;
}
.invitation-item.rejecting::after {
  -webkit-animation: blinking 0.4s linear 1 normal running 0s forwards;
  animation: blinking 0.4s linear 1 normal running 0s forwards;
}

@-webkit-keyframes blinking {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 0.7;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 0.7;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}