.blog-details h1 {
  color: #2B4764;
  line-height: 140%;
  margin-bottom: 20px;
  font-size: 28px;
}
@media (min-width: 576px) {
  .blog-details h1 {
    margin-bottom: 38px;
    font-size: 36px;
  }
}
.blog-details h2 {
  font-size: 24px;
}
.blog-details h2,
.blog-details h3 {
  line-height: 140%;
  color: #2F2F2F;
}
.blog-details__container {
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 30px;
  column-gap: 30px;
}
@media (max-width: 991px) {
  .blog-details__container {
    flex-direction: column;
  }
}
.blog-details__left-wrap {
  width: 100%;
}
@media (min-width: 992px) {
  .blog-details__left-wrap {
    max-width: 730px;
  }
}
.blog-details__btn-back {
  display: inline-flex;
  font-size: 18px;
}
.blog-details__right-wrap {
  width: 100%;
}
@media (min-width: 992px) {
  .blog-details__right-wrap {
    max-width: 350px;
  }
}
.blog-details__right-wrap h2 {
  color: #2B4764;
}
.blog-details__right-wrap h3 {
  font-size: 18px;
  color: #2B4764;
}
.blog-details__img-wrap {
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 0;
  padding-bottom: 45.62%;
}
.blog-details__img-wrap img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.blog-details .rec-list {
  display: flex;
  flex-direction: column;
  gap: unset;
}
@media (max-width: 991px) {
  .blog-details .rec-list {
    align-items: center;
  }
}
.blog-details .rec-list__rec-blog {
  margin-bottom: 20px;
}
.blog-details .rec-blog__img-wrap img {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}
.blog-details__cnt {
  position: relative;
  width: 100%;
  font-size: 18px;
  line-height: 25px;
  color: #2F2F2F;
}
.blog-details__cnt table {
  width: 100% !important;
}
@media (max-width: 992px) {
  .blog-details__cnt {
    font-size: 15px;
    line-height: 1.2;
  }
}

@media (min-width: 992px) {
  .blog-details__cnt {
    max-width: 635px;
  }
}
.blog-details__cnt:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #2B4764;
}
@media (min-width: 992px) {
  .blog-details__cnt:after {
    width: calc(100% + 95px);
  }
}
.blog-details__cnt img {
  width: inherit;
  margin-bottom: 76px;
  max-width: 100%;
}
.blog-details__cnt blockquote {
  position: relative;
  font-family: "RobotoLight";
  font-size: 18px;
  line-height: 140%;
  background-color: #FFFFFF;
  padding: 54px 35px 50px;
  margin: 0 0 30px;
}
.blog-details__cnt blockquote:before {
  content: '"';
  position: absolute;
  top: 42px;
  left: 36px;
  font-size: 25px;
}
.blog-details__cnt blockquote p {
  margin: 0;
}
.blog-details__cnt ul {
  margin-bottom: 70px;
}
.blog-details__cnt ul li {
  list-style-type: square;
  margin: 0 0 26px 50px;
}

.blog-details__cnt ol li {
  list-style-type: decimal;
  margin: 0 0 26px 50px;
}

.info-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #535353;
}
.info-block__right {
  display: flex;
  align-items: center;
}
.info-block__btn-share {
  margin-left: 10px;
  transition: background 0.4s ease 0s;
}
@media (min-width: 576px) {
  .info-block__btn-share {
    margin-left: 20px;
  }
}
.info-block__btn-share:hover {
  background: #6592C1;
}

.tag-list {
  margin: 0 -6px;
}
.tag-list__link {
  display: inline-block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #6F6F6F;
  background-color: #FFFFFF;
  border-radius: 33px;
  padding: 10px 20px;
  margin: 6px;
  transition: all 0.4s ease 0s;
}
.tag-list__link:hover {
  color: #ffffff;
  background-color: #2B4764;
}

.tags h3 {
  color: #535353;
  font-family: "RobotoRegular";
}

@media (max-width: 991px) {
  .blog-details__cnt iframe {
    max-width: 100%;
  }
}

.blog-details__cnt iframe {
  width: calc(100% + 95px);
}

.tooltip-adv .tooltip-content {
  right: 0;
  left: auto;
  transform: none;
}
.tooltip-adv .tooltip-content::after {
  right: 15px;
  left: auto;
  transform: none;
}