@charset "UTF-8";

.main-search {
  background-color: #FFFFFF;
  background: url("../images/main-page/main-search/bg-search.png") center/cover no-repeat;
}

.main-search__title {
  max-width: 920px;
  width: 100%;
  margin: 0 auto 208px;
}

@media (max-width: 1000px) {
  .main-search__title {
    margin: 0 auto 150px;
  }
}

@media (max-width: 500px) {
  .main-search__title {
    margin-bottom: 100px;
  }
}

.main-search__title h1 {
  margin: 0 0 26px;
  padding-top: 75px;
}

@media (max-width: 700px) {
  .main-search__title h1 {
    margin: 0 0 20px;
    padding-top: 40px;
  }
}

.main-search__title p {
  font-family: "RobotoRegular";
  font-size: 34px;
  line-height: 48px;
  letter-spacing: 0.04em;
  color: #535353;
  margin: 0;
}

@media (max-width: 1000px) {
  .main-search__title p {
    font-size: 22px;
    line-height: 36px;
  }
}

@media (max-width: 500px) {
  .main-search__title p {
    font-size: 15px;
    line-height: 29px;
  }
}

.main-search__title p span {
  font-family: "RobotoBold";
  color: #507DAC;
}

.main-search .form-search {
  position: relative;
  background: rgba(110, 184, 198, 0.19);
  padding: 40px 95px 65px;
  margin-bottom: 221px;
}

@media (max-width: 767px) {
  .main-search .form-search {
    padding: 15px 20px 30px;
    margin-bottom: 150px;
  }
}

@media (max-width: 500px) {
  .main-search .form-search {
    margin-bottom: 100px;
  }
}

.main-search .form-search:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.main-search .form-search__wrap {
  position: relative;
  z-index: 2;
}

.main-search .form-search .select-wrap {
  max-width: 350px;
  width: 100%;
}

@media (max-width: 767px) {
  .main-search .form-search .select-wrap {
    max-width: 100%;
  }
}

.main-search .selects-wrap {
  display: flex;
  -moz-column-gap: 30px;
  column-gap: 30px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .main-search .selects-wrap {
    flex-direction: column;
    row-gap: 20px;
  }
}

.main-search .selects-wrap p {
  font-size: 18px;
  line-height: 25px;
  color: #535353;
  margin: 0 0 10px;
}

.main-search .select__body {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}

.main-search .select__body::before {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 40px;
  height: 100%;
  background: #2B4764;
  border-bottom: none;
  border-right: none;
  margin-right: 0;
  transform: rotate(0deg);
  transition: all 0.4s ease 0s;
  pointer-events: none;
}

.main-search .select__body::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 40px;
  height: 100%;
  background: url("../images/profile/listing/select-arrow.svg") center no-repeat;
  transition: all 0.4s ease 0s;
  pointer-events: none;
}

.main-search .select__body.active::before {
  transform: rotate(0deg);
  right: 0px;
  top: 0px;
}

.main-search .select__body.active::after {
  transform: rotate(180deg);
}

.main-search .select__field {
  text-align: left;
  width: 100%;
  min-height: 40px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 18px;
  font-family: "RobotoRegular";
  line-height: 140%;
  color: #2B4764;
  padding: 6px 46px 6px 16px;
}

.main-search .select__field--placeholder {
  color: #727272;
  font-size: 18px;
}

.main-search .select__values {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
}

.main-search .select__options {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: none;
  margin-top: 10px;
  max-height: 200px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

.main-search .select__option {
  min-height: 40px;
  text-align: left;
  background: #F8F8F8;
  border: none;
  font-size: 18px;
  line-height: 140%;
  color: #535353;
  padding: 6px 15px 6px 24px;
  transition: font-weight 1s ease 0s, background 0.5s ease 0s;
}

.main-search .select__option.active {
  font-weight: 700;
  background: #DCE6E8;
}

.main-search .select__option:hover {
  background: #DCE6E8;
}

.main-search .select__placeholder {
  color: #727272;
}

.main-search .input-wrap {
  margin-bottom: 20px;
}

.main-search .input-wrap input {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 16px;
  background: #fff;
  font-size: 18px;
  border: 1px solid #727272;
  -webkit-appearance: none !important;
  border-radius: 0px !important;
}

.main-search .input-wrap input::-moz-placeholder {
  color: #727272;
  font-size: 18px;
  line-height: 140%;
}

.main-search .input-wrap input:-ms-input-placeholder {
  color: #727272;
  font-size: 18px;
  line-height: 140%;
}

.main-search .input-wrap input::placeholder {
  color: #727272;
  font-size: 18px;
  line-height: 140%;
}

.main-search .input-wrap img {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translate(-50%, -50%);
}

.main-search .btn-wrap {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .main-search .btn-wrap {
    justify-content: center;
  }
}

.main-search .btn-wrap .btn {
  max-width: 140px;
  width: 100%;
  height: 40px;
}

.main-search .search-request {
  position: relative;
  display: flex;
  max-width: 825px;
  width: 100%;
  padding: 30px 60px;
}

@media (max-width: 767px) {
  .main-search .search-request {
    padding: 15px 30px;
  }
}

.main-search .search-request:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(50, 93, 101, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
}

.main-search .search-request__wrap {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -moz-column-gap: 15px;
  column-gap: 15px;
}

@media (max-width: 767px) {
  .main-search .search-request__wrap {
    justify-content: center;
    row-gap: 15px;
  }
}

@media (max-width: 450px) {
  .main-search .search-request__wrap {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .main-search .search-request__wrap .line {
    display: none;
  }
}

.main-search .search-request__text {
  max-width: 191px;
  width: 100%;
  color: #ffffff;
  margin: 0;
}

@media (max-width: 450px) {
  .main-search .search-request__text {
    text-align: center;
  }
}

.main-search .search-request__text span {
  padding-left: 43px;
}

@media (max-width: 450px) {
  .main-search .search-request__text span {
    padding-left: 0;
  }
}

.main-search .search-request .btn {
  max-width: 140px;
  width: 100%;
}

.blog__container {
  padding-top: 60px;
  padding-bottom: 90px;
}

@media (max-width: 500px) {
  .blog__container {
    padding-bottom: 60px;
  }
}

.blog__section-title {
  margin-bottom: 30px;
  margin-right: -20px;
}

.blog__section-title h2 {
  color: #2B4764;
}

.blog__btn-wrap {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .blog__btn-wrap {
    justify-content: center;
  }
}

.blog .title {
  display: flex;
  align-items: center;
}

.blog .news-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
  gap: 30px;
}

@media (max-width: 425px) {
  .blog .news-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.blog .news {
  display: flex;
  flex-direction: column;
}

/* .blog .news:hover .news__desc {
  background-color: #9FB5CB;
} */
.blog .news__img-wrap {
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 0;
  padding-bottom: 60%;
}

.blog .news__img-wrap img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: transform 0.4s ease 0s;
}

.blog .news__cnt {
  display: flex;
  flex: 1 1 auto;
  background-color: #DCE6E8;
}

.blog .news__date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  background-color: #2B4764;
  color: #ffffff;
}

@media (max-width: 500px) {
  .blog .news__date {
    font-size: 14px;
  }
}

.blog .news__date span {
  font-size: 24px;
}

@media (max-width: 500px) {
  .blog .news__date span {
    font-size: 18px;
  }
}

.blog .news .title h3 {
  font-family: "RobotoMedium";
  margin-bottom: 0;
  transition: color 0.4s ease 0s;
}

.blog .news__img-wrap:hover img {
  transform: scale(1.1);
}

.blog .news .title h3:hover {
  color: #6dbdf8;
}

.blog .news__desc p a {
  font-family: "RobotoMedium";
  transition: color 0.4s ease 0s;
  color: inherit;
}

.blog .news__desc p a:hover {
  color: #6dbdf8;
}

@media (max-width: 500px) {
  .blog .news .title h3 {
    font-size: 15px;
  }
}

.blog .news__desc {
  transition: 0.3s;
  padding: 9px 11px 6px 8px;
}

.blog .news__desc p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-family: "RobotoLight";
  font-size: 14px;
  color: #2B4764;
  margin: 0;
}

@media (max-width: 500px) {
  .blog .news__desc p {
    font-size: 14px;
  }
}

.blog .btn {
  max-width: 160px;
  width: 100%;
  margin-top: 30px;
}

.how-works {
  background-color: #DCE6E8;
}

.how-works.visible {
  padding: 61px 0 60px 0;
  background: linear-gradient(360deg, #1E333B 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(360deg, rgba(119, 150, 156, 0.71) 29.58%, rgba(30, 51, 60, 0) 103.59%), linear-gradient(0deg, rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.84)), url("../images/main-page/how-works/bg-1.png") center/cover no-repeat;
}

.how-works__section-title {
  margin-bottom: 52px;
  margin-left: -20px;
  display: flex;
  justify-content: flex-end;
}

.how-works__section-title h2 {
  color: #ffffff;
  flex: 0 0 auto;
}

.how-works__section-title .line {
  background-color: #FFFFFF;
}

.how-works__btn-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 96px;
}

@media (max-width: 767px) {
  .how-works__btn-wrap {
    justify-content: center;
  }
}

.how-works__btn-wrap .btn {
  width: 160px;
}

.how-works .slider {
  position: relative;
}

@media (max-width: 1250px) {
  .how-works .slider {
    padding: 0 40px;
  }
}

.how-works .slider .swiper-button-disabled {
  pointer-events: none;
}

.how-works .slider .swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 0;
}

.how-works .slider .swiper-pagination {
  width: 100%;
  margin-top: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  -moz-column-gap: 13px;
  column-gap: 13px;
  row-gap: 10px;
}

.how-works .slider .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  margin: 0;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  opacity: 1;
}

.how-works .slider .swiper-pagination-bullet-active {
  background: #fff;
}

.how-works .slider .btn-prev,
.how-works .slider .btn-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

.how-works .slider .btn-prev:hover,
.how-works .slider .btn-next:hover {
  opacity: 1;
}

.how-works .slider .btn-prev {
  background-image: url("../images/main-page/how-works/arrow-left.svg");
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
}

@media (max-width: 1250px) {
  .how-works .slider .btn-prev {
    left: 0;
  }
}

.how-works .slider .btn-next {
  background-image: url("../images/main-page/how-works/arrow-right.svg");
  right: -70px;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

@media (max-width: 1250px) {
  .how-works .slider .btn-next {
    right: 0;
  }
}

.how-works .slider__item {
  margin: 0 auto;
}

.how-works .slider__content {
  position: relative;
  overflow: hidden;
  display: block;
  left: 0;
  top: 0;
  max-width: 274px;
  flex: 0 1 274px;
  padding: 670px 0 0 0;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .how-works .slider__content {
    padding: 550px 0 0 0;
  }
}

@media (max-width: 500px) {
  .how-works .slider__content {
    padding: 400px 0 0 0;
  }
}

.how-works .slider__content:hover img {
  transform: scale(1.2);
}

.how-works .slider__content img {
  display: inline;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.4s ease 0s;
}

.how-works .slider__content p {
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 222px;
  min-width: 80%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

.about-platform {
  padding: 61px 0 193px 0;
  color: #DCE6E8;
  font-family: "RobotoMedium";
}

@media (max-width: 800px) {
  .about-platform {
    padding: 60px 0;
  }
}

.about-platform__section-title {
  margin-bottom: 44px;
  margin-right: -20px;
}

.about-platform__section-title h2 {
  color: #2B4764;
}

.about-platform .highlighted {
  color: #30B9E4;
  font-family: "RobotoMedium";
}

.about-platform__btn-wrap {
  position: relative;
  z-index: 2;
}

.about-platform__btn-wrap .btn {
  max-width: 160px;
}

.about-platform__items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.about-platform__item--huge {
  grid-column: span 3;
  grid-row: span 2;
}

@media (max-width: 800px) {
  .about-platform__item--huge {
    grid-column: span 5;
  }
}

.about-platform__item--big {
  grid-column: span 2;
  grid-row: span 2;
}

@media (max-width: 800px) {
  .about-platform__item--big {
    grid-column: span 5;
  }
}

.about-platform__item--fon-1 {
  background: #15252C;
}

.about-platform__item--fon-2 {
  background: linear-gradient(0deg, #2CACD5, #2CACD5);
}

.about-platform__video {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 500px) {
  .about-platform__video {
    height: 300px;
  }
}

.about-platform__video::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000;
  opacity: 0.5;
}

.about-platform__video.active {
  cursor: pointer;
}

.about-platform__video.active::before {
  display: none;
}

.about-platform__video .video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  transform: translate(-50%, -50%);
  font-family: "RobotoRegular";
  background: transparent;
  border: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 8px;
  column-gap: 8px;
  z-index: 3;
  padding: 14px;
}

.about-platform__video .video__play span {
  line-height: 14px;
}

.about-platform__video .video__play svg {
  width: 26px;
  height: 26px;
}

.about-platform__video .video__play.active {
  display: none;
}

.about-platform__video .video__block {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-platform__text-item {
  position: relative;
  padding: 89px 70px 105px 58px;
  background: #2D424B;
  height: 100%;
}

@media (max-width: 500px) {
  .about-platform__text-item {
    padding: 30px 30px 50px 35px;
  }
}

.about-platform__text-item p {
  margin: 0;
  font-family: "RobotoRegular";
  font-size: 24px;
  line-height: 139.5%;
  color: #E5ECED;
}

@media (max-width: 500px) {
  .about-platform__text-item p {
    font-size: 18px;
  }
}

.about-platform__text-item::before {
  content: "";
  display: block;
  position: absolute;
  left: 36px;
  top: 0;
  width: 1px;
  height: 71%;
  background: #fff;
}

@media (max-width: 500px) {
  .about-platform__text-item::before {
    left: 20px;
  }
}

.about-platform__premium {
  background-color: #DCE6E8;
}

.about-platform__premium {
  min-height: 444px;
  background: url("../images/main-page/about-platform/bg1.jpeg") center/cover no-repeat;
  display: flex;
  align-items: flex-end;
  padding: 40px;
}

@media (max-width: 500px) {
  .about-platform__premium {
    min-height: 350px;
  }
}

.about-platform__premium .premium__content {
  max-width: 180px;
}

.about-platform__premium-text {
  max-width: 160px;
  font-family: "RobotoRegular";
  font-size: 18px;
  line-height: 140%;
  color: #FFFFFF;
  margin: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.about-platform__premium-subtext {
  font-family: "RobotoLight";
  margin: 20px 0;
}

.about-platform__item-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.about-platform__image {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 222px;
}

@media (max-width: 500px) {
  .about-platform__image {
    min-height: 150px;
  }
}

.about-platform__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-platform__left-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  max-width: 665px;
  width: 100%;
  padding: 0 0 40px 40px;
}

.about-platform__left-block p {
  position: relative;
  z-index: 2;
  max-width: 156px;
  width: 100%;
  font-size: 18px;
  line-height: 25px;
}

.about-platform__left-block p>span {
  display: block;
  font-size: 14px;
  margin-top: 10px;
}

.about-platform__right-block {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 33px;
  padding: 0 70px 0 58px;
  height: 100%;
  position: relative;
  max-width: 445px;
  width: 100%;
  background-color: #2D424B;
}

.about-platform__right-block .line {
  position: absolute;
  top: 128px;
  left: -92px;
  max-width: 257px;
  transform: rotate(90deg);
}

.reviews {
  background-color: #DCE6E8;
}

.reviews.visible {
  background-image: url("/images/main-page/reviews/bg.jpeg");
  background-size: cover;
  padding-bottom: 21px;
}

.reviews__section-title {
  position: absolute;
  z-index: 2;
  top: 75px;
  right: 46px;
  justify-content: flex-end;
  max-width: 530px;
  width: 100%;
  color: #ffffff;
}

@media (max-width: 1000px) {
  .reviews__section-title {
    right: 20px;
  }
}

.reviews__section-title .line {
  background-color: #FFFFFF;
}

.reviews__section-title h2 {
  color: #ffffff;
  flex: 0 0 auto;
}

.reviews .review {
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  max-width: 278px;
  width: 100%;
  margin: 0 auto;
  padding: 181px 0 205px;
}

@media (max-width: 700px) {
  .reviews .review {
    padding: 181px 0 250px;
  }
}

.reviews .review:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-180deg);
}

.reviews .review_gradient-light:before {
  background: linear-gradient(359.95deg, rgba(23, 34, 36, 0.4) 0.02%, rgba(0, 0, 0, 0) 99.94%);
}

.reviews .review_gradient-average:before {
  background: linear-gradient(359.95deg, rgba(15, 46, 53, 0.4) 0.02%, rgba(0, 0, 0, 0) 99.94%);
}

.reviews .review_gradient-strong:before {
  background: linear-gradient(359.95deg, rgba(44, 68, 68, 0.4) 0.02%, rgba(0, 0, 0, 0) 99.94%);
}

.reviews .review_gradient-very-strong:before {
  background: linear-gradient(359.95deg, rgba(15, 46, 53, 0.4) 0.02%, rgba(0, 0, 0, 0) 99.94%);
}

.reviews .review__img-wrap {
  max-width: 183px;
  width: 100%;
  margin-bottom: 36px;
}

.reviews .review__img-wrap img {
  width: 100%;
  height: auto;
}

.reviews .review__cnt {
  position: relative;
  max-width: 138px;
  width: 100%;
  margin: 0 auto;
}

.reviews .review__cnt:before {
  content: "";
  position: absolute;
  top: 0;
  left: -11px;
  width: 1px;
  height: 195px;
  background-color: #FFFFFF;
}

.reviews .review__cnt .figcaption {
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 45px;
}

.reviews .review__cnt blockquote {
  position: relative;
  max-width: 122px;
  width: 100%;
  font-family: "RobotoLight";
  color: #ffffff;
  margin: 0 0 0 4px;
}

.reviews .review__cnt blockquote:before {
  content: "“";
  position: absolute;
  top: -15px;
  font-size: 35px;
  line-height: 35px;
}

.about-us {
  padding: 100px 0 61px 0;
}

.about-us__title-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.about-us__section-title {
  justify-content: flex-end;
  margin-left: -20px;
}

.about-us__section-title h2 {
  color: #2B4764;
  flex: 0 0 auto;
}

.about-us__wrap {
  display: flex;
  justify-content: space-between;
  margin: -331px 0 66px;
}

@media (max-width: 1000px) {
  .about-us__wrap {
    flex-direction: column;
    row-gap: 30px;
  }
}

.about-us .contact {
  max-width: 445px;
  width: 100%;
  font-family: "RobotoLight";
  color: #ffffff;
  padding: 81px 0 50px;
  background-image: url("../images/main-page/about-us/bg-contact.jpg");
}

@media (max-width: 1000px) {
  .about-us .contact {
    margin: 0 auto;
  }
}

@media (max-width: 700px) {
  .about-us .contact {
    padding: 60px 0 30px;
  }
}

.about-us .contact__title {
  max-width: 390px;
  width: 100%;
  padding: 0 96px;
}

@media (max-width: 700px) {
  .about-us .contact__title {
    padding: 0 25px;
    text-align: center;
    max-width: 100%;
  }
}

.about-us .contact__title h3 {
  color: #ffffff;
  margin-bottom: 24px;
}

.about-us .contact__text {
  padding: 0 96px;
  margin-bottom: 45px;
}

@media (max-width: 700px) {
  .about-us .contact__text {
    padding: 0 25px;
  }
}

.about-us .contact__text p {
  margin-bottom: 20px;
}

.about-us .contact__text a {
  color: #30B9E4;
}

.about-us .contact__btn-wrap {
  display: flex;
  justify-content: flex-end;
  padding-right: 64px;
}

@media (max-width: 700px) {
  .about-us .contact__btn-wrap {
    padding: 0 15px;
    justify-content: center;
  }
}

.about-us .contact__btn-wrap .btn {
  max-width: 157px;
  width: 100%;
}

.about-us .questions-asked {
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 30px;
  column-gap: 30px;
}

@media (max-width: 1000px) {
  .about-us .questions-asked {
    flex-direction: column;
    row-gap: 30px;
  }
}

.about-us .left-block,
.about-us .right-block {
  max-width: 540px;
  width: 100%;
  font-family: "RobotoLight";
  color: #363636;
}

@media (max-width: 1000px) {

  .about-us .left-block,
  .about-us .right-block {
    max-width: 100%;
  }
}

.about-us .left-block .title,
.about-us .right-block .title {
  margin-bottom: 26px;
}

.about-us .left-block .title h3,
.about-us .right-block .title h3 {
  color: #363636;
}

.about-us .left-block__text,
.about-us .right-block__text {
  margin-bottom: 31px;
}

.about-us .left-block__text p,
.about-us .right-block__text p {
  margin-bottom: 20px;
}

.about-us .left-block .left-block__img-wrap img,
.about-us .right-block .left-block__img-wrap img {
  max-width: 540px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.about-us .left-block .quote,
.about-us .right-block .quote {
  background-color: #FFFFFF;
  margin: 0 0 42px 0;
  padding: 52px 95px 43px;
}

@media (max-width: 700px) {

  .about-us .left-block .quote,
  .about-us .right-block .quote {
    padding: 42px 60px 33px;
  }
}

.about-us .left-block .quote P,
.about-us .right-block .quote P {
  position: relative;
}

.about-us .left-block .quote P:before,
.about-us .right-block .quote P:before {
  content: "“";
  position: absolute;
  top: -10px;
  font-size: 35px;
  line-height: 25px;
}

.about-us .left-block .list,
.about-us .right-block .list {
  list-style: square;
  margin-left: 64px;
}

.about-us .left-block .list li:not(:last-child),
.about-us .right-block .list li:not(:last-child) {
  margin-bottom: 26px;
}

.faq {
  background-color: #DCE6E8;
}

.faq.visible {
  padding: 51px 0 69px 0;
  background: url("../images/main-page/faq/bg.jpg") center/cover no-repeat;
}

.faq__section-title {
  margin-bottom: 40px;
  margin-right: -20px;
}

.faq__section-title h2 {
  color: #2B4764;
  flex: 0 0 auto;
}

@media (max-width: 374px) {
  .faq__section-title h2 {
    max-width: 200px;
  }
}

.faq__section-title .line {
  background-color: #2B4764;
}

.faq__block {
  position: relative;
  max-width: 760px;
  width: 100%;
}

@media (max-width: 700px) {
  .faq .img-wrap {
    display: none;
  }
}

.faq .list-question {
  position: absolute;
  top: 42px;
  right: 0;
  max-width: 405px;
  width: 100%;
  list-style: square;
  background-color: #FFFFFF;
  padding: 30px 0 70px;
}

@media (max-width: 700px) {
  .faq .list-question {
    position: relative;
    top: 0;
    margin: 0 auto;
    padding: 30px 0 40px;
  }
}

.faq .list-question .question.active .question__text {
  background-color: #DCE6E8;
}

.faq .list-question .question:hover .question__text {
  background-color: #DCE6E8;
}

.faq .list-question .question__text {
  position: relative;
  display: flex;
  align-items: unset;
  width: 100%;
  text-align: unset;
  padding: 15px 53px 15px 70px;
  background-color: unset;
  color: #414141;
}

@media (max-width: 700px) {
  .faq .list-question .question__text {
    padding: 15px 20px 15px 40px;
  }
}

.faq .list-question .question__text:before {
  content: "";
  position: absolute;
  top: 22px;
  left: 40px;
  width: 5px;
  height: 5px;
  background-color: #535353;
}

@media (max-width: 700px) {
  .faq .list-question .question__text:before {
    left: 20px;
  }
}

.faq .list-question .question__answer {
  font-family: "RobotoLight";
  padding: 0 110px 0 80px;
  color: #414141;
}

@media (max-width: 700px) {
  .faq .list-question .question__answer {
    padding: 0 20px 0 40px;
  }
}

.forma__section .callendar {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background: url("/images/creating/calendar.svg") center/20px 20px no-repeat;
  pointer-events: none;
}

