.blog-page .listing-search {
  max-width: 635px;
}
.blog-page .search-field {
  padding: 0 94px 0 0;
}
.blog-page .search-btn {
  width: 94px;
  background-color: #2B4764;
  color: #ffffff;
  transition: background 0.4s ease 0s;
}
.blog-page .search-btn:hover {
  background: #6592C1;
}
.blog-page .blog__container {
  padding: 0 12.5px !important;
}
.blog-page .blog__btn-wrap {
  font-size: 18px;
  color: #535353;
  margin-top: 30px;
}
.blog-page .news-wrap {
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 576px) {
  .blog-page .news-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .blog-page .news-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) {
  .blog-page .news.news-big {
    grid-column: span 2;
    order: -1;
  }
  .blog-page .news.news-big .news__img-wrap {
    padding-bottom: 45.7%;
  }
}
@media (min-width: 992px) {
  .blog-page .news.news-big {
    order: unset;
  }
}
.blog-page .news__img-wrap {
  padding-bottom: 60%;
}
.blog-page .news__cnt {
  flex: 1 1 auto;
}
.blog-page .news__title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-bottom: 6px;
}
.blog-page .news__desc {
  padding: 16px 15px 15px;
}
.blog-page .news__desc p {
  -webkit-line-clamp: 4;
}
.blog-page .news__date {
  display: unset;
  justify-content: unset;
  align-items: unset;
  max-width: 86px;
  width: 100%;
}
.blog-page .news__date span {
  display: block;
  margin: 22px 0 8px;
}
.blog-page .news_subscribe .cnt {
  height: 100%;
  font-size: 24px;
  color: #ffffff;
  line-height: 140%;
  background-color: #2D424B;
  padding: 44px;
}
.blog-page .news_subscribe .cnt .btn {
  font-size: 14px;
  margin: 0 auto;
}
.blog-page .news_subscribe p {
  position: relative;
  margin: 0 0 100px;
}
.blog-page .news_subscribe p:before {
  content: "";
  position: absolute;
  top: -44px;
  left: -17px;
  width: 1px;
  height: 100%;
  background-color: #FFFFFF;
}

.rec-list {
  display: grid;
  -moz-column-gap: 30px;
  column-gap: 30px;
  row-gap: 56px;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 576px) {
  .rec-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .rec-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

.rec-news {
  height: 190px;
}
.rec-news_color-mod .rec-news__date-wrap {
  background-color: #8FB7C4;
}
.rec-news_color-mod .rec-news__date {
  background-color: #2B4764;
}
.rec-news_color-mod .rec-news__desc {
  background-color: #DCE6E8;
}
.rec-news a {
  display: flex;
  height: 100%;
}
.rec-news__date-wrap {
  border-right: 4px solid #2B4764;
}
.rec-news__date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 86px;
  background-color: #8AA0B8;
  color: #ffffff;
  font-size: 18px;
}
.rec-news__date span {
  font-size: 24px;
  line-height: 33px;
}
.rec-news__desc {
  color: #2B4764;
  padding: 11px 11px 11px 15px;
}
.rec-news__desc p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin: 8px 0 0;
}
.rec-news__title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.rec-news__title h3 {
  margin: 0;
}

.quantum-listers .list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}
@media (min-width: 576px) {
  .quantum-listers .list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .quantum-listers .list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.quantum-listers .list .item__img-wrap {
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 0;
  padding-bottom: 100%;
  padding-top: 78px;
}
.quantum-listers .list .item__img-wrap img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.quantum-listers .list .item__title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.quantum-listers .list .item__title h3 {
  margin-bottom: 20px;
}
.quantum-listers .list .item__cnt {
  flex: 1 1 auto;
  background-color: #DCE6E8;
  border-left: 6px solid #2B4764;
  color: #2B4764;
  padding: 8px 12px 10px 14px;
}
.quantum-listers .list .item__cnt p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin: 0;
}
.quantum-listers__btn-wrap {
  display: flex;
  justify-content: center;
  color: #535353;
  font-size: 18px;
}
@media (min-width: 768px) {
  .quantum-listers__btn-wrap {
    justify-content: flex-end;
  }
}

.news .forma__field .field__icon--email {
  fill: transparent;
  stroke: #fff;
}
.news .forma__section.error .field__icon--email {
  fill: transparent;
  stroke: #C13838;
}
.news .forma__input::-moz-placeholder {
  color: #fff;
}
.news .forma__input:-ms-input-placeholder {
  color: #fff;
}
.news .forma__input::placeholder {
  color: #fff;
}
.news .forma__input{
  color: #fff !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom-color: #fff;
  background: transparent !important;
}