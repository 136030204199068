.pricing {
  background-color: #DCE6E8;
}

.pricing.visible {
  background: linear-gradient(360deg, #1E333B 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(360deg, rgba(119, 150, 156, 0.71) 29.58%, rgba(30, 51, 60, 0) 103.59%), linear-gradient(0deg, rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.84)), url("/images/main-page/how-works/bg-1.png") center/cover no-repeat;
  padding: 70px 0 180px;
}

.about-prices {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.about-prices h3 {
  font-size: 24px;
  text-transform: uppercase;
}
.about-prices h4 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.about-prices .block {
  width: 33.333%;
  padding: 0 15px;
  margin-bottom: 30px;
}
.about-prices .block:nth-child(3n+2) .block__wrap {
  height: 102.49%;
}
.about-prices .block__wrap {
  overflow: hidden;
  position: relative;
  min-height: 683px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  line-height: 25px;
  color: #535353;
  border-radius: 20px;
  background-color: #FFFFFF;
  padding-bottom: 40px;
}
.about-prices .block .discount {
  z-index: 1;
  position: absolute;
  top: 0;
  right: -56px;
  background-image: url("/images/blog/popular.png");
  background-repeat: no-repeat;
  width: 175px;
  height: 114px;
}
.about-prices .block__cnt {
  background-color: #FFFFFF;
}
.about-prices .block p {
  margin: 0 0 20px;
}
.about-prices .block__title {
  background-color: #DCE6E8;
  border-radius: 20px 20px 0 0;
  padding: 20px 40px;
}
.about-prices .block__title h3 {
  margin: 0;
}
.about-prices .block__title .subtitle {
  font-size: 18px;
}
.about-prices .block .subtitle {
  font-family: "RobotoMedium";
  margin: 0;
}
.about-prices .block .status {
  display: block;
  color: #2394E7;
  margin-bottom: 40px;
}
.about-prices .block .features {
  padding: 20px 40px 0;
}
.about-prices .block .features .title {
  margin-bottom: 32px;
}
.about-prices .block .features .title .subtitle {
  font-size: 16px;
  line-height: 22px;
}
.about-prices .block .features .title .subtitle b {
  font-family: "RobotoBold";
}
.about-prices .block .list-services li {
  list-style: square inside;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  margin-bottom: 14px;
}
.about-prices .block .price {
  color: #2B4764;
  margin-bottom: 20px;
}
.about-prices .block .price__val {
  font-family: "RobotoBold";
}
.about-prices .block__btn-wrap {
  padding: 0 40px;
}

.bundles {
  padding: 30px 0 60px;
}

.about-bundles {
  display: flex;
}
.about-bundles__btn-wrap {
  max-width: 270px;
  width: 100%;
}
.about-bundles .left-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 350px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  padding: 20px 40px;
  margin: 0 30px 0 0;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(26, 49, 58, 0.8) 36.7%, rgba(19, 33, 39, 0.8) 100%), #001343;
}
.about-bundles .left-block__text-top {
  font-family: "RobotoBold";
  font-size: 24px;
  line-height: 33.5px;
  padding: 0 15px;
}
.about-bundles .left-block__text-bottom {
  font-family: "RobotoLight";
  font-size: 18px;
  line-height: 25px;
}

.about-bundles .right-block {
  background-color: #DCE6E8;
}

.about-bundles .right-block.visible {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 730px;
  width: 100%;
  min-height: 508px;
  color: #ffffff;
  padding: 30px 40px 40px;
  background: linear-gradient(92.05deg, rgba(12, 0, 0, 0.9) 1.57%, rgba(0, 30, 47, 0.9) 110.89%), url("/images/pricing/bundles-img.png") center/cover no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
}
.about-bundles .right-block h2 {
  margin-bottom: 0;
}
.about-bundles .right-block p {
  max-width: 500px;
  width: 100%;
  font-size: 18px;
  line-height: 29px;
  margin-top: 0;
}
.about-bundles .right-block p span {
  font-family: "RobotoBold";
}
.about-bundles .right-block .feature-list {
  max-width: 500px;
  width: 100%;
}
.about-bundles .right-block .feature-list li {
  list-style: square inside;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  margin-bottom: 14px;
}
.about-bundles .right-block .feature-list li:last-child {
  margin-bottom: 0;
}
.about-bundles .right-block .wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.about-bundles_reverse {
  align-items: unset;
}
.about-bundles_reverse .left-block {
  order: 2;
  background: linear-gradient(180deg, rgba(44, 26, 58, 0.8) 36.7%, rgba(19, 33, 39, 0.8) 100%), #10756F;
  margin: 0 0 0 30px;
}
.about-bundles_reverse .right-block {
  order: 1;
}

.about-cost_without-discount .about-cost__full-price {
  font-size: 36px;
  color: #ffffff;
  text-decoration: unset;
}

@media (max-width: 480px) {
  .about-cost_without-discount .about-cost__full-price {
    font-size: 22px;
  }
}

.about-cost__full-price {
  font-family: "RobotoMedium";
  font-size: 24px;
  color: #DCE6E8;
  text-decoration: line-through;
}
.about-cost__discount {
  font-family: "RobotoBold";
  font-size: 36px;
}

.exposure {
  background-color: #DCE6E8;
}

.exposure.visible {
  background: linear-gradient(92.05deg, rgba(12, 0, 0, 0.9) 1.57%, rgba(0, 30, 47, 0.9) 110.89%), url("/images/exposure/exposure-bg.png") center/cover no-repeat;
  padding: 30px 0 255px;
}

.exposure .section-title {
  margin-bottom: 90px;
}

.promotion-slider .btn-prev,
.promotion-slider .btn-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 50px;
  opacity: 0.6;
  transition: 0.3s;
}
.promotion-slider .btn-prev:hover,
.promotion-slider .btn-next:hover {
  opacity: 1;
}
.promotion-slider .btn-prev {
  background-image: url("/images/main-page/how-works/arrow-left.svg");
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translate(0, -50%);
  z-index: 2;
}
@media (max-width: 1250px) {
  .promotion-slider .btn-prev {
    left: -15px;
  }
}
.promotion-slider .btn-next {
  background-image: url("/images/main-page/how-works/arrow-right.svg");
  padding: 0;
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translate(0, -50%);
}
@media (max-width: 1250px) {
  .promotion-slider .btn-next {
    right: -15px;
  }
}

.promotion-point {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 300px;
  width: 100%;
  height: 325px;
  color: #535353;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 42px;
}
.promotion-point__cnt p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.levels {
  padding: 30px 0 240px;
}

.level-table {
  width: 100%;
  color: #2B4764;
}
.level-table__head {
  height: 60px;
}
.level-table__head th {
  font-family: "RobotoMedium";
  font-size: 16px;
  font-weight: 500;
  width: 17.1%;
  min-width: 140px;
  padding: 0 10px;
  background-color: #FFFFFF;
}
.level-table__head th:first-child {
  width: 31.44%;
  font-size: 24px;
  color: #ffffff;
  text-align: start;
  text-transform: uppercase;
  padding-left: 20px;
  background-color: #2B4764;
}
.level-table__head th:nth-child(4) {
  background-color: #F8F8F8;
}
.level-table tr {
  background-color: #DCE6E8;
  height: 60px;
}
.level-table tr:nth-child(even) {
  background-color: #FFFFFF;
}
.level-table tr td {
  font-size: 16px;
  padding: 5px;
}
.level-table tr td:first-child {
  padding-left: 20px;
}
.level-table .mark {
  overflow: hidden;
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid #9FB5CB;
  border-radius: 5px;
  margin: 0 auto;
}
.level-table .mark_selected {
  background-color: #2B4764;
}
.level-table .mark_selected:before {
  position: absolute;
  content: "";
  top: 8px;
  left: 2px;
  transform: rotate(45deg);
  width: 8px;
  height: 2px;
  background-color: #FFFFFF;
}
.level-table .mark_selected:after {
  position: absolute;
  content: "";
  top: 7px;
  left: 7px;
  transform: rotate(-45deg);
  width: 13px;
  height: 2px;
  background-color: #FFFFFF;
}

@media screen and (max-width: 991px) {
  .pricing {
    padding: 30px 0 40px;
  }

  .about-prices {
    justify-content: center;
  }
  .about-prices .block {
    width: 40.04%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
  .about-prices .block:nth-child(3n+2) .block__wrap {
    height: unset;
  }

  .bundles {
    padding: 30px 0 40px;
  }

  .exposure {
    padding-bottom: 205px;
  }
  .exposure .section-title {
    margin-bottom: 50px;
  }

  .levels {
    padding-bottom: 100px;
  }

  .level-table__head th {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .container-title {
    padding: 0 0 0 10px !important;
  }
  .container-title_reverse {
    padding: 0 10px 0 0 !important;
  }

  .pricing .container {
    padding: 0 10px;
  }

  .about-prices {
    justify-content: center;
    margin: 0;
  }
  .about-prices .block {
    width: 370px;
    margin-bottom: 60px;
  }
  .about-prices .block:last-child {
    margin-bottom: 0;
  }

  .about-bundles {
    flex-direction: column;
    align-items: center;
  }
  .about-bundles .left-block {
    order: 2;
    margin-right: 0;
  }
  .about-bundles .right-block {
    order: 1;
    max-width: 350px;
    margin-bottom: 20px;
  }
  .about-bundles_reverse .left-block {
    margin-left: 0;
  }

  .promotion-slider {
    padding: 0 30px;
  }

  .level-table__head th {
    font-size: 11px;
    min-width: unset;
    padding: 0 6px;
    line-height: 15px;
  }
  .level-table tr td {
    font-size: 12px;
  }
  .level-table__head th:first-child {
    font-size: 18px;
    min-width: 120px;
    padding-left: 12px;
  }
  .level-table tr td:first-child {
    padding-left: 12px;
  }
}
@media screen and (max-width: 600px) {
  .exposure .section-title .line {
    display: none;
  }

  .promotion-point {
    margin: 0 auto;
  }
}
@media screen and (max-width: 374px) {
  .level-table__head th {
    font-size: 10px;
    min-width: unset;
  }
  .level-table__head th:first-child {
    font-size: 10px;
    min-width: unset;
    padding-left: 10px;
  }
  .level-table tr td {
    font-size: 10px;
    line-height: 15px;
  }
  .level-table tr td:first-child {
    padding-left: 10px;
    padding-right: unset;
  }
}

@media (min-width: 768px) {
  .about-bundles .left-block-bottom {
    justify-content: flex-end;
    padding-bottom: 100px;
  }
  .about-bundles .left-block-top {
    justify-content: flex-start;
    padding-top: 100px;
  }
}
.about-bundles .right-block-modify {
  background-color: #DCE6E8;
}

.about-bundles .right-block-modify.visible {
  max-width: 100%;
  min-height: 361px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.84)), url("/images/pricing/bundles-img1.png") center/cover no-repeat;
}
.about-bundles .right-block-modify::before {
  display: none;
}