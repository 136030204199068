.about-page .network-tab {
  color: #535353;
}

.about-page .network-tab:hover {
  color: #fff;
}

.about-page .network-tab.active {
  color: #fff;
}

@media (max-width: 767px) {
  .about-page .ask-question {
    margin-top: 140px !important;
  }
}

.content-editor {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
}

.content-editor p {
  margin: 0;
}

.content-editor ul,
.content-editor ol {
  padding-left: 40px;
}

.content-editor ul li:not(:last-child),
.content-editor ol li:not(:last-child) {
  margin-bottom: 10px;
}

.content-editor ul {
  list-style-type: disc;
}

.content-editor ol {
  list-style-type: decimal;
}

.content-editor a {
  color: #507DAC;
}

.content-editor a:hover {
  color: #2B4764;
}

.content-editor b {
  font-family: "RobotoBold";
}

.contacts-img {
  position: relative;
  min-height: 254px;
  width: 100%;
}

@media (min-width: 576px) {
  .contacts-img {
    min-height: 354px;
  }
}

.contacts-img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.bg-support {
  min-height: 830px;
  background: linear-gradient(174.49deg, #00505B 45.93%, rgba(0, 0, 0, 0) 82.96%), url("../images/support/shedule.png") bottom left/cover no-repeat;
}

@media (min-width: 768px) {
  .bg-support {
    min-height: auto;
    background: linear-gradient(91.74deg, #00505B 30.35%, rgba(0, 0, 0, 0) 98.79%), #44394D;
  }
}

.support-list {
  display: flex;
  margin-top: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  row-gap: 20px;
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .support-list {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media (min-width: 768px) {
  .support-list {
    margin-top: 90px;
  }
}

.support-card {
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 20px 30px 30px;
}

@media (min-width: 570px) {
  .support-card {
    flex: 0 1 255px;
  }
}

.about-page .network-tab {
  white-space: inherit;
  text-align: center;
}