.footer {
  position: relative;
  z-index: 10;
}
.footer .lists-wrap {
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 5px;
  column-gap: 5px;
  padding: 48px 0 43px;
}
@media (max-width: 991px) {
  .footer .lists-wrap {
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    text-align: center;
  }
}
.footer .lists-wrap .list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 100% !important;
  gap: 10px;
  max-width: 255px;
  width: 100%;
}
@media (max-width: 991px) {
  .footer .lists-wrap .list {
    max-width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 991px) {
  .footer .lists-wrap .list--hidden {
    display: none;
  }
}
.footer .lists-wrap .list li:last-child {
  margin-bottom: 0;
}
.footer .lists-wrap .list a {
  font-size: 12px;
  line-height: 13px;
  color: #2B4764;
}
@media (max-width: 991px) {
  .footer .lists-wrap .list a {
    font-size: 18px;
    line-height: 140%;
  }
}
.footer .lists-wrap .lists__more {
  display: none;
  position: relative;
  padding-right: 20px;
  background: transparent;
}
.footer .lists-wrap .lists__more::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  width: 14px;
  height: 8px;
  background: url("../images/footer/arrow.png") 0 0/14px 8px no-repeat;
}
@media (max-width: 991px) {
  .footer .lists-wrap .lists__more {
    display: block;
  }
}
.footer .bottom-side {
  background-color: #0C111B;
}
.footer .bottom-side__top {
  display: flex;
  justify-content: center;
  -moz-column-gap: 27px;
  column-gap: 27px;
  padding: 30px 0 15px;
  border-bottom: 1px solid #232A36;
}
@media (max-width: 370px) {
  .footer .bottom-side__top {
    -moz-column-gap: 15px;
    column-gap: 15px;
  }
}
.footer .bottom-side__top a {
  font-size: 12px;
  color: #ffffff;
}
.footer .bottom-side__social-networks {
  display: flex;
  justify-content: center;
  font-size: 0;
  border-bottom: 1px solid #232A36;
  padding: 16px 0 14px;
}
.footer .bottom-side__social-networks li {
  margin: 0 8px;
}
.footer .bottom-side__social-networks a, .footer .bottom-side__social-networks form {
  display: flex;
  width: 48px;
  height: 48px;
}
.footer .bottom-side__social-networks a:hover svg path, .footer .bottom-side__social-networks form:hover svg path {
  fill: #9FB5CB;
}
.footer .bottom-side__social-networks a svg, .footer .bottom-side__social-networks form svg {
  max-width: 100%;
}
@media (max-width: 370px) {
  .footer .bottom-side__social-networks a, .footer .bottom-side__social-networks form {
    width: 36px;
    height: 36px;
  }
}
.footer .bottom-side__copyright {
  padding: 20px;
}
.footer .bottom-side__copyright p {
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #808080;
  margin: 0 0 6px;
}
.footer .bottom-side__copyright p:last-child {
  color: #E1E1E1;
  margin: 0;
}
@media (max-width: 370px) {
  .footer .bottom-side__copyright p {
    text-align: center;
  }
}