.how-it-link {
  position: relative;
  line-height: 140%;
  color: #727272;
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 55px;
  padding: 10px 0 10px 28px;
  transition: all 0.4s ease 0s;
}
@media (min-width: 768px) {
  .how-it-link {
    padding: 10px 28px;
    font-size: 18px;
  }
}
.how-it-link::before {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background: #2B4764;
}
.how-it-link:hover {
  background: #DCE6E8;
  color: #2B4764;
}

.how-it-tab {
  transition: all 0.4s ease 0s;
}
.how-it-tab:hover {
  background: #DCE6E8;
  color: #2B4764 !important;
}
.how-it-tab.active {
  background: #DCE6E8;
  color: #2B4764 !important;
  pointer-events: none;
}