.overflow-auto {
  overflow: auto;
}

.text-center {
  text-align: center;
}

.active {
  opacity: 1 !important;
  visibility: visible !important;
}

.string {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.row-2 {
  -webkit-line-clamp: 2;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-none {
  display: none !important;
}

.grid-col {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media (min-width: 576px) {
  .grid-col {
    grid-template-columns: repeat(auto-fit, minmax(250px, 0.5fr));
  }
}

@media (min-width: 768px) {
  .grid-col {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (min-width: 950px) {
  .grid-col {
    grid-template-columns: repeat(auto-fit, minmax(250px, 0.5fr));
  }
}

.grid-col2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -moz-column-gap: 36px;
  column-gap: 36px;
  row-gap: 14px;
}

@media (min-width: 576px) {
  .grid-col2 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) {
  .grid-col2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .grid-col2 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.grid-col3 {
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 425px) {
  .grid-col3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .grid-col3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 900px) {
  .grid-col3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1100px) {
  .grid-col3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.grid-col4 {
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
}

@media (min-width: 576px) {
  .grid-col4 {
    grid-template-columns: repeat(auto-fit, minmax(230px, 0.5fr));
  }
}

@media (min-width: 768px) {
  .grid-col4 {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }
}

@media (min-width: 900px) {
  .grid-col4 {
    grid-template-columns: repeat(auto-fit, minmax(230px, 0.5fr));
  }
}

@media (min-width: 1200px) {
  .grid-col4 {
    grid-template-columns: repeat(auto-fit, minmax(230px, 0.333fr));
  }
}

.grid-col5 {
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 576px) {
  .grid-col5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-col5 .span-2 {
    grid-column: span 2;
  }
}

.shadow {
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-sm {
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-hover-sm:hover {
  box-shadow: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.translate-middle {
  transform: translate(50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.rotate-45 {
  transform: rotate(45deg) !important;
}

.bg-white {
  background-color: white !important;
}

.bg-white-opacity-74 {
  background-color: rgba(255, 255, 255, 0.74) !important;
}

.bg-white-smoke {
  background-color: #f8f8f8 !important;
}

.bg-nepal {
  background-color: #95a6b7 !important;
}

.bg-silver-tree {
  background-color: #6be695 !important;
}

.bg-arapawa {
  background-color: #2b4764 !important;
}

.bg-dodgerBlue {
  background-color: #2394e7 !important;
}

.bg-solitude {
  background-color: #ebedf1 !important;
}

.bg-zircon {
  background-color: #dce6e8 !important;
}

.bg-havelock {
  background-color: #418ad6 !important;
}

.bg-moss {
  background-color: #b8f1b3 !important;
}

.bg-nero {
  background-color: #232323 !important;
}

.bg-scooter {
  background-color: #2084a4 !important;
}

.bg-astroBlue {
  background-color: #1c4a5e !important;
}

.bg-bigStone {
  background-color: #2d424b !important;
}

.bg-persianRed {
  background-color: #c13838 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-hover-arapawa:hover {
  background-color: #2B4764 !important;
}

.bg-hover-danube:hover {
  background-color: #6592C1 !important;
}

.bg-hover-nepal:hover {
  background-color: #95A6B7 !important;
}

.bg-hover-onahau:hover {
  background-color: #cbe8ee !important;
}

.bg-hover-scooter:hover {
  background-color: #2084A4 !important;
}

.bg-hover-silverTree:hover {
  background-color: #6BE695 !important;
}

.bg-hover-persianRed:hover {
  background-color: #C13838 !important;
}

.bg-hover-cinnabar:hover {
  background-color: #E62D2D !important;
}

.hoverable {
  transition: all 0.4s ease 0s;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.border {
  border: 1px solid #EFF2F5 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #EFF2F5 !important;
}

.border-start {
  border-left: 1px solid #EFF2F5 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #EFF2F5 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-end {
  border-right: 1px solid #EFF2F5 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-persianRed {
  border-color: #C13838 !important;
}

.border-cinnabar {
  border-color: #E62D2D !important;
}

.border-arapawa {
  border-color: #2B4764 !important;
}

.border-hover-scooter:hover {
  border-color: #2084A4 !important;
}

.border-matterhorn {
  border-color: #535353 !important;
}

.border-echoBlue {
  border-color: #9FB5CB !important;
}

.border-white-smoke {
  border-color: #F8F8F8 !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.border-zircon {
  border-color: #DCE6E8 !important;
}

.border-black-opacity-40 {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.border-hover-persianRed:hover {
  border-color: #C13838 !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-top-0 {
  border-top-width: 0 !important;
}

.border-top-1 {
  border-top-width: 1px !important;
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-top-3 {
  border-top-width: 3px !important;
}

.border-top-4 {
  border-top-width: 4px !important;
}

.border-top-5 {
  border-top-width: 5px !important;
}

.border-bottom-0 {
  border-bottom-width: 0 !important;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
}

.border-bottom-4 {
  border-bottom-width: 4px !important;
}

.border-bottom-5 {
  border-bottom-width: 5px !important;
}

.border-left-0 {
  border-left-width: 0 !important;
}

.border-left-1 {
  border-left-width: 1px !important;
}

.border-left-2 {
  border-left-width: 2px !important;
}

.border-left-3 {
  border-left-width: 3px !important;
}

.border-left-4 {
  border-left-width: 4px !important;
}

.border-left-5 {
  border-left-width: 5px !important;
}

.border-right-0 {
  border-right-width: 0 !important;
}

.border-right-1 {
  border-right-width: 1px !important;
}

.border-right-2 {
  border-right-width: 2px !important;
}

.border-right-3 {
  border-right-width: 3px !important;
}

.border-right-4 {
  border-right-width: 4px !important;
}

.border-right-5 {
  border-right-width: 5px !important;
}

.border-right-12 {
  border-right-width: 12px !important;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}

.rounded-33px {
  border-radius: 33px;
}

.rounded-5px {
  border-radius: 5px;
}

@media (min-width: 576px) {
  .border-sm {
    border: 1px solid #EFF2F5 !important;
  }

  .border-sm-end {
    border-right: 1px solid #EFF2F5 !important;
  }

  .border-black-opacity-sm-40 {
    border-color: rgba(0, 0, 0, 0.4) !important;
  }

  .border-start-sm-0 {
    border-left: 0 !important;
  }
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.flex-fill {
  flex: 1 1 auto !important;
  align-self: stretch;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-17 {
  gap: 4.25rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-19 {
  gap: 4.75rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.row-gap-3px {
  row-gap: 3px;
}

.col-gap-6px {
  -moz-column-gap: 6px;
  column-gap: 6px;
}

.col-gap-10px {
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.col-gap-14px {
  -moz-column-gap: 14px;
  column-gap: 14px;
}

.col-gap-15px {
  -moz-column-gap: 15px;
  column-gap: 15px;
}

.col-gap-17px {
  -moz-column-gap: 17px;
  column-gap: 17px;
}

.col-gap-20px {
  -moz-column-gap: 20px !important;
  column-gap: 20px !important;
}

.col-gap-30px {
  -moz-column-gap: 30px;
  column-gap: 30px;
}

.col-gap-100px {
  -moz-column-gap: 100px;
  column-gap: 100px;
}

.row-gap-8px {
  row-gap: 8px;
}

.row-gap-10px {
  row-gap: 10px;
}

.row-gap-15px {
  row-gap: 15px;
}

.row-gap-18px {
  row-gap: 18px;
}

.row-gap-20px {
  row-gap: 20px;
}

.row-gap-30px {
  row-gap: 30px;
}

.row-gap-40px {
  row-gap: 40px;
}

.flex-310px {
  flex: 0 1 310px;
}

.flex-min-240 {
  min-width: 240px;
}

.flex-240px {
  width: 240px;
  /* flex: 0 1 240px; */
}

.flex-50 {
  flex: 0 1 50%;
}

.flex-100 {
  flex: 0 1 100%;
}

.flex-230px {
  flex: 0 0 230px;
}

.flex-170px {
  flex: 0 1 170px;
}

.flex-350px {
  flex: 0 0 350px;
}

.flex-30px {
  flex: 0 0 30px;
}

.flex-auto {
  flex: 0 0 auto !important;
}

@media (min-width: 576px) {
  .d-sm-flex {
    display: flex !important;
  }

  .col-gap-sm-30px {
    -moz-column-gap: 30px !important;
    column-gap: 30px !important;
  }

  .d-inline-sm-flex {
    display: inline-flex !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-row-sm-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-column-sm-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-grow-sm-0 {
    flex-grow: 0 !important;
  }

  .flex-grow-sm-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-sm-0 {
    flex-shrink: 0 !important;
  }

  .flex-shrink-sm-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-sm-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-9 {
    gap: 2.25rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-11 {
    gap: 2.75rem !important;
  }

  .gap-sm-12 {
    gap: 3rem !important;
  }

  .gap-sm-13 {
    gap: 3.25rem !important;
  }

  .gap-sm-14 {
    gap: 3.5rem !important;
  }

  .gap-sm-15 {
    gap: 3.75rem !important;
  }

  .gap-sm-16 {
    gap: 4rem !important;
  }

  .gap-sm-17 {
    gap: 4.25rem !important;
  }

  .gap-sm-18 {
    gap: 4.5rem !important;
  }

  .gap-sm-19 {
    gap: 4.75rem !important;
  }

  .gap-sm-20 {
    gap: 5rem !important;
  }

  .flex-sm-240px {
    flex: 0 1 240px !important;
  }

  .flex-sm-auto {
    flex: 0 0 auto !important;
  }

  .flex-sm-170px {
    flex: 0 1 170px !important;
  }
}

@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }

  .d-inline-md-flex {
    display: inline-flex !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-row-md-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-column-md-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-grow-md-0 {
    flex-grow: 0 !important;
  }

  .flex-grow-md-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-md-0 {
    flex-shrink: 0 !important;
  }

  .flex-shrink-md-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-md-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-9 {
    gap: 2.25rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-11 {
    gap: 2.75rem !important;
  }

  .gap-md-12 {
    gap: 3rem !important;
  }

  .gap-md-13 {
    gap: 3.25rem !important;
  }

  .gap-md-14 {
    gap: 3.5rem !important;
  }

  .gap-md-15 {
    gap: 3.75rem !important;
  }

  .gap-md-16 {
    gap: 4rem !important;
  }

  .gap-md-17 {
    gap: 4.25rem !important;
  }

  .gap-md-18 {
    gap: 4.5rem !important;
  }

  .gap-md-19 {
    gap: 4.75rem !important;
  }

  .gap-md-20 {
    gap: 5rem !important;
  }
}

@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
  }

  .col-gap-lg-20px {
    -moz-column-gap: 20px;
    column-gap: 20px;
  }

  .col-gap-lg-30px {
    -moz-column-gap: 30px !important;
    column-gap: 30px !important;
  }

  .d-inline-lg-flex {
    display: inline-flex !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-row-lg-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-column-lg-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-grow-lg-0 {
    flex-grow: 0 !important;
  }

  .flex-grow-lg-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-lg-0 {
    flex-shrink: 0 !important;
  }

  .flex-shrink-lg-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-lg-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-9 {
    gap: 2.25rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-11 {
    gap: 2.75rem !important;
  }

  .gap-lg-12 {
    gap: 3rem !important;
  }

  .gap-lg-13 {
    gap: 3.25rem !important;
  }

  .gap-lg-14 {
    gap: 3.5rem !important;
  }

  .gap-lg-15 {
    gap: 3.75rem !important;
  }

  .gap-lg-16 {
    gap: 4rem !important;
  }

  .gap-lg-17 {
    gap: 4.25rem !important;
  }

  .gap-lg-18 {
    gap: 4.5rem !important;
  }

  .gap-lg-19 {
    gap: 4.75rem !important;
  }

  .gap-lg-20 {
    gap: 5rem !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-flex {
    display: flex !important;
  }

  .d-inline-xl-flex {
    display: inline-flex !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-row-xl-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-column-xl-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-grow-xl-0 {
    flex-grow: 0 !important;
  }

  .flex-grow-xl-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-xl-0 {
    flex-shrink: 0 !important;
  }

  .flex-shrink-xl-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-xl-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-9 {
    gap: 2.25rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-11 {
    gap: 2.75rem !important;
  }

  .gap-xl-12 {
    gap: 3rem !important;
  }

  .gap-xl-13 {
    gap: 3.25rem !important;
  }

  .gap-xl-14 {
    gap: 3.5rem !important;
  }

  .gap-xl-15 {
    gap: 3.75rem !important;
  }

  .gap-xl-16 {
    gap: 4rem !important;
  }

  .gap-xl-17 {
    gap: 4.25rem !important;
  }

  .gap-xl-18 {
    gap: 4.5rem !important;
  }

  .gap-xl-19 {
    gap: 4.75rem !important;
  }

  .gap-xl-20 {
    gap: 5rem !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-flex {
    display: flex !important;
  }

  .d-inline-xxl-flex {
    display: inline-flex !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-row-xxl-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-column-xxl-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-grow-xxl-0 {
    flex-grow: 0 !important;
  }

  .flex-grow-xxl-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-xxl-0 {
    flex-shrink: 0 !important;
  }

  .flex-shrink-xxl-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-xxl-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-9 {
    gap: 2.25rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-11 {
    gap: 2.75rem !important;
  }

  .gap-xxl-12 {
    gap: 3rem !important;
  }

  .gap-xxl-13 {
    gap: 3.25rem !important;
  }

  .gap-xxl-14 {
    gap: 3.5rem !important;
  }

  .gap-xxl-15 {
    gap: 3.75rem !important;
  }

  .gap-xxl-16 {
    gap: 4rem !important;
  }

  .gap-xxl-17 {
    gap: 4.25rem !important;
  }

  .gap-xxl-18 {
    gap: 4.5rem !important;
  }

  .gap-xxl-19 {
    gap: 4.75rem !important;
  }

  .gap-xxl-20 {
    gap: 5rem !important;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0rem;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0rem;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.25rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.25rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 2.5rem;
}

@media (min-width: 576px) {

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0rem;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0rem;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 768px) {

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0rem;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0rem;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2rem;
  }

  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2rem;
  }

  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 992px) {

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0rem;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0rem;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 1200px) {

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0rem;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0rem;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 1400px) {

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0rem;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0rem;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-7 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-9 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-11 {
  margin-left: 2.75rem !important;
  margin-right: 2.75rem !important;
}

.mx-12 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-13 {
  margin-left: 3.25rem !important;
  margin-right: 3.25rem !important;
}

.mx-14 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.mx-15 {
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important;
}

.mx-16 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-17 {
  margin-left: 4.25rem !important;
  margin-right: 4.25rem !important;
}

.mx-18 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.mx-19 {
  margin-left: 4.75rem !important;
  margin-right: 4.75rem !important;
}

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-13 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-15 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-17 {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-19 {
  margin-top: 4.75rem !important;
  margin-bottom: 4.75rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-13 {
  margin-top: 3.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-15 {
  margin-top: 3.75rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-17 {
  margin-top: 4.25rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-19 {
  margin-top: 4.75rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-9 {
  margin-left: 2.25rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-11 {
  margin-left: 2.75rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-13 {
  margin-left: 3.25rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-15 {
  margin-left: 3.75rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-17 {
  margin-left: 4.25rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-19 {
  margin-left: 4.75rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-13 {
  margin-bottom: 3.25rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-15 {
  margin-bottom: 3.75rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-17 {
  margin-bottom: 4.25rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-19 {
  margin-bottom: 4.75rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-9 {
  margin-right: 2.25rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-11 {
  margin-right: 2.75rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-13 {
  margin-right: 3.25rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-15 {
  margin-right: 3.75rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-17 {
  margin-right: 4.25rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-19 {
  margin-right: 4.75rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n11 {
  margin: -2.75rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.m-n13 {
  margin: -3.25rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.m-n15 {
  margin: -3.75rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.m-n17 {
  margin: -4.25rem !important;
}

.m-n18 {
  margin: -4.5rem !important;
}

.m-n19 {
  margin: -4.75rem !important;
}

.m-n20 {
  margin: -5rem !important;
}

.mx-n1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.mx-n3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.mx-n4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.mx-n6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n7 {
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}

.mx-n8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.mx-n9 {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.mx-n10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.mx-n11 {
  margin-left: -2.75rem !important;
  margin-right: -2.75rem !important;
}

.mx-n12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.mx-n13 {
  margin-left: -3.25rem !important;
  margin-right: -3.25rem !important;
}

.mx-n14 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.mx-n15 {
  margin-left: -3.75rem !important;
  margin-right: -3.75rem !important;
}

.mx-n16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.mx-n17 {
  margin-left: -4.25rem !important;
  margin-right: -4.25rem !important;
}

.mx-n18 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.mx-n19 {
  margin-left: -4.75rem !important;
  margin-right: -4.75rem !important;
}

.mx-n20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n13 {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}

.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n15 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n17 {
  margin-top: -4.25rem !important;
  margin-bottom: -4.25rem !important;
}

.my-n18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n19 {
  margin-top: -4.75rem !important;
  margin-bottom: -4.75rem !important;
}

.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n9 {
  margin-top: -2.25rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n11 {
  margin-top: -2.75rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.mt-n13 {
  margin-top: -3.25rem !important;
}

.mt-n14 {
  margin-top: -3.5rem !important;
}

.mt-n15 {
  margin-top: -3.75rem !important;
}

.mt-n16 {
  margin-top: -4rem !important;
}

.mt-n17 {
  margin-top: -4.25rem !important;
}

.mt-n18 {
  margin-top: -4.5rem !important;
}

.mt-n19 {
  margin-top: -4.75rem !important;
}

.mt-n20 {
  margin-top: -5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n9 {
  margin-left: -2.25rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.ms-n11 {
  margin-left: -2.75rem !important;
}

.ms-n12 {
  margin-left: -3rem !important;
}

.ms-n13 {
  margin-left: -3.25rem !important;
}

.ms-n14 {
  margin-left: -3.5rem !important;
}

.ms-n15 {
  margin-left: -3.75rem !important;
}

.ms-n16 {
  margin-left: -4rem !important;
}

.ms-n17 {
  margin-left: -4.25rem !important;
}

.ms-n18 {
  margin-left: -4.5rem !important;
}

.ms-n19 {
  margin-left: -4.75rem !important;
}

.ms-n20 {
  margin-left: -5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n9 {
  margin-bottom: -2.25rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n11 {
  margin-bottom: -2.75rem !important;
}

.mb-n12 {
  margin-bottom: -3rem !important;
}

.mb-n13 {
  margin-bottom: -3.25rem !important;
}

.mb-n14 {
  margin-bottom: -3.5rem !important;
}

.mb-n15 {
  margin-bottom: -3.75rem !important;
}

.mb-n16 {
  margin-bottom: -4rem !important;
}

.mb-n17 {
  margin-bottom: -4.25rem !important;
}

.mb-n18 {
  margin-bottom: -4.5rem !important;
}

.mb-n19 {
  margin-bottom: -4.75rem !important;
}

.mb-n20 {
  margin-bottom: -5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n9 {
  margin-right: -2.25rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.me-n11 {
  margin-right: -2.75rem !important;
}

.me-n12 {
  margin-right: -3rem !important;
}

.me-n13 {
  margin-right: -3.25rem !important;
}

.me-n14 {
  margin-right: -3.5rem !important;
}

.me-n15 {
  margin-right: -3.75rem !important;
}

.me-n16 {
  margin-right: -4rem !important;
}

.me-n17 {
  margin-right: -4.25rem !important;
}

.me-n18 {
  margin-right: -4.5rem !important;
}

.me-n19 {
  margin-right: -4.75rem !important;
}

.me-n20 {
  margin-right: -5rem !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-9 {
    margin: 2.25rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-11 {
    margin: 2.75rem !important;
  }

  .m-sm-12 {
    margin: 3rem !important;
  }

  .m-sm-13 {
    margin: 3.25rem !important;
  }

  .m-sm-14 {
    margin: 3.5rem !important;
  }

  .m-sm-15 {
    margin: 3.75rem !important;
  }

  .m-sm-16 {
    margin: 4rem !important;
  }

  .m-sm-17 {
    margin: 4.25rem !important;
  }

  .m-sm-18 {
    margin: 4.5rem !important;
  }

  .m-sm-19 {
    margin: 4.75rem !important;
  }

  .m-sm-20 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-sm-9 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-sm-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-sm-11 {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .mx-sm-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-13 {
    margin-left: 3.25rem !important;
    margin-right: 3.25rem !important;
  }

  .mx-sm-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-sm-15 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
  }

  .mx-sm-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-sm-17 {
    margin-left: 4.25rem !important;
    margin-right: 4.25rem !important;
  }

  .mx-sm-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-sm-19 {
    margin-left: 4.75rem !important;
    margin-right: 4.75rem !important;
  }

  .mx-sm-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 2.75rem !important;
  }

  .mt-sm-12 {
    margin-top: 3rem !important;
  }

  .mt-sm-13 {
    margin-top: 3.25rem !important;
  }

  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 3.75rem !important;
  }

  .mt-sm-16 {
    margin-top: 4rem !important;
  }

  .mt-sm-17 {
    margin-top: 4.25rem !important;
  }

  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-19 {
    margin-top: 4.75rem !important;
  }

  .mt-sm-20 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-9 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 2.75rem !important;
  }

  .ms-sm-12 {
    margin-left: 3rem !important;
  }

  .ms-sm-13 {
    margin-left: 3.25rem !important;
  }

  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 3.75rem !important;
  }

  .ms-sm-16 {
    margin-left: 4rem !important;
  }

  .ms-sm-17 {
    margin-left: 4.25rem !important;
  }

  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-19 {
    margin-left: 4.75rem !important;
  }

  .ms-sm-20 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 0.75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-9 {
    margin-right: 2.25rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-11 {
    margin-right: 2.75rem !important;
  }

  .me-sm-12 {
    margin-right: 3rem !important;
  }

  .me-sm-13 {
    margin-right: 3.25rem !important;
  }

  .me-sm-14 {
    margin-right: 3.5rem !important;
  }

  .me-sm-15 {
    margin-right: 3.75rem !important;
  }

  .me-sm-16 {
    margin-right: 4rem !important;
  }

  .me-sm-17 {
    margin-right: 4.25rem !important;
  }

  .me-sm-18 {
    margin-right: 4.5rem !important;
  }

  .me-sm-19 {
    margin-right: 4.75rem !important;
  }

  .me-sm-20 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n9 {
    margin: -2.25rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .m-sm-n11 {
    margin: -2.75rem !important;
  }

  .m-sm-n12 {
    margin: -3rem !important;
  }

  .m-sm-n13 {
    margin: -3.25rem !important;
  }

  .m-sm-n14 {
    margin: -3.5rem !important;
  }

  .m-sm-n15 {
    margin: -3.75rem !important;
  }

  .m-sm-n16 {
    margin: -4rem !important;
  }

  .m-sm-n17 {
    margin: -4.25rem !important;
  }

  .m-sm-n18 {
    margin: -4.5rem !important;
  }

  .m-sm-n19 {
    margin: -4.75rem !important;
  }

  .m-sm-n20 {
    margin: -5rem !important;
  }

  .mx-sm-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-sm-n9 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-sm-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-sm-n11 {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .mx-sm-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-sm-n13 {
    margin-left: -3.25rem !important;
    margin-right: -3.25rem !important;
  }

  .mx-sm-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-sm-n15 {
    margin-left: -3.75rem !important;
    margin-right: -3.75rem !important;
  }

  .mx-sm-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-sm-n17 {
    margin-left: -4.25rem !important;
    margin-right: -4.25rem !important;
  }

  .mx-sm-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-sm-n19 {
    margin-left: -4.75rem !important;
    margin-right: -4.75rem !important;
  }

  .mx-sm-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-sm-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-sm-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-sm-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-sm-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-sm-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-sm-n12 {
    margin-top: -3rem !important;
  }

  .mt-sm-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-sm-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-sm-n16 {
    margin-top: -4rem !important;
  }

  .mt-sm-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-sm-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-sm-n20 {
    margin-top: -5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2rem !important;
  }

  .ms-sm-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-sm-n12 {
    margin-left: -3rem !important;
  }

  .ms-sm-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-sm-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-sm-n16 {
    margin-left: -4rem !important;
  }

  .ms-sm-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-sm-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-sm-n20 {
    margin-left: -5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n8 {
    margin-right: -2rem !important;
  }

  .me-sm-n9 {
    margin-right: -2.25rem !important;
  }

  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n11 {
    margin-right: -2.75rem !important;
  }

  .me-sm-n12 {
    margin-right: -3rem !important;
  }

  .me-sm-n13 {
    margin-right: -3.25rem !important;
  }

  .me-sm-n14 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n15 {
    margin-right: -3.75rem !important;
  }

  .me-sm-n16 {
    margin-right: -4rem !important;
  }

  .me-sm-n17 {
    margin-right: -4.25rem !important;
  }

  .me-sm-n18 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n19 {
    margin-right: -4.75rem !important;
  }

  .me-sm-n20 {
    margin-right: -5rem !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-9 {
    margin: 2.25rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-11 {
    margin: 2.75rem !important;
  }

  .m-md-12 {
    margin: 3rem !important;
  }

  .m-md-13 {
    margin: 3.25rem !important;
  }

  .m-md-14 {
    margin: 3.5rem !important;
  }

  .m-md-15 {
    margin: 3.75rem !important;
  }

  .m-md-16 {
    margin: 4rem !important;
  }

  .m-md-17 {
    margin: 4.25rem !important;
  }

  .m-md-18 {
    margin: 4.5rem !important;
  }

  .m-md-19 {
    margin: 4.75rem !important;
  }

  .m-md-20 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-md-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .mx-md-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-md-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-md-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-md-9 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-md-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-md-11 {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .mx-md-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-13 {
    margin-left: 3.25rem !important;
    margin-right: 3.25rem !important;
  }

  .mx-md-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-md-15 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
  }

  .mx-md-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-md-17 {
    margin-left: 4.25rem !important;
    margin-right: 4.25rem !important;
  }

  .mx-md-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-md-19 {
    margin-left: 4.75rem !important;
    margin-right: 4.75rem !important;
  }

  .mx-md-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-9 {
    margin-top: 2.25rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-11 {
    margin-top: 2.75rem !important;
  }

  .mt-md-12 {
    margin-top: 3rem !important;
  }

  .mt-md-13 {
    margin-top: 3.25rem !important;
  }

  .mt-md-14 {
    margin-top: 3.5rem !important;
  }

  .mt-md-15 {
    margin-top: 3.75rem !important;
  }

  .mt-md-16 {
    margin-top: 4rem !important;
  }

  .mt-md-17 {
    margin-top: 4.25rem !important;
  }

  .mt-md-18 {
    margin-top: 4.5rem !important;
  }

  .mt-md-19 {
    margin-top: 4.75rem !important;
  }

  .mt-md-20 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-9 {
    margin-left: 2.25rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-11 {
    margin-left: 2.75rem !important;
  }

  .ms-md-12 {
    margin-left: 3rem !important;
  }

  .ms-md-13 {
    margin-left: 3.25rem !important;
  }

  .ms-md-14 {
    margin-left: 3.5rem !important;
  }

  .ms-md-15 {
    margin-left: 3.75rem !important;
  }

  .ms-md-16 {
    margin-left: 4rem !important;
  }

  .ms-md-17 {
    margin-left: 4.25rem !important;
  }

  .ms-md-18 {
    margin-left: 4.5rem !important;
  }

  .ms-md-19 {
    margin-left: 4.75rem !important;
  }

  .ms-md-20 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3rem !important;
  }

  .mb-md-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-md-16 {
    margin-bottom: 4rem !important;
  }

  .mb-md-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-md-20 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 0.75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-9 {
    margin-right: 2.25rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-11 {
    margin-right: 2.75rem !important;
  }

  .me-md-12 {
    margin-right: 3rem !important;
  }

  .me-md-13 {
    margin-right: 3.25rem !important;
  }

  .me-md-14 {
    margin-right: 3.5rem !important;
  }

  .me-md-15 {
    margin-right: 3.75rem !important;
  }

  .me-md-16 {
    margin-right: 4rem !important;
  }

  .me-md-17 {
    margin-right: 4.25rem !important;
  }

  .me-md-18 {
    margin-right: 4.5rem !important;
  }

  .me-md-19 {
    margin-right: 4.75rem !important;
  }

  .me-md-20 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n9 {
    margin: -2.25rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .m-md-n11 {
    margin: -2.75rem !important;
  }

  .m-md-n12 {
    margin: -3rem !important;
  }

  .m-md-n13 {
    margin: -3.25rem !important;
  }

  .m-md-n14 {
    margin: -3.5rem !important;
  }

  .m-md-n15 {
    margin: -3.75rem !important;
  }

  .m-md-n16 {
    margin: -4rem !important;
  }

  .m-md-n17 {
    margin: -4.25rem !important;
  }

  .m-md-n18 {
    margin: -4.5rem !important;
  }

  .m-md-n19 {
    margin: -4.75rem !important;
  }

  .m-md-n20 {
    margin: -5rem !important;
  }

  .mx-md-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .mx-md-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-md-n9 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-md-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-md-n11 {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .mx-md-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-md-n13 {
    margin-left: -3.25rem !important;
    margin-right: -3.25rem !important;
  }

  .mx-md-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-md-n15 {
    margin-left: -3.75rem !important;
    margin-right: -3.75rem !important;
  }

  .mx-md-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-md-n17 {
    margin-left: -4.25rem !important;
    margin-right: -4.25rem !important;
  }

  .mx-md-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-md-n19 {
    margin-left: -4.75rem !important;
    margin-right: -4.75rem !important;
  }

  .mx-md-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-md-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-md-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-md-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-md-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-md-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-md-n12 {
    margin-top: -3rem !important;
  }

  .mt-md-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-md-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-md-n16 {
    margin-top: -4rem !important;
  }

  .mt-md-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-md-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-md-n20 {
    margin-top: -5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n8 {
    margin-left: -2rem !important;
  }

  .ms-md-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-md-n12 {
    margin-left: -3rem !important;
  }

  .ms-md-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-md-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-md-n16 {
    margin-left: -4rem !important;
  }

  .ms-md-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-md-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-md-n20 {
    margin-left: -5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -0.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -1.75rem !important;
  }

  .me-md-n8 {
    margin-right: -2rem !important;
  }

  .me-md-n9 {
    margin-right: -2.25rem !important;
  }

  .me-md-n10 {
    margin-right: -2.5rem !important;
  }

  .me-md-n11 {
    margin-right: -2.75rem !important;
  }

  .me-md-n12 {
    margin-right: -3rem !important;
  }

  .me-md-n13 {
    margin-right: -3.25rem !important;
  }

  .me-md-n14 {
    margin-right: -3.5rem !important;
  }

  .me-md-n15 {
    margin-right: -3.75rem !important;
  }

  .me-md-n16 {
    margin-right: -4rem !important;
  }

  .me-md-n17 {
    margin-right: -4.25rem !important;
  }

  .me-md-n18 {
    margin-right: -4.5rem !important;
  }

  .me-md-n19 {
    margin-right: -4.75rem !important;
  }

  .me-md-n20 {
    margin-right: -5rem !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-9 {
    margin: 2.25rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-11 {
    margin: 2.75rem !important;
  }

  .m-lg-12 {
    margin: 3rem !important;
  }

  .m-lg-13 {
    margin: 3.25rem !important;
  }

  .m-lg-14 {
    margin: 3.5rem !important;
  }

  .m-lg-15 {
    margin: 3.75rem !important;
  }

  .m-lg-16 {
    margin: 4rem !important;
  }

  .m-lg-17 {
    margin: 4.25rem !important;
  }

  .m-lg-18 {
    margin: 4.5rem !important;
  }

  .m-lg-19 {
    margin: 4.75rem !important;
  }

  .m-lg-20 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-lg-9 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-lg-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-lg-11 {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .mx-lg-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-13 {
    margin-left: 3.25rem !important;
    margin-right: 3.25rem !important;
  }

  .mx-lg-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-lg-15 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
  }

  .mx-lg-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-lg-17 {
    margin-left: 4.25rem !important;
    margin-right: 4.25rem !important;
  }

  .mx-lg-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-lg-19 {
    margin-left: 4.75rem !important;
    margin-right: 4.75rem !important;
  }

  .mx-lg-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 2.75rem !important;
  }

  .mt-lg-12 {
    margin-top: 3rem !important;
  }

  .mt-lg-13 {
    margin-top: 3.25rem !important;
  }

  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 3.75rem !important;
  }

  .mt-lg-16 {
    margin-top: 4rem !important;
  }

  .mt-lg-17 {
    margin-top: 4.25rem !important;
  }

  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-19 {
    margin-top: 4.75rem !important;
  }

  .mt-lg-20 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-9 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 2.75rem !important;
  }

  .ms-lg-12 {
    margin-left: 3rem !important;
  }

  .ms-lg-13 {
    margin-left: 3.25rem !important;
  }

  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 3.75rem !important;
  }

  .ms-lg-16 {
    margin-left: 4rem !important;
  }

  .ms-lg-17 {
    margin-left: 4.25rem !important;
  }

  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-19 {
    margin-left: 4.75rem !important;
  }

  .ms-lg-20 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 0.75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-9 {
    margin-right: 2.25rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-11 {
    margin-right: 2.75rem !important;
  }

  .me-lg-12 {
    margin-right: 3rem !important;
  }

  .me-lg-13 {
    margin-right: 3.25rem !important;
  }

  .me-lg-14 {
    margin-right: 3.5rem !important;
  }

  .me-lg-15 {
    margin-right: 3.75rem !important;
  }

  .me-lg-16 {
    margin-right: 4rem !important;
  }

  .me-lg-17 {
    margin-right: 4.25rem !important;
  }

  .me-lg-18 {
    margin-right: 4.5rem !important;
  }

  .me-lg-19 {
    margin-right: 4.75rem !important;
  }

  .me-lg-20 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n9 {
    margin: -2.25rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .m-lg-n11 {
    margin: -2.75rem !important;
  }

  .m-lg-n12 {
    margin: -3rem !important;
  }

  .m-lg-n13 {
    margin: -3.25rem !important;
  }

  .m-lg-n14 {
    margin: -3.5rem !important;
  }

  .m-lg-n15 {
    margin: -3.75rem !important;
  }

  .m-lg-n16 {
    margin: -4rem !important;
  }

  .m-lg-n17 {
    margin: -4.25rem !important;
  }

  .m-lg-n18 {
    margin: -4.5rem !important;
  }

  .m-lg-n19 {
    margin: -4.75rem !important;
  }

  .m-lg-n20 {
    margin: -5rem !important;
  }

  .mx-lg-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-lg-n9 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-lg-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-lg-n11 {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .mx-lg-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-lg-n13 {
    margin-left: -3.25rem !important;
    margin-right: -3.25rem !important;
  }

  .mx-lg-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-lg-n15 {
    margin-left: -3.75rem !important;
    margin-right: -3.75rem !important;
  }

  .mx-lg-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-lg-n17 {
    margin-left: -4.25rem !important;
    margin-right: -4.25rem !important;
  }

  .mx-lg-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-lg-n19 {
    margin-left: -4.75rem !important;
    margin-right: -4.75rem !important;
  }

  .mx-lg-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-lg-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-lg-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-lg-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-lg-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-lg-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-lg-n12 {
    margin-top: -3rem !important;
  }

  .mt-lg-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-lg-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-lg-n16 {
    margin-top: -4rem !important;
  }

  .mt-lg-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-lg-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-lg-n20 {
    margin-top: -5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2rem !important;
  }

  .ms-lg-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-lg-n12 {
    margin-left: -3rem !important;
  }

  .ms-lg-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-lg-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-lg-n16 {
    margin-left: -4rem !important;
  }

  .ms-lg-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-lg-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-lg-n20 {
    margin-left: -5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n8 {
    margin-right: -2rem !important;
  }

  .me-lg-n9 {
    margin-right: -2.25rem !important;
  }

  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n11 {
    margin-right: -2.75rem !important;
  }

  .me-lg-n12 {
    margin-right: -3rem !important;
  }

  .me-lg-n13 {
    margin-right: -3.25rem !important;
  }

  .me-lg-n14 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n15 {
    margin-right: -3.75rem !important;
  }

  .me-lg-n16 {
    margin-right: -4rem !important;
  }

  .me-lg-n17 {
    margin-right: -4.25rem !important;
  }

  .me-lg-n18 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n19 {
    margin-right: -4.75rem !important;
  }

  .me-lg-n20 {
    margin-right: -5rem !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-9 {
    margin: 2.25rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-11 {
    margin: 2.75rem !important;
  }

  .m-xl-12 {
    margin: 3rem !important;
  }

  .m-xl-13 {
    margin: 3.25rem !important;
  }

  .m-xl-14 {
    margin: 3.5rem !important;
  }

  .m-xl-15 {
    margin: 3.75rem !important;
  }

  .m-xl-16 {
    margin: 4rem !important;
  }

  .m-xl-17 {
    margin: 4.25rem !important;
  }

  .m-xl-18 {
    margin: 4.5rem !important;
  }

  .m-xl-19 {
    margin: 4.75rem !important;
  }

  .m-xl-20 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xl-9 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-xl-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xl-11 {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .mx-xl-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-13 {
    margin-left: 3.25rem !important;
    margin-right: 3.25rem !important;
  }

  .mx-xl-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xl-15 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
  }

  .mx-xl-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xl-17 {
    margin-left: 4.25rem !important;
    margin-right: 4.25rem !important;
  }

  .mx-xl-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xl-19 {
    margin-left: 4.75rem !important;
    margin-right: 4.75rem !important;
  }

  .mx-xl-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xl-12 {
    margin-top: 3rem !important;
  }

  .mt-xl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xl-16 {
    margin-top: 4rem !important;
  }

  .mt-xl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xl-20 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xl-12 {
    margin-left: 3rem !important;
  }

  .ms-xl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xl-16 {
    margin-left: 4rem !important;
  }

  .ms-xl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xl-20 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xl-12 {
    margin-right: 3rem !important;
  }

  .me-xl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xl-16 {
    margin-right: 4rem !important;
  }

  .me-xl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xl-20 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n9 {
    margin: -2.25rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .m-xl-n11 {
    margin: -2.75rem !important;
  }

  .m-xl-n12 {
    margin: -3rem !important;
  }

  .m-xl-n13 {
    margin: -3.25rem !important;
  }

  .m-xl-n14 {
    margin: -3.5rem !important;
  }

  .m-xl-n15 {
    margin: -3.75rem !important;
  }

  .m-xl-n16 {
    margin: -4rem !important;
  }

  .m-xl-n17 {
    margin: -4.25rem !important;
  }

  .m-xl-n18 {
    margin: -4.5rem !important;
  }

  .m-xl-n19 {
    margin: -4.75rem !important;
  }

  .m-xl-n20 {
    margin: -5rem !important;
  }

  .mx-xl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xl-n9 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-xl-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-xl-n11 {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .mx-xl-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xl-n13 {
    margin-left: -3.25rem !important;
    margin-right: -3.25rem !important;
  }

  .mx-xl-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-xl-n15 {
    margin-left: -3.75rem !important;
    margin-right: -3.75rem !important;
  }

  .mx-xl-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-xl-n17 {
    margin-left: -4.25rem !important;
    margin-right: -4.25rem !important;
  }

  .mx-xl-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-xl-n19 {
    margin-left: -4.75rem !important;
    margin-right: -4.75rem !important;
  }

  .mx-xl-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xl-n20 {
    margin-top: -5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xl-n20 {
    margin-left: -5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n8 {
    margin-right: -2rem !important;
  }

  .me-xl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xl-n12 {
    margin-right: -3rem !important;
  }

  .me-xl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xl-n16 {
    margin-right: -4rem !important;
  }

  .me-xl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xl-n20 {
    margin-right: -5rem !important;
  }
}

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-9 {
    margin: 2.25rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-11 {
    margin: 2.75rem !important;
  }

  .m-xxl-12 {
    margin: 3rem !important;
  }

  .m-xxl-13 {
    margin: 3.25rem !important;
  }

  .m-xxl-14 {
    margin: 3.5rem !important;
  }

  .m-xxl-15 {
    margin: 3.75rem !important;
  }

  .m-xxl-16 {
    margin: 4rem !important;
  }

  .m-xxl-17 {
    margin: 4.25rem !important;
  }

  .m-xxl-18 {
    margin: 4.5rem !important;
  }

  .m-xxl-19 {
    margin: 4.75rem !important;
  }

  .m-xxl-20 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xxl-9 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-xxl-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xxl-11 {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .mx-xxl-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-13 {
    margin-left: 3.25rem !important;
    margin-right: 3.25rem !important;
  }

  .mx-xxl-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xxl-15 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
  }

  .mx-xxl-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xxl-17 {
    margin-left: 4.25rem !important;
    margin-right: 4.25rem !important;
  }

  .mx-xxl-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xxl-19 {
    margin-left: 4.75rem !important;
    margin-right: 4.75rem !important;
  }

  .mx-xxl-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3rem !important;
  }

  .mt-xxl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xxl-16 {
    margin-top: 4rem !important;
  }

  .mt-xxl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xxl-20 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xxl-12 {
    margin-left: 3rem !important;
  }

  .ms-xxl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xxl-16 {
    margin-left: 4rem !important;
  }

  .ms-xxl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xxl-20 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xxl-12 {
    margin-right: 3rem !important;
  }

  .me-xxl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xxl-16 {
    margin-right: 4rem !important;
  }

  .me-xxl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xxl-20 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n9 {
    margin: -2.25rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .m-xxl-n11 {
    margin: -2.75rem !important;
  }

  .m-xxl-n12 {
    margin: -3rem !important;
  }

  .m-xxl-n13 {
    margin: -3.25rem !important;
  }

  .m-xxl-n14 {
    margin: -3.5rem !important;
  }

  .m-xxl-n15 {
    margin: -3.75rem !important;
  }

  .m-xxl-n16 {
    margin: -4rem !important;
  }

  .m-xxl-n17 {
    margin: -4.25rem !important;
  }

  .m-xxl-n18 {
    margin: -4.5rem !important;
  }

  .m-xxl-n19 {
    margin: -4.75rem !important;
  }

  .m-xxl-n20 {
    margin: -5rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xxl-n9 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-xxl-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-xxl-n11 {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .mx-xxl-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xxl-n13 {
    margin-left: -3.25rem !important;
    margin-right: -3.25rem !important;
  }

  .mx-xxl-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-xxl-n15 {
    margin-left: -3.75rem !important;
    margin-right: -3.75rem !important;
  }

  .mx-xxl-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-xxl-n17 {
    margin-left: -4.25rem !important;
    margin-right: -4.25rem !important;
  }

  .mx-xxl-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-xxl-n19 {
    margin-left: -4.75rem !important;
    margin-right: -4.75rem !important;
  }

  .mx-xxl-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xxl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xxl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xxl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xxl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xxl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2rem !important;
  }

  .me-xxl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xxl-n12 {
    margin-right: -3rem !important;
  }

  .me-xxl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xxl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xxl-n16 {
    margin-right: -4rem !important;
  }

  .me-xxl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xxl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xxl-n20 {
    margin-right: -5rem !important;
  }
}

.offset-1 {
  margin-right: 8.33333333%;
}

.offset-2 {
  margin-right: 16.66666667%;
}

.offset-3 {
  margin-right: 25%;
}

.offset-4 {
  margin-right: 33.33333333%;
}

.offset-5 {
  margin-right: 41.66666667%;
}

.offset-6 {
  margin-right: 50%;
}

.offset-7 {
  margin-right: 58.33333333%;
}

.offset-8 {
  margin-right: 66.66666667%;
}

.offset-9 {
  margin-right: 75%;
}

.offset-10 {
  margin-right: 83.33333333%;
}

.offset-11 {
  margin-right: 91.66666667%;
}

@media (min-width: 576px) {
  .offset-sm-0 {
    margin-right: 0;
  }

  .offset-sm-1 {
    margin-right: 8.33333333%;
  }

  .offset-sm-2 {
    margin-right: 16.66666667%;
  }

  .offset-sm-3 {
    margin-right: 25%;
  }

  .offset-sm-4 {
    margin-right: 33.33333333%;
  }

  .offset-sm-5 {
    margin-right: 41.66666667%;
  }

  .offset-sm-6 {
    margin-right: 50%;
  }

  .offset-sm-7 {
    margin-right: 58.33333333%;
  }

  .offset-sm-8 {
    margin-right: 66.66666667%;
  }

  .offset-sm-9 {
    margin-right: 75%;
  }

  .offset-sm-10 {
    margin-right: 83.33333333%;
  }

  .offset-sm-11 {
    margin-right: 91.66666667%;
  }
}

@media (min-width: 768px) {
  .offset-md-0 {
    margin-right: 0;
  }

  .offset-md-1 {
    margin-right: 8.33333333%;
  }

  .offset-md-2 {
    margin-right: 16.66666667%;
  }

  .offset-md-3 {
    margin-right: 25%;
  }

  .offset-md-4 {
    margin-right: 33.33333333%;
  }

  .offset-md-5 {
    margin-right: 41.66666667%;
  }

  .offset-md-6 {
    margin-right: 50%;
  }

  .offset-md-7 {
    margin-right: 58.33333333%;
  }

  .offset-md-8 {
    margin-right: 66.66666667%;
  }

  .offset-md-9 {
    margin-right: 75%;
  }

  .offset-md-10 {
    margin-right: 83.33333333%;
  }

  .offset-md-11 {
    margin-right: 91.66666667%;
  }
}

@media (min-width: 992px) {
  .offset-lg-0 {
    margin-right: 0;
  }

  .offset-lg-1 {
    margin-right: 8.33333333%;
  }

  .offset-lg-2 {
    margin-right: 16.66666667%;
  }

  .offset-lg-3 {
    margin-right: 25%;
  }

  .offset-lg-4 {
    margin-right: 33.33333333%;
  }

  .offset-lg-5 {
    margin-right: 41.66666667%;
  }

  .offset-lg-6 {
    margin-right: 50%;
  }

  .offset-lg-7 {
    margin-right: 58.33333333%;
  }

  .offset-lg-8 {
    margin-right: 66.66666667%;
  }

  .offset-lg-9 {
    margin-right: 75%;
  }

  .offset-lg-10 {
    margin-right: 83.33333333%;
  }

  .offset-lg-11 {
    margin-right: 91.66666667%;
  }
}

@media (min-width: 1200px) {
  .offset-xl-0 {
    margin-right: 0;
  }

  .offset-xl-1 {
    margin-right: 8.33333333%;
  }

  .offset-xl-2 {
    margin-right: 16.66666667%;
  }

  .offset-xl-3 {
    margin-right: 25%;
  }

  .offset-xl-4 {
    margin-right: 33.33333333%;
  }

  .offset-xl-5 {
    margin-right: 41.66666667%;
  }

  .offset-xl-6 {
    margin-right: 50%;
  }

  .offset-xl-7 {
    margin-right: 58.33333333%;
  }

  .offset-xl-8 {
    margin-right: 66.66666667%;
  }

  .offset-xl-9 {
    margin-right: 75%;
  }

  .offset-xl-10 {
    margin-right: 83.33333333%;
  }

  .offset-xl-11 {
    margin-right: 91.66666667%;
  }
}

@media (min-width: 1400px) {
  .offset-xxl-0 {
    margin-right: 0;
  }

  .offset-xxl-1 {
    margin-right: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-right: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-right: 25%;
  }

  .offset-xxl-4 {
    margin-right: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-right: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-right: 50%;
  }

  .offset-xxl-7 {
    margin-right: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-right: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-right: 75%;
  }

  .offset-xxl-10 {
    margin-right: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-right: 91.66666667%;
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-0-hover:hover {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-5-hover:hover {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-10-hover:hover {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-15-hover:hover {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-20-hover:hover {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-25-hover:hover {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-50-hover:hover {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-75-hover:hover {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-100-hover:hover {
  opacity: 1 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-9 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-11 {
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
}

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-13 {
  padding-left: 3.25rem !important;
  padding-right: 3.25rem !important;
}

.px-14 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.px-15 {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
}

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-17 {
  padding-left: 4.25rem !important;
  padding-right: 4.25rem !important;
}

.px-18 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.px-19 {
  padding-left: 4.75rem !important;
  padding-right: 4.75rem !important;
}

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-13 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-15 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-17 {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-19 {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-13 {
  padding-top: 3.25rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-17 {
  padding-top: 4.25rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-19 {
  padding-top: 4.75rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-11 {
  padding-left: 2.75rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-13 {
  padding-left: 3.25rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-15 {
  padding-left: 3.75rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-17 {
  padding-left: 4.25rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-19 {
  padding-left: 4.75rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-13 {
  padding-bottom: 3.25rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-15 {
  padding-bottom: 3.75rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-17 {
  padding-bottom: 4.25rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-19 {
  padding-bottom: 4.75rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-11 {
  padding-right: 2.75rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-13 {
  padding-right: 3.25rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-15 {
  padding-right: 3.75rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-17 {
  padding-right: 4.25rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-19 {
  padding-right: 4.75rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-9 {
    padding: 2.25rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-11 {
    padding: 2.75rem !important;
  }

  .p-sm-12 {
    padding: 3rem !important;
  }

  .p-sm-13 {
    padding: 3.25rem !important;
  }

  .p-sm-14 {
    padding: 3.5rem !important;
  }

  .p-sm-15 {
    padding: 3.75rem !important;
  }

  .p-sm-16 {
    padding: 4rem !important;
  }

  .p-sm-17 {
    padding: 4.25rem !important;
  }

  .p-sm-18 {
    padding: 4.5rem !important;
  }

  .p-sm-19 {
    padding: 4.75rem !important;
  }

  .p-sm-20 {
    padding: 5rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-sm-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .px-sm-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-sm-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-sm-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-sm-9 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-sm-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-sm-11 {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .px-sm-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-sm-13 {
    padding-left: 3.25rem !important;
    padding-right: 3.25rem !important;
  }

  .px-sm-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-sm-15 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }

  .px-sm-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-sm-17 {
    padding-left: 4.25rem !important;
    padding-right: 4.25rem !important;
  }

  .px-sm-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-sm-19 {
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
  }

  .px-sm-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 2.75rem !important;
  }

  .pt-sm-12 {
    padding-top: 3rem !important;
  }

  .pt-sm-13 {
    padding-top: 3.25rem !important;
  }

  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 3.75rem !important;
  }

  .pt-sm-16 {
    padding-top: 4rem !important;
  }

  .pt-sm-17 {
    padding-top: 4.25rem !important;
  }

  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-19 {
    padding-top: 4.75rem !important;
  }

  .pt-sm-20 {
    padding-top: 5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-9 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 2.75rem !important;
  }

  .ps-sm-12 {
    padding-left: 3rem !important;
  }

  .ps-sm-13 {
    padding-left: 3.25rem !important;
  }

  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 3.75rem !important;
  }

  .ps-sm-16 {
    padding-left: 4rem !important;
  }

  .ps-sm-17 {
    padding-left: 4.25rem !important;
  }

  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-19 {
    padding-left: 4.75rem !important;
  }

  .ps-sm-20 {
    padding-left: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-9 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 2.75rem !important;
  }

  .pe-sm-12 {
    padding-right: 3rem !important;
  }

  .pe-sm-13 {
    padding-right: 3.25rem !important;
  }

  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 3.75rem !important;
  }

  .pe-sm-16 {
    padding-right: 4rem !important;
  }

  .pe-sm-17 {
    padding-right: 4.25rem !important;
  }

  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-19 {
    padding-right: 4.75rem !important;
  }

  .pe-sm-20 {
    padding-right: 5rem !important;
  }
}

@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-9 {
    padding: 2.25rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-11 {
    padding: 2.75rem !important;
  }

  .p-md-12 {
    padding: 3rem !important;
  }

  .p-md-13 {
    padding: 3.25rem !important;
  }

  .p-md-14 {
    padding: 3.5rem !important;
  }

  .p-md-15 {
    padding: 3.75rem !important;
  }

  .p-md-16 {
    padding: 4rem !important;
  }

  .p-md-17 {
    padding: 4.25rem !important;
  }

  .p-md-18 {
    padding: 4.5rem !important;
  }

  .p-md-19 {
    padding: 4.75rem !important;
  }

  .p-md-20 {
    padding: 5rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-md-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .px-md-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-md-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-md-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-md-9 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-md-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-md-11 {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .px-md-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-md-13 {
    padding-left: 3.25rem !important;
    padding-right: 3.25rem !important;
  }

  .px-md-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-md-15 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }

  .px-md-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-md-17 {
    padding-left: 4.25rem !important;
    padding-right: 4.25rem !important;
  }

  .px-md-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-md-19 {
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
  }

  .px-md-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-9 {
    padding-top: 2.25rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-11 {
    padding-top: 2.75rem !important;
  }

  .pt-md-12 {
    padding-top: 3rem !important;
  }

  .pt-md-13 {
    padding-top: 3.25rem !important;
  }

  .pt-md-14 {
    padding-top: 3.5rem !important;
  }

  .pt-md-15 {
    padding-top: 3.75rem !important;
  }

  .pt-md-16 {
    padding-top: 4rem !important;
  }

  .pt-md-17 {
    padding-top: 4.25rem !important;
  }

  .pt-md-18 {
    padding-top: 4.5rem !important;
  }

  .pt-md-19 {
    padding-top: 4.75rem !important;
  }

  .pt-md-20 {
    padding-top: 5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 0.75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-9 {
    padding-left: 2.25rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .ps-md-11 {
    padding-left: 2.75rem !important;
  }

  .ps-md-12 {
    padding-left: 3rem !important;
  }

  .ps-md-13 {
    padding-left: 3.25rem !important;
  }

  .ps-md-14 {
    padding-left: 3.5rem !important;
  }

  .ps-md-15 {
    padding-left: 3.75rem !important;
  }

  .ps-md-16 {
    padding-left: 4rem !important;
  }

  .ps-md-17 {
    padding-left: 4.25rem !important;
  }

  .ps-md-18 {
    padding-left: 4.5rem !important;
  }

  .ps-md-19 {
    padding-left: 4.75rem !important;
  }

  .ps-md-20 {
    padding-left: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3rem !important;
  }

  .pb-md-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-md-16 {
    padding-bottom: 4rem !important;
  }

  .pb-md-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-md-20 {
    padding-bottom: 5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 0.75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-9 {
    padding-right: 2.25rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pe-md-11 {
    padding-right: 2.75rem !important;
  }

  .pe-md-12 {
    padding-right: 3rem !important;
  }

  .pe-md-13 {
    padding-right: 3.25rem !important;
  }

  .pe-md-14 {
    padding-right: 3.5rem !important;
  }

  .pe-md-15 {
    padding-right: 3.75rem !important;
  }

  .pe-md-16 {
    padding-right: 4rem !important;
  }

  .pe-md-17 {
    padding-right: 4.25rem !important;
  }

  .pe-md-18 {
    padding-right: 4.5rem !important;
  }

  .pe-md-19 {
    padding-right: 4.75rem !important;
  }

  .pe-md-20 {
    padding-right: 5rem !important;
  }
}

@media (min-width: 992px) {
  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-9 {
    padding: 2.25rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-11 {
    padding: 2.75rem !important;
  }

  .p-lg-12 {
    padding: 3rem !important;
  }

  .p-lg-13 {
    padding: 3.25rem !important;
  }

  .p-lg-14 {
    padding: 3.5rem !important;
  }

  .p-lg-15 {
    padding: 3.75rem !important;
  }

  .p-lg-16 {
    padding: 4rem !important;
  }

  .p-lg-17 {
    padding: 4.25rem !important;
  }

  .p-lg-18 {
    padding: 4.5rem !important;
  }

  .p-lg-19 {
    padding: 4.75rem !important;
  }

  .p-lg-20 {
    padding: 5rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-lg-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .px-lg-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-lg-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-lg-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-lg-9 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-lg-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-lg-11 {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .px-lg-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-lg-13 {
    padding-left: 3.25rem !important;
    padding-right: 3.25rem !important;
  }

  .px-lg-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-lg-15 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }

  .px-lg-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-lg-17 {
    padding-left: 4.25rem !important;
    padding-right: 4.25rem !important;
  }

  .px-lg-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-lg-19 {
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
  }

  .px-lg-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 2.75rem !important;
  }

  .pt-lg-12 {
    padding-top: 3rem !important;
  }

  .pt-lg-13 {
    padding-top: 3.25rem !important;
  }

  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 3.75rem !important;
  }

  .pt-lg-16 {
    padding-top: 4rem !important;
  }

  .pt-lg-17 {
    padding-top: 4.25rem !important;
  }

  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-19 {
    padding-top: 4.75rem !important;
  }

  .pt-lg-20 {
    padding-top: 5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-9 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 2.75rem !important;
  }

  .ps-lg-12 {
    padding-left: 3rem !important;
  }

  .ps-lg-13 {
    padding-left: 3.25rem !important;
  }

  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 3.75rem !important;
  }

  .ps-lg-16 {
    padding-left: 4rem !important;
  }

  .ps-lg-17 {
    padding-left: 4.25rem !important;
  }

  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-19 {
    padding-left: 4.75rem !important;
  }

  .ps-lg-20 {
    padding-left: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-9 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 2.75rem !important;
  }

  .pe-lg-12 {
    padding-right: 3rem !important;
  }

  .pe-lg-13 {
    padding-right: 3.25rem !important;
  }

  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 3.75rem !important;
  }

  .pe-lg-16 {
    padding-right: 4rem !important;
  }

  .pe-lg-17 {
    padding-right: 4.25rem !important;
  }

  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-19 {
    padding-right: 4.75rem !important;
  }

  .pe-lg-20 {
    padding-right: 5rem !important;
  }
}

@media (min-width: 1200px) {
  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-9 {
    padding: 2.25rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-11 {
    padding: 2.75rem !important;
  }

  .p-xl-12 {
    padding: 3rem !important;
  }

  .p-xl-13 {
    padding: 3.25rem !important;
  }

  .p-xl-14 {
    padding: 3.5rem !important;
  }

  .p-xl-15 {
    padding: 3.75rem !important;
  }

  .p-xl-16 {
    padding: 4rem !important;
  }

  .p-xl-17 {
    padding: 4.25rem !important;
  }

  .p-xl-18 {
    padding: 4.5rem !important;
  }

  .p-xl-19 {
    padding: 4.75rem !important;
  }

  .p-xl-20 {
    padding: 5rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-xl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .px-xl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-xl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-xl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xl-9 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-xl-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xl-11 {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .px-xl-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xl-13 {
    padding-left: 3.25rem !important;
    padding-right: 3.25rem !important;
  }

  .px-xl-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-xl-15 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }

  .px-xl-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xl-17 {
    padding-left: 4.25rem !important;
    padding-right: 4.25rem !important;
  }

  .px-xl-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-xl-19 {
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
  }

  .px-xl-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xl-12 {
    padding-top: 3rem !important;
  }

  .pt-xl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xl-16 {
    padding-top: 4rem !important;
  }

  .pt-xl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xl-20 {
    padding-top: 5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xl-12 {
    padding-left: 3rem !important;
  }

  .ps-xl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xl-16 {
    padding-left: 4rem !important;
  }

  .ps-xl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xl-20 {
    padding-left: 5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xl-12 {
    padding-right: 3rem !important;
  }

  .pe-xl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xl-16 {
    padding-right: 4rem !important;
  }

  .pe-xl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xl-20 {
    padding-right: 5rem !important;
  }
}

@media (min-width: 1400px) {
  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-9 {
    padding: 2.25rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-11 {
    padding: 2.75rem !important;
  }

  .p-xxl-12 {
    padding: 3rem !important;
  }

  .p-xxl-13 {
    padding: 3.25rem !important;
  }

  .p-xxl-14 {
    padding: 3.5rem !important;
  }

  .p-xxl-15 {
    padding: 3.75rem !important;
  }

  .p-xxl-16 {
    padding: 4rem !important;
  }

  .p-xxl-17 {
    padding: 4.25rem !important;
  }

  .p-xxl-18 {
    padding: 4.5rem !important;
  }

  .p-xxl-19 {
    padding: 4.75rem !important;
  }

  .p-xxl-20 {
    padding: 5rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xxl-9 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-xxl-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xxl-11 {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .px-xxl-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xxl-13 {
    padding-left: 3.25rem !important;
    padding-right: 3.25rem !important;
  }

  .px-xxl-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-xxl-15 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }

  .px-xxl-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xxl-17 {
    padding-left: 4.25rem !important;
    padding-right: 4.25rem !important;
  }

  .px-xxl-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-xxl-19 {
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
  }

  .px-xxl-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3rem !important;
  }

  .pt-xxl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xxl-16 {
    padding-top: 4rem !important;
  }

  .pt-xxl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xxl-20 {
    padding-top: 5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xxl-12 {
    padding-left: 3rem !important;
  }

  .ps-xxl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xxl-16 {
    padding-left: 4rem !important;
  }

  .ps-xxl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xxl-20 {
    padding-left: 5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xxl-12 {
    padding-right: 3rem !important;
  }

  .pe-xxl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xxl-16 {
    padding-right: 4rem !important;
  }

  .pe-xxl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xxl-20 {
    padding-right: 5rem !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.start-100 {
  right: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-n2 {
  z-index: -2 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

@media (min-width: 1400px) {
  .position-xxl-static {
    position: static !important;
  }

  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-absolute {
    position: absolute !important;
  }

  .position-xxl-fixed {
    position: fixed !important;
  }

  .position-xxl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-1px {
  width: 1px !important;
}

.w-2px {
  width: 2px !important;
}

.w-3px {
  width: 3px !important;
}

.w-4px {
  width: 4px !important;
}

.w-5px {
  width: 5px !important;
}

.w-6px {
  width: 6px !important;
}

.w-7px {
  width: 7px !important;
}

.w-8px {
  width: 8px !important;
}

.w-9px {
  width: 9px !important;
}

.w-10px {
  width: 10px !important;
}

.w-15px {
  width: 15px !important;
}

.w-20px {
  width: 20px !important;
}

.w-25px {
  width: 25px !important;
}

.w-30px {
  width: 30px !important;
}

.w-35px {
  width: 35px !important;
}

.w-40px {
  width: 40px !important;
}

.w-45px {
  width: 45px !important;
}

.w-50px {
  width: 50px !important;
}

.w-55px {
  width: 55px !important;
}

.w-60px {
  width: 60px !important;
}

.w-65px {
  width: 65px !important;
}

.w-70px {
  width: 70px !important;
}

.w-75px {
  width: 75px !important;
}

.w-80px {
  width: 80px !important;
}

.w-85px {
  width: 85px !important;
}

.w-90px {
  width: 90px !important;
}

.w-95px {
  width: 95px !important;
}

.w-100px {
  width: 100px !important;
}

.w-125px {
  width: 125px !important;
}

.w-150px {
  width: 150px !important;
}

.w-160px {
  width: 160px !important;
}

.w-175px {
  width: 175px !important;
}

.w-200px {
  width: 200px !important;
}

.w-225px {
  width: 225px !important;
}

.w-250px {
  width: 250px !important;
}

.w-275px {
  width: 275px !important;
}

.w-300px {
  width: 300px !important;
}

.w-325px {
  width: 325px !important;
}

.w-350px {
  width: 350px !important;
}

.w-375px {
  width: 375px !important;
}

.w-400px {
  width: 400px !important;
}

.w-425px {
  width: 425px !important;
}

.w-450px {
  width: 450px !important;
}

.w-475px {
  width: 475px !important;
}

.w-500px {
  width: 500px !important;
}

.w-550px {
  width: 550px !important;
}

.w-600px {
  width: 600px !important;
}

.w-650px {
  width: 650px !important;
}

.w-700px {
  width: 700px !important;
}

.w-750px {
  width: 750px !important;
}

.w-800px {
  width: 800px !important;
}

.w-850px {
  width: 850px !important;
}

.w-900px {
  width: 900px !important;
}

.w-950px {
  width: 950px !important;
}

.w-1000px {
  width: 1000px !important;
}

.mw-unset {
  max-width: unset !important;
}

.mw-25 {
  max-width: 25% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-auto {
  max-width: auto !important;
}

.mw-1px {
  max-width: 1px !important;
}

.mw-2px {
  max-width: 2px !important;
}

.mw-3px {
  max-width: 3px !important;
}

.mw-4px {
  max-width: 4px !important;
}

.mw-5px {
  max-width: 5px !important;
}

.mw-6px {
  max-width: 6px !important;
}

.mw-7px {
  max-width: 7px !important;
}

.mw-8px {
  max-width: 8px !important;
}

.mw-9px {
  max-width: 9px !important;
}

.mw-10px {
  max-width: 10px !important;
}

.mw-15px {
  max-width: 15px !important;
}

.mw-20px {
  max-width: 20px !important;
}

.mw-25px {
  max-width: 25px !important;
}

.mw-30px {
  max-width: 30px !important;
}

.mw-35px {
  max-width: 35px !important;
}

.mw-40px {
  max-width: 40px !important;
}

.mw-45px {
  max-width: 45px !important;
}

.mw-50px {
  max-width: 50px !important;
}

.mw-55px {
  max-width: 55px !important;
}

.mw-60px {
  max-width: 60px !important;
}

.mw-65px {
  max-width: 65px !important;
}

.mw-70px {
  max-width: 70px !important;
}

.mw-75px {
  max-width: 75px !important;
}

.mw-80px {
  max-width: 80px !important;
}

.mw-85px {
  max-width: 85px !important;
}

.mw-90px {
  max-width: 90px !important;
}

.mw-95px {
  max-width: 95px !important;
}

.mw-100px {
  max-width: 100px !important;
}

.mw-125px {
  max-width: 125px !important;
}

.mw-150px {
  max-width: 150px !important;
}

.mw-175px {
  max-width: 175px !important;
}

.mw-200px {
  max-width: 200px !important;
}

.mw-210px {
  max-width: 210px !important;
}

.mw-225px {
  max-width: 225px !important;
}

.mw-250px {
  max-width: 250px !important;
}

.mw-275px {
  max-width: 275px !important;
}

.mw-300px {
  max-width: 300px !important;
}

.mw-310px {
  max-width: 310px !important;
}

.mw-325px {
  max-width: 325px !important;
}

.mw-350px {
  max-width: 350px !important;
}

.mw-375px {
  max-width: 375px !important;
}

.mw-400px {
  max-width: 400px !important;
}

.mw-425px {
  max-width: 425px !important;
}

.mw-450px {
  max-width: 450px !important;
}

.mw-475px {
  max-width: 475px !important;
}

.mw-500px {
  max-width: 500px !important;
}

.mw-550px {
  max-width: 550px !important;
}

.mw-575px {
  max-width: 575px !important;
}

.mw-600px {
  max-width: 600px !important;
}

.mw-650px {
  max-width: 650px !important;
}

.mw-700px {
  max-width: 700px !important;
}

.mw-750px {
  max-width: 750px !important;
}

.mw-800px {
  max-width: 800px !important;
}

.mw-850px {
  max-width: 850px !important;
}

.mw-900px {
  max-width: 900px !important;
}

.mw-950px {
  max-width: 950px !important;
}

.mw-1000px {
  max-width: 1000px !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-w-25 {
  min-width: 25% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-w-75 {
  min-width: 75% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-auto {
  min-width: auto !important;
}

.min-w-1px {
  min-width: 1px !important;
}

.min-w-2px {
  min-width: 2px !important;
}

.min-w-3px {
  min-width: 3px !important;
}

.min-w-4px {
  min-width: 4px !important;
}

.min-w-5px {
  min-width: 5px !important;
}

.min-w-6px {
  min-width: 6px !important;
}

.min-w-7px {
  min-width: 7px !important;
}

.min-w-8px {
  min-width: 8px !important;
}

.min-w-9px {
  min-width: 9px !important;
}

.min-w-10px {
  min-width: 10px !important;
}

.min-w-15px {
  min-width: 15px !important;
}

.min-w-20px {
  min-width: 20px !important;
}

.min-w-25px {
  min-width: 25px !important;
}

.min-w-30px {
  min-width: 30px !important;
}

.min-w-35px {
  min-width: 35px !important;
}

.min-w-40px {
  min-width: 40px !important;
}

.min-w-45px {
  min-width: 45px !important;
}

.min-w-50px {
  min-width: 50px !important;
}

.min-w-55px {
  min-width: 55px !important;
}

.min-w-60px {
  min-width: 60px !important;
}

.min-w-65px {
  min-width: 65px !important;
}

.min-w-70px {
  min-width: 70px !important;
}

.min-w-75px {
  min-width: 75px !important;
}

.min-w-80px {
  min-width: 80px !important;
}

.min-w-85px {
  min-width: 85px !important;
}

.min-w-90px {
  min-width: 90px !important;
}

.min-w-95px {
  min-width: 95px !important;
}

.min-w-100px {
  min-width: 100px !important;
}

.min-w-130px {
  min-width: 130px !important;
}

.min-w-150px {
  min-width: 150px !important;
}

.min-w-175px {
  min-width: 175px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.min-w-225px {
  min-width: 225px !important;
}

.min-w-230px {
  min-width: 230px !important;
}

.min-w-250px {
  min-width: 250px !important;
}

.min-w-275px {
  min-width: 275px !important;
}

.min-w-290px {
  min-width: 290px !important;
}

.min-w-300px {
  min-width: 300px !important;
}

.min-w-325px {
  min-width: 325px !important;
}

.min-w-350px {
  min-width: 350px !important;
}

.min-w-375px {
  min-width: 375px !important;
}

.min-w-400px {
  min-width: 400px !important;
}

.min-w-425px {
  min-width: 425px !important;
}

.min-w-450px {
  min-width: 450px !important;
}

.min-w-475px {
  min-width: 475px !important;
}

.min-w-500px {
  min-width: 500px !important;
}

.min-w-550px {
  min-width: 550px !important;
}

.min-w-600px {
  min-width: 600px !important;
}

.min-w-650px {
  min-width: 650px !important;
}

.min-w-700px {
  min-width: 700px !important;
}

.min-w-750px {
  min-width: 750px !important;
}

.min-w-800px {
  min-width: 800px !important;
}

.min-w-850px {
  min-width: 850px !important;
}

.min-w-900px {
  min-width: 900px !important;
}

.min-w-950px {
  min-width: 950px !important;
}

.min-w-1000px {
  min-width: 1000px !important;
}

.h-3px {
  height: 3px !important;
}

.h-auto {
  height: auto;
}

.h-2px {
  height: 2px !important;
}

.h-4px {
  height: 4px !important;
}

.h-30px {
  height: 30px !important;
}

.h-40px {
  height: 40px !important;
}

.h-200px {
  height: 200px !important;
}

.h-100 {
  height: 100%;
}

.min-h-25 {
  min-height: 25% !important;
}

.min-h-50 {
  min-height: 50% !important;
}

.min-h-75 {
  min-height: 75% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.min-h-auto {
  min-height: auto !important;
}

.min-h-1px {
  min-height: 1px !important;
}

.min-h-2px {
  min-height: 2px !important;
}

.min-h-3px {
  min-height: 3px !important;
}

.min-h-4px {
  min-height: 4px !important;
}

.min-h-5px {
  min-height: 5px !important;
}

.min-h-6px {
  min-height: 6px !important;
}

.min-h-7px {
  min-height: 7px !important;
}

.min-h-8px {
  min-height: 8px !important;
}

.min-h-9px {
  min-height: 9px !important;
}

.min-h-10px {
  min-height: 10px !important;
}

.min-h-15px {
  min-height: 15px !important;
}

.min-h-20px {
  min-height: 20px !important;
}

.min-h-25px {
  min-height: 25px !important;
}

.min-h-30px {
  min-height: 30px !important;
}

.min-h-35px {
  min-height: 35px !important;
}

.min-h-40px {
  min-height: 40px !important;
}

.min-h-45px {
  min-height: 45px !important;
}

.min-h-50px {
  min-height: 50px !important;
}

.min-h-55px {
  min-height: 55px !important;
}

.min-h-60px {
  min-height: 60px !important;
}

.min-h-65px {
  min-height: 65px !important;
}

.min-h-70px {
  min-height: 70px !important;
}

.min-h-75px {
  min-height: 75px !important;
}

.min-h-80px {
  min-height: 80px !important;
}

.min-h-85px {
  min-height: 85px !important;
}

.min-h-90px {
  min-height: 90px !important;
}

.min-h-95px {
  min-height: 95px !important;
}

.min-h-100px {
  min-height: 100px !important;
}

.min-h-125px {
  min-height: 125px !important;
}

.min-h-150px {
  min-height: 150px !important;
}

.min-h-175px {
  min-height: 175px !important;
}

.min-h-200px {
  min-height: 200px !important;
}

.min-h-225px {
  min-height: 225px !important;
}

.min-h-250px {
  min-height: 250px !important;
}

.min-h-275px {
  min-height: 275px !important;
}

.min-h-300px {
  min-height: 300px !important;
}

.min-h-325px {
  min-height: 325px !important;
}

.min-h-350px {
  min-height: 350px !important;
}

.min-h-365px {
  min-height: 365px !important;
}

.min-h-375px {
  min-height: 375px !important;
}

.min-h-400px {
  min-height: 400px !important;
}

.min-h-425px {
  min-height: 425px !important;
}

.min-h-450px {
  min-height: 450px !important;
}

.min-h-475px {
  min-height: 475px !important;
}

.min-h-500px {
  min-height: 500px !important;
}

.min-h-550px {
  min-height: 550px !important;
}

.min-h-600px {
  min-height: 600px !important;
}

.min-h-650px {
  min-height: 650px !important;
}

.min-h-700px {
  min-height: 700px !important;
}

.min-h-750px {
  min-height: 750px !important;
}

.min-h-800px {
  min-height: 800px !important;
}

.min-h-850px {
  min-height: 850px !important;
}

.min-h-900px {
  min-height: 900px !important;
}

.min-h-950px {
  min-height: 950px !important;
}

.min-h-1000px {
  min-height: 1000px !important;
}

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-65px {
    width: 65px !important;
  }

  .w-sm-140px {
    width: 140px !important;
  }

  .w-sm-150px {
    width: 150px !important;
  }

  .w-sm-180px {
    width: 180px !important;
  }

  .mw-sm-100px {
    max-width: 100px !important;
  }

  .mw-sm-275px {
    max-width: 275px !important;
  }

  .mw-sm-310px {
    max-width: 310px !important;
  }

  .mw-sm-250px {
    max-width: 250px !important;
  }

  .mw-sm-200px {
    max-width: 200px !important;
  }

  .mw-sm-175px {
    max-width: 175px !important;
  }

  .mw-sm-125px {
    max-width: 125px;
  }

  .min-w-sm-130px {
    min-width: 130px !important;
  }

  .min-sm-h-365px {
    min-height: 365px !important;
  }

  .h-sm-auto {
    height: auto;
  }
}

@media (min-width: 768px) {
  .w-md-50px {
    width: 50px !important;
  }

  .w-md-65px {
    width: 65px !important;
  }

  .mw-md-275px {
    max-width: 275px !important;
  }

  .mw-md-350px {
    max-width: 350px !important;
  }

  .mw-md-450px {
    max-width: 450px !important;
  }

  .min-w-md-300px {
    min-width: 300px !important;
  }

  .min-w-md-290px {
    min-width: 290px !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .mw-md-100 {
    max-width: 100% !important;
  }

  .min-h-md-350px {
    min-height: 350px !important;
  }
}

@media (min-width: 992px) {
  .w-lg-65px {
    width: 65px !important;
  }

  .mw-lg-120px {
    max-width: 120px !important;
  }

  .mw-lg-175px {
    max-width: 175px !important;
  }

  .mw-lg-310px {
    max-width: 310px !important;
  }

  .mw-lg-325px {
    max-width: 325px !important;
  }

  .mw-lg-450px {
    max-width: 450px !important;
  }

  .mw-lg-500px {
    max-width: 500px !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .min-w-lg-80px {
    min-width: 80px !important;
  }
}

.fs-1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-3 {
  font-size: calc(1.26rem + 0.12vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.15rem !important;
}

.fs-6 {
  font-size: 1.075rem !important;
}

.fs-7 {
  font-size: 0.95rem !important;
}

.fs-8 {
  font-size: 0.85rem !important;
}

.fs-9 {
  font-size: 0.75rem !important;
}

.fs-10 {
  font-size: 0.5rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-fluid {
  font-size: 100% !important;
}

.fs-2x {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-2qx {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2hx {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2tx {
  font-size: calc(1.4rem + 1.8vw) !important;
}

.fs-3x {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3qx {
  font-size: calc(1.45rem + 2.4vw) !important;
}

.fs-3hx {
  font-size: calc(1.475rem + 2.7vw) !important;
}

.fs-3tx {
  font-size: calc(1.5rem + 3vw) !important;
}

.fs-4x {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-4qx {
  font-size: calc(1.55rem + 3.6vw) !important;
}

.fs-4hx {
  font-size: calc(1.575rem + 3.9vw) !important;
}

.fs-4tx {
  font-size: calc(1.6rem + 4.2vw) !important;
}

.fs-5x {
  font-size: calc(1.625rem + 4.5vw) !important;
}

.fs-5qx {
  font-size: calc(1.65rem + 4.8vw) !important;
}

.fs-5hx {
  font-size: calc(1.675rem + 5.1vw) !important;
}

.fs-5tx {
  font-size: calc(1.7rem + 5.4vw) !important;
}

.fs-30px {
  font-size: 30px;
}

.fs-18px {
  font-size: 18px !important;
}

.fs-24px {
  font-size: 24px !important;
}

.fs-36px {
  font-size: 36px !important;
}

@media (min-width: 576px) {
  .fs-sm-18px {
    font-size: 18px !important;
  }

  .fs-sm-24px {
    font-size: 24px !important;
  }

  .fs-sm-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-sm-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-sm-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-sm-4 {
    font-size: 1.25rem !important;
  }

  .fs-sm-5 {
    font-size: 1.15rem !important;
  }

  .fs-sm-6 {
    font-size: 1.075rem !important;
  }

  .fs-sm-7 {
    font-size: 0.95rem !important;
  }

  .fs-sm-8 {
    font-size: 0.85rem !important;
  }

  .fs-sm-9 {
    font-size: 0.75rem !important;
  }

  .fs-sm-10 {
    font-size: 0.5rem !important;
  }

  .fs-sm-base {
    font-size: 1rem !important;
  }

  .fs-sm-fluid {
    font-size: 100% !important;
  }

  .fs-sm-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-sm-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-sm-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-sm-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-sm-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-sm-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-sm-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-sm-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-sm-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-sm-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-sm-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-sm-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-sm-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-sm-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-sm-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-sm-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-sm-18px {
    font-size: 18px !important;
  }
}

@media (min-width: 768px) {
  .fs-md-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-md-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-md-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-md-4 {
    font-size: 1.25rem !important;
  }

  .fs-md-5 {
    font-size: 1.15rem !important;
  }

  .fs-md-6 {
    font-size: 1.075rem !important;
  }

  .fs-md-7 {
    font-size: 0.95rem !important;
  }

  .fs-md-8 {
    font-size: 0.85rem !important;
  }

  .fs-md-9 {
    font-size: 0.75rem !important;
  }

  .fs-md-10 {
    font-size: 0.5rem !important;
  }

  .fs-md-base {
    font-size: 1rem !important;
  }

  .fs-md-fluid {
    font-size: 100% !important;
  }

  .fs-md-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-md-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-md-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-md-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-md-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-md-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-md-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-md-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-md-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-md-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-md-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-md-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-md-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-md-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-md-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-md-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }
}

@media (min-width: 992px) {
  .fs-lg-18px {
    font-size: 18px !important;
  }

  .mw-lg-33 {
    max-width: calc(100% / 3 - 6.66666px);
  }

  .fs-lg-24px {
    font-size: 24px !important;
  }

  .fs-lg-36px {
    font-size: 36px !important;
  }

  .fs-lg-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-lg-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-lg-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-lg-4 {
    font-size: 1.25rem !important;
  }

  .fs-lg-5 {
    font-size: 1.15rem !important;
  }

  .fs-lg-6 {
    font-size: 1.075rem !important;
  }

  .fs-lg-7 {
    font-size: 0.95rem !important;
  }

  .fs-lg-8 {
    font-size: 0.85rem !important;
  }

  .fs-lg-9 {
    font-size: 0.75rem !important;
  }

  .fs-lg-10 {
    font-size: 0.5rem !important;
  }

  .fs-lg-base {
    font-size: 1rem !important;
  }

  .fs-lg-fluid {
    font-size: 100% !important;
  }

  .fs-lg-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-lg-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-lg-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-lg-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-lg-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-lg-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-lg-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-lg-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-lg-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-lg-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-lg-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-lg-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-lg-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-lg-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-lg-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-lg-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }
}

@media (min-width: 1200px) {
  .fs-xl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-xl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-xl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-xl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xl-5 {
    font-size: 1.15rem !important;
  }

  .fs-xl-6 {
    font-size: 1.075rem !important;
  }

  .fs-xl-7 {
    font-size: 0.95rem !important;
  }

  .fs-xl-8 {
    font-size: 0.85rem !important;
  }

  .fs-xl-9 {
    font-size: 0.75rem !important;
  }

  .fs-xl-10 {
    font-size: 0.5rem !important;
  }

  .fs-xl-base {
    font-size: 1rem !important;
  }

  .fs-xl-fluid {
    font-size: 100% !important;
  }

  .fs-xl-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-xl-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-xl-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xl-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-xl-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-xl-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-xl-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-xl-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-xl-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-xl-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-xl-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-xl-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-xl-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-xl-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-xl-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-xl-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }
}

@media (min-width: 1400px) {
  .fs-xxl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-xxl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-xxl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-xxl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xxl-5 {
    font-size: 1.15rem !important;
  }

  .fs-xxl-6 {
    font-size: 1.075rem !important;
  }

  .fs-xxl-7 {
    font-size: 0.95rem !important;
  }

  .fs-xxl-8 {
    font-size: 0.85rem !important;
  }

  .fs-xxl-9 {
    font-size: 0.75rem !important;
  }

  .fs-xxl-10 {
    font-size: 0.5rem !important;
  }

  .fs-xxl-base {
    font-size: 1rem !important;
  }

  .fs-xxl-fluid {
    font-size: 100% !important;
  }

  .fs-xxl-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-xxl-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-xxl-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xxl-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-xxl-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-xxl-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-xxl-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-xxl-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-xxl-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-xxl-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-xxl-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-xxl-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-xxl-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-xxl-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-xxl-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-xxl-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }
}

.text-end {
  text-align: right !important;
}

.text-start {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-white {
  color: white !important;
}

.text-black {
  color: black !important;
}

.text-dimgray {
  color: #727272 !important;
}

.text-dimgray1 {
  color: #6c6c6c !important;
}

.text-dimgray2 {
  color: #6f6f6f !important;
}

.text-arapawa {
  color: #2b4764 !important;
}

.text-persianRed {
  color: #c13838 !important;
}

.text-matterhorn {
  color: #535353 !important;
}

.text-nightRider {
  color: #2f2f2f !important;
}

.text-whisper {
  color: #eeeeee !important;
}

.text-charcoal {
  color: #434343 !important;
}

.text-darkGray {
  color: #b1b1b1 !important;
}

.text-eclipse {
  color: #363636 !important;
}

.text-echoBlue {
  color: #9fb5cb !important;
}

.text-summerSky {
  color: #30b9e4 !important;
}

.text-mayaBlue {
  color: #6dbdf8 !important;
}

.text-steelBlue {
  color: #507dac !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-hover-danube:hover {
  color: #6592c1 !important;
}

.text-hover-white:hover {
  color: white !important;
}

.text-hover-cinnabar:hover {
  color: #e62d2d !important;
}

.text-hover-summerSky:hover {
  color: #30b9e4 !important;
}

.font-RobotoRegular {
  font-family: "RobotoRegular" !important;
}

.font-RobotoMedium {
  font-family: "RobotoMedium" !important;
}

.font-RobotoBold {
  font-family: "RobotoBold" !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.75 !important;
}

.lh-xl {
  line-height: 2 !important;
}

.lh-xxl {
  line-height: 2.25 !important;
}

.lh-140 {
  line-height: 140% !important;
}

.indent-5px {
  text-indent: 5px;
}

@media (min-width: 576px) {
  .d-sm-block {
    display: block !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .bg-white-opacity-sm-74 {
    background-color: rgba(255, 255, 255, 0.74) !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
}

@media (min-width: 992px) {


  .d-lg-none {
    display: none !important;
  }
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
}

.card-image {
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 0 48% 0;
}

.card-image.wants-img {
  padding: 0;
  background: #fff;
  display: flex;
  min-height: 216px;
  justify-content: center;
  align-items: center;
}

.card-image.wants-img img {
  position: relative;
  width: 178px;
  height: 160px;
}

.card-image img {
  display: inline;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.4s ease 0s;
}

.card-image img:hover {
  transform: scale(1.1);
}

.card-content {
  padding: 16px 13px 13px 14px;
  display: flex;
  justify-content: space-between;
  background-color: #DCE6E8;
  border-left: 9px solid #2b4764;
  transition: all 0.4s ease 0s;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.card-text {
  display: flex;
  flex-direction: column;
  color: #404040;
  justify-content: space-between;
}

.card-wants {
  margin: 0;
  font-family: "RobotoMedium";
  font-size: 14px;
  line-height: 140%;
  color: #2B4764;
}

.card-name {
  font-family: "RobotoMedium";
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  margin: 0;
  transition: color 0.4s ease 0s;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.card-name:hover {
  color: #6592C1;
}

.card-price {
  margin: 0;
  font-size: 18px;
  line-height: 140%;
}

.card-target {
  margin: 0;
  font-size: 14px;
  line-height: 140%;
}

.card-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.card-flag {
  background: #FFFFFF;
  border: 1px solid #9FB5CB;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease 0s;
  width: 40px;
  height: 40px;
}

.card-flag svg {
  flex: 0 0 auto;
}

.card-buttons .card-setting:hover {
  background: #9FB5CB !important;
}

.card-buttons .card-setting:hover span {
  background: #2B4764 !important;
}

.card-setting {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #9FB5CB;
  cursor: pointer;
  transition: all 0.4s ease 0s;
}

.card-setting span {
  display: block;
  width: 4px;
  height: 4px;
  background-color: #2B4764;
}

.forma__title {
  margin: 0;
  color: #294262;
  font-size: 26px;
  font-weight: 900;
  line-height: 100%;
  text-align: center;
}

@media (min-width: 800px) {
  .forma__title {
    font-size: 34px;
  }
}

.forma__subtitle {
  line-height: 20px;
  margin: 20px 0 10px;
  font-weight: 400;
  color: #2394E7;
}

.forma__section {
  margin-bottom: 20px;
}

.forma__section.error .field__icon {
  fill: #C13838;
}

.forma__section.error .field__icon--email {
  fill: transparent;
  stroke: #C13838;
}

.forma__section.error .forma__input {
  border: 2px solid #C13838;
}

.forma__field {
  position: relative;
}

.forma__field .field__icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
  fill: #BCBCBC;
  z-index: 1;
}

.forma__field .field__icon--email {
  fill: transparent;
  stroke: #BCBCBC;
}

.forma__input {
  width: 100%;
  background: #F8F8F8;
  border: 1px solid #727272;
  padding: 6px 15px 6px 36px;
  font-size: 18px;
  line-height: 140%;
  color: #434343;
  -webkit-appearance: none;
  border-radius: 0 !important;
  outline: 1px solid transparent;
  transition: background-color 0.4s ease 0s;
}

.forma__input::-moz-placeholder {
  color: #535353;
}

.forma__input:-ms-input-placeholder {
  color: #535353;
}

.forma__input::placeholder {
  color: #535353;
}

.forma__button {
  line-height: 16px;
  color: #F8F8F8;
}

.forma__btn {
  padding: 12px;
  display: block;
  width: 100%;
  background: #2B4764;
  transition: all 0.4s ease 0s;
}

.forma__btn:hover {
  background: #2394E7;
}

.error__label {
  position: relative;
  left: 0;
  top: 0;
  margin: 10px 0 0;
  color: #C13838;
}

.error__label span {
  padding-left: 20px;
  display: block;
}

.error__label::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  width: 13px;
  height: 10px;
  background: url("/images/authorization/attention.svg");
}

.select__body {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}

.select__body::before {
  content: "";
  position: absolute;
  right: 10px;
  top: 13px;
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #535353;
  border-right: 2px solid #535353;
  margin-right: 10px;
  transform: rotate(45deg);
  transition: all 0.4s ease 0s;
  pointer-events: none;
}

.select__body.active::before {
  transform: rotate(-135deg);
  right: 8px;
  top: 19px;
}

.select__field {
  text-align: left;
  width: 100%;
  background: #F8F8F8;
  border: 1px solid #727272;
  font-size: 18px;
  line-height: 140%;
  color: #434343;
  padding: 6px 15px 6px 16px;
}

.select__field--placeholder {
  color: #535353;
}

.select__values {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 11;
}

.select__options {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #727272;
}

.select__option {
  text-align: left;
  background: #F8F8F8;
  border: 1px solid rgba(114, 114, 114, 0.1);
  font-size: 18px;
  line-height: 140%;
  color: #434343;
  padding: 6px 15px 6px 16px;
  transition: font-weight 1s ease 0s;
}

.select__option.active {
  font-weight: 700;
}

.select__placeholder {
  color: #535353;
}

.checkbox__element {
  display: inline-flex;
  position: relative;
  left: 0;
  top: 0;
  padding-left: 30px;
  padding-top: 5px;
  line-height: 16px;
  color: #535353;
  cursor: pointer;
}

.checkbox__element p {
  margin: 0;
}

.checkbox__element a {
  color: #2394E7;
  transition: all 0.4s ease 0s;
}

.checkbox__element a:hover {
  color: #30B9E4;
}

.checkbox__element::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
  background: #DCE6E8;
  border: 1px solid #9FB5CB;
  border-radius: 5px;
  transition: all 0.4s ease 0s;
}

.checkbox__element::after {
  content: "";
  display: block;
  width: 17px;
  height: 13px;
  background: url("/images/other/checkbox.svg") no-repeat;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
}

.checkbox__element.active::after {
  opacity: 1;
  visibility: visible;
}

.checkbox__element.active::before {
  border: 1px solid #2B4764;
  background: #2B4764;
}

.fav-btn {
  transition: all 0.4s ease 0s;
}

.fav-btn:not(.card-flag) svg path {
  transition: all 0.4s ease 0s;
  fill: transparent;
}

.fav-btn:not(.card-flag) svg path:first-child {
  fill: #2B4764;
  stroke: #2B4764;
}

@media (any-hover: hover) {
  .fav-btn:not(.card-flag):hover {
    background-color: #2B4764 !important;
  }

  .fav-btn:not(.card-flag):hover svg path {
    fill: transparent;
  }

  .fav-btn:not(.card-flag):hover svg path:first-child {
    fill: #fff;
    stroke: #fff;
  }
}

.fav-btn:not(.card-flag).active {
  background-color: #2B4764 !important;
}

.fav-btn:not(.card-flag).active svg path {
  fill: #fff;
}

.fav-btn:not(.card-flag).active svg path:first-child {
  fill: #fff;
  stroke: #fff;
}

.fav-btn.card-flag svg path {
  fill: #2B4764;
}

.fav-btn.card-flag svg path:first-child {
  fill: #fff;
}

@media (any-hover: hover) {
  .fav-btn.card-flag:hover {
    background: #9FB5CB;
  }

  .fav-btn.card-flag:hover svg path {
    fill: #2B4764;
  }
}

.fav-btn.card-flag.active {
  background-color: #2B4764 !important;
}

.fav-btn.card-flag.active.card-flag {
  border-color: #2B4764;
}

.fav-btn.card-flag.active.card-flag svg path {
  fill: #fff;
}

@media (any-hover: hover) {
  .fav-btn.card-flag.active.card-flag:hover {
    background: #9FB5CB !important;
    border-color: #9FB5CB;
  }
}

.search-field {
  display: block;
  width: 100%;
  flex: 1 1 auto;
  padding: 0 40px 0 10px;
  position: relative;
  color: #2F2F2F;
}

.search-field input {
  width: 100%;
  background: transparent !important;
  border: none;
  -webkit-appearance: none;
  font-size: 18px;
  min-height: 40px;
}

.search-field input::-moz-placeholder {
  font-size: 14px;
  line-height: 140%;
  color: #727272;
}

.search-field input:-ms-input-placeholder {
  font-size: 14px;
  line-height: 140%;
  color: #727272;
}

.search-field input::placeholder {
  font-size: 14px;
  line-height: 140%;
  color: #727272;
}

.search-field .search-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.search-field .search-btn:hover svg {
  fill: #2F2F2F;
}

.search-field .search-btn svg {
  fill: #BCBCBC;
  transition: all 0.2s ease 0s;
  flex: 0 0 auto;
}

.search-field .search-btn-big {
  right: -1px;
  height: 42px;
  background: #2B4764;
  min-width: 130px;
  text-transform: uppercase;
  color: #EEEEEE;
}

.search-field .search-cancel {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.search-field .search-cancel span {
  position: relative;
  display: block;
  width: 15px;
  height: 15px;
  flex: 0 0 auto;
}

.search-field .search-cancel span::before,
.search-field .search-cancel span::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: #2B4764;
  transition: background 0.4s ease 0s;
}

.search-field .search-cancel span::after {
  bottom: 6px;
  transform: rotate(-45deg);
}

.search-field .search-cancel span::before {
  top: 7px;
  transform: rotate(45deg);
}

.search-field .search-cancel:hover span::after,
.search-field .search-cancel:hover span::before {
  background: #6592C1;
}

.search-field.active {
  padding: 0 40px 0 10px !important;
}

.search-field.active .search-btn {
  display: none !important;
}

.search-field.active .search-cancel {
  display: flex;
}

.search-field .search-list {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  top: 50px;
  background: #fff;
  z-index: 1000;
  max-height: 160px;
  overflow: auto;
}

.search-field .search-list.active {
  display: block;
}

.search-field .search-list .search-item {
  position: relative;
  min-height: 40px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 140%;
  padding-left: 34px;
  transition: background 0.4s ease 0s;
}

.search-field .search-list .search-item.active {
  background: #DCE6E8;
}

.search-field .search-list .search-item .search-link {
  padding-left: 34px;
  display: block;
  width: 100%;
  color: #2F2F2F;
  transition: color 0.4s ease 0s;
}

.search-field .search-list .search-item .search-link:hover {
  color: #507DAC;
}

.search-field .search-list .search-item.search-user {
  padding-left: 0;
}

.search-field .search-list .search-item.search-user img {
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  pointer-events: none;
}

.search-field .search-list .search-item.no-find {
  display: none;
}

.search-field .search-list .search-item.no-find.visible {
  display: flex;
}

.search-field .search-list .search-item.no-find::before {
  display: none;
}

.modal__window {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  cursor: pointer;
  transition: all 0.8s ease 0s;
  background-color: rgba(30, 33, 44, 0.75);
}

.modal__window.active {
  opacity: 1;
  visibility: visible;
}

.modal__window.active .modal__content {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px);
}

.modal__body {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
}

.modal__content {
  background: #fff;
  flex: 0 1 740px;
  padding: 50px;
  position: relative;
  opacity: 0;
  visibility: hidden;
  transform: translate(0px, -100%);
  transition: all 0.8s ease 0s;
  cursor: auto;
}

.radio__element {
  position: relative;
  display: inline-flex;
  left: 0;
  top: 0;
  padding-left: 32px;
  cursor: pointer;
}

.radio__element::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(0, -50%);
  border: solid 2px #727272;
}

.radio__element::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2B4764;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
}

.radio__element.active::after {
  opacity: 1;
  visibility: visible;
}

.radio__element.active::before {
  border-color: #2B4764;
}

.radio-secondary .radio__element::before {
  border: 1px solid #9fb5cb;
  background: #dce6e8;
}

.radio-secondary .radio__element.active::before {
  border: 2px solid #2b4764;
  background: transparent;
}

.hiddening {
  -webkit-animation: hiddening 0.4s linear 1 normal running 0s forwards;
  animation: hiddening 0.4s linear 1 normal running 0s forwards;
}

@-webkit-keyframes hiddening {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes hiddening {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.listing-nav {
  display: flex;
  -moz-column-gap: 5px;
  column-gap: 5px;
}

@media (min-width: 985px) {
  .listing-nav {
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
}

.listing-nav-btn {
  font-family: "RobotoMedium";
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #2B4764;
  background: transparent;
  border: 1px solid #9FB5CB;
  min-width: 60px;
  min-height: 60px;
}

.listing-nav-btn span {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #2B4764;
}

.listing-nav-btn.disabled {
  background-color: #DCE6E8;
  pointer-events: none;
  color: #9FB5CB;
}

.listing-nav-btn.disabled span {
  background-color: #9FB5CB;
}

.previous-page {
  flex: 1 1 auto;
  background-color: transparent;
}

.previous-page svg {
  fill: #2B4764;
}

.nav-hover {
  transition: all 0.4s ease 0s;
}

.nav-hover:hover {
  background: #2B4764;
  border-color: #2B4764 !important;
  color: #fff;
}

.nav-hover:hover svg {
  fill: #fff;
}

.previous-page.disabled {
  background-color: #DCE6E8;
  pointer-events: none;
}

.previous-page.disabled svg {
  fill: #9FB5CB;
}

.next-page {
  flex: 1 1 auto;
  background-color: transparent;
}

.next-page svg {
  flex: 0 0 10px;
  fill: #2B4764;
}

.next-page.disabled {
  background-color: #DCE6E8;
  pointer-events: none;
}

.next-page.disabled svg {
  fill: #9FB5CB;
}

.menu-parameters {
  position: relative;
}

.menu-parameters.active .menu-list {
  opacity: 1;
  visibility: visible;
}

.menu-parameters.active .menu-btn {
  background-color: #2B4764 !important;
}

.menu-parameters.active .menu-btn span {
  background-color: #fff !important;
}

.menu-parameters.active .menu-btn.card-setting {
  border-color: #2B4764;
}

.menu-parameters.active .profile-settings-menu {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 768px) {
  .profile-settings--right .menu-list {
    left: 48px;
    right: auto;
  }
}

.menu-btn {
  transition: all 0.4s ease 0s;
}

.menu-btn span {
  transition: background-color 0.4s ease 0s;
}

.menu-btn:hover {
  background-color: #2B4764 !important;
}

.menu-btn:hover span {
  background-color: #fff !important;
}

.menu-list {
  top: 0;
  right: 100%;
  margin-right: 10px;
  position: absolute;
  opacity: 0;
  z-index: 11;
  visibility: hidden;
  background: #FFFFFF;
  min-width: 220px;
  border: 1px solid #9FB5CB;
}

.menu-list.menu-list-top {
  top: auto;
  bottom: 100%;
  right: 0;
  margin-right: 0;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .menu-list {
    top: 100%;
    right: 0;
    margin-right: 0;
    margin-top: 10px;
  }
}

.menu-list.menu-message {
  border: none;
  top: 0;
  right: 100%;
  margin-top: 0;
  margin-right: 10px;
}

@media (min-width: 1100px) {

  .menu-list.menu-message a,
  .menu-list.menu-message button {
    padding: 5px 30px;
    font-size: 18px;
  }
}

@media (min-width: 1100px) {
  .menu-list.menu-message {
    min-width: 300px;
  }
}

.menu-list li {
  cursor: pointer;
  color: #2F2F2F;
  transition: background 0.4s ease 0s;
}

.menu-list li:hover {
  background: #DCE6E8;
}

.menu-list li a,
.menu-list li button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 15px;
  color: #2F2F2F;
  min-height: 40px;
  background: transparent;
  font-size: 16px;
  line-height: 140%;
}

/* select2 ===================================================================================*/
.big-select2 .select2 .selection {
  position: relative;
}

.big-select2 .select2-container--default .select2-selection--multiple {
  border-radius: 0;
  border: 1px solid #727272;
  cursor: pointer;
}

.big-select2 .select2-selection.select2-selection--multiple {
  min-height: 80px;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.big-select2 .select2-selection.select2-selection--multiple::before {
  content: "";
  position: absolute;
  right: -1px;
  top: -1px;
  display: block;
  width: 80px;
  height: 80px;
  background: #2B4764;
}

.big-select2 .select2-selection.select2-selection--multiple::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 80px;
  height: 80px;
  background: url("/images/creating/arrow-white.svg") center/14px 12px no-repeat;
  transition: transform 0.4s ease 0s;
}

.big-select2 .select2-container--open .select2-selection.select2-selection--multiple::after {
  transform: rotate(180deg);
}

.big-select2 .select2-selection.select2-selection--multiple .select2-selection__rendered {
  display: none;
}

.big-select2 .select2-search.select2-search--inline {
  width: calc(100% - 80px);
  padding: 10px 25px;
}

.big-select2 .select2-container .select2-search--inline .select2-search__field {
  margin: 0;
  font-size: 18px;
  line-height: 21px;
  color: #2f2f2f;
  min-height: 21px;
}

.big-select2 .select2-container .select2-search--inline .select2-search__field::-moz-placeholder {
  color: #727272;
}

.big-select2 .select2-container .select2-search--inline .select2-search__field:-ms-input-placeholder {
  color: #727272;
}

.big-select2 .select2-container .select2-search--inline .select2-search__field::placeholder {
  color: #727272;
}

/* dropdwon list=============================================================== */
.select2-container--default .select2-results>.select2-results__options {
  max-height: 400px;
}

.select2-dropdown {
  border: none;
}

.select2-results__option {
  padding: 0;
}

.select2-container--open .select2-dropdown {
  margin-top: 10px;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: transparent;
}

.select2-results__option .list-item {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -moz-column-gap: 15px;
  column-gap: 15px;
  padding: 0 15px;
  padding-right: 45px;
  min-height: 80px;
  background: #F8F8F8;
  border-top: 1px solid #9FB5CB;
  border-bottom: 1px solid #9FB5CB;
}

@media (min-width: 575px) {
  .select2-results__option .list-item {
    -moz-column-gap: 35px;
    column-gap: 35px;
  }
}

.select2-results__option .item-img {
  border-radius: 50%;
  overflow: hidden;
  cursor: auto;
}

.select2-results__option .item-img img {
  width: 50px;
  height: 50px;
}

.select2-results__option .item-text {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  cursor: auto;
  font-family: "RobotoMedium";
  font-size: 18px;
  line-height: 21px;
  color: #535353;
}

.select2-results__option .text-subtitle {
  font-family: "RobotoRegular";
  font-size: 14px;
  line-height: 16px;
}

.select2-results__option .btn-container {
  position: absolute;
  right: 20px;
  height: 20px;
  width: 20px;
}

.select2-results__option .checkbox-btn {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  background: #DCE6E8;
  border: 1px solid #9FB5CB;
  border-radius: 5px;
  transition: all 0.4s ease 0s;
}

.select2-results__option .checkbox-btn::before {
  content: "";
  display: block;
  width: 17px;
  height: 13px;
  background: url("/images/other/checkbox.svg") no-repeat;
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
}

.select2-results__option--selected .checkbox-btn {
  background: #2B4764;
  border: 1px solid #2B4764;
}

.select2-results__option--selected .checkbox-btn::before {
  opacity: 1;
  visibility: visible;
}

/* dropdwon list end=============================================================== */
/* choose list ======================================== */
.selected2 {
  margin-top: 20px;
}

.selected2 ul {
  display: flex !important;
  flex-wrap: wrap;
  gap: 10px;
}

.selected2 .select2-selection__choice {
  display: flex;
  flex-direction: row-reverse;
  -moz-column-gap: 10px;
  column-gap: 10px;
  align-items: center;
  background: #fff;
  border: 1px solid #9FB5CB;
  border-radius: 33px;
  position: relative;
  padding: 10px 20px !important;
  transition: all 0.4s ease 0s;
  cursor: pointer;
  color: #727272;
  line-height: 140%;
}

.selected2 .select2-selection__choice:hover {
  border: 1px solid #E62D2D;
  color: #E62D2D;
}

.selected2 .select2-selection__choice__remove {
  padding: 0;
  background: transparent;
  display: block;
}

.selected2 .select2-selection__choice__remove span {
  position: relative;
  font-size: 0;
  display: block;
  width: 12px;
  height: 12px;
}

.selected2 .select2-selection__choice__remove span::after,
.selected2 .select2-selection__choice__remove span::before {
  content: "";
  display: block;
  position: absolute;
  background: #E62D2D;
  width: 100%;
  height: 1px;
  transition: all 0.4s ease 0s;
}

.selected2 .select2-selection__choice__remove span::before {
  top: 6px;
  transform: rotate(-45deg);
}

.selected2 .select2-selection__choice__remove span::after {
  bottom: 5px;
  transform: rotate(45deg);
}

.selected2 .select2-selection__choice:hover .select2-selection__choice__remove span::before {
  transform: rotate(-45deg) scale(1.6);
}

.selected2 .select2-selection__choice:hover .select2-selection__choice__remove span::after {
  transform: rotate(45deg) scale(1.6);
}

/* choose list end======================================== */
.big-select2 .select2-container--default .select2-selection--single {
  border-radius: 0;
  border: 1px solid #727272;
  cursor: pointer;
}

.big-select2 .select2-selection.select2-selection--single {
  min-height: 80px;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.big-select2 .select2-selection.select2-selection--single::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 80px;
  height: 100%;
  background: #2B4764 url("/images/creating/arrow-white.svg") center/15px 15px no-repeat;
}

.big-select2.single .select2-selection--clearable.select2-selection.select2-selection--single::before {
  display: none;
}

@media (min-width: 992px) {
  .big-select2.single .select2-selection--clearable.select2-selection.select2-selection--single::before {
    display: flex;
  }
}

.big-select2.single .select2-selection__arrow {
  display: none;
}

.big-select2.single .select2-selection__rendered {
  display: block;
  width: calc(100% - 80px);
  font-size: 18px;
  line-height: 21px;
  color: #727272;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 992px) {
  .big-select2.single .select2-selection__rendered {
    padding-left: 25px;
  }
}

.big-select2.single .select2-selection__clear {
  order: 1;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  background: #C13838;
  border-radius: 5px;
  transition: background 0.4s ease 0s;
  pointer-events: auto;
}

@media (min-width: 992px) {
  .big-select2.single .select2-selection__clear {
    margin-right: 25px;
  }
}

.big-select2.single .select2-selection__clear:hover {
  background: #E46666;
}

.big-select2.single .select2-selection__clear::before,
.big-select2.single .select2-selection__clear::after {
  content: "";
  display: block;
  position: absolute;
  width: 28px;
  height: 4px;
  background: #fff;
}

.big-select2.single .select2-selection__clear::before {
  transform: rotate(45deg) translateY(-50%);
  right: 7px;
  top: 19px;
}

.big-select2.single .select2-selection__clear::after {
  transform: rotate(-45deg) translateY(-50%);
  left: 7px;
  bottom: 17px;
}

.big-select2.single .select2-selection__clear span {
  display: none;
}

.big-select2.single .select2-selection--clearable .select2-selection__rendered {
  width: 100%;
}

@media (min-width: 992px) {
  .big-select2.single .select2-selection--clearable .select2-selection__rendered {
    width: calc(100% - 145px);
  }
}

.big-select2.single .select2-selection__rendered .list-item {
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 10px;
  align-items: center;
}

@media (min-width: 992px) {
  .big-select2.single .select2-selection__rendered .list-item {
    -moz-column-gap: 35px;
    column-gap: 35px;
  }
}

/* select2 end===================================================================================*/
.message-alert {
  position: fixed;
  right: 5px;
  top: 80px;
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 576px) {
  .message-alert {
    right: 40px;
    top: 120px;
  }
}

.message-alert.active {
  opacity: 1;
  visibility: visible;
}

.alert-content {
  padding: 25px;
  position: relative;
  z-index: 2;
}

.alert-content .alert-cross {
  position: absolute;
  top: 10px;
  right: 10px;
}

.alert-content span {
  position: relative;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.alert-content span::before,
.alert-content span::after {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 1px;
  background: #fff;
}

.alert-content span::before {
  top: 9px;
  transform: rotate(45deg);
}

.alert-content span::after {
  bottom: 10px;
  transform: rotate(-45deg);
}

.alert-content p {
  max-width: 225px;
  margin: 0;
  font-size: 16px;
  line-height: 140%;
  color: #FFFFFF;
}

@media (min-width: 992px) {
  .alert-content {
    padding: 40px;
  }

  .alert-content p {
    max-width: 316px;
    font-size: 20px;
  }
}

.ask-question {
  background: #fff;
  padding: 40px 20px;
}

@media (min-width: 992px) {
  .ask-question {
    padding: 40px;
  }
}

.ask-question .forma__section {
  margin-bottom: 30px !important;
}

.ask-question .forma__section.textarea {
  margin-top: 0;
}

.ask-question .forma__section .forma__input {
  outline: 1px solid transparent;
}

.ask-question .forma__section:focus-within .field__icon {
  fill: #2B4764;
}

.ask-question .forma__section:focus-within .field__icon--email {
  fill: transparent;
  stroke: #2B4764;
}

.ask-question .forma__section:focus-within .forma__input {
  border-color: #2B4764;
  outline-color: #2B4764;
}

.ask-question .forma__section .forma__input {
  border: 1px solid #9FB5CB;
}

.ask-question .forma__section.filled .field__icon {
  fill: #2B4764;
}

.ask-question .forma__section.filled .field__icon--email {
  fill: transparent;
  stroke: #2B4764;
}

.ask-question .forma__section.filled .forma__input {
  color: #2B4764;
  border: 1px solid #DCE6E8;
}

.title-404 {
  line-height: 108%;
  font-size: 30px;
  text-align: center;
  font-family: "RobotoMedium";
  color: #507DAC;
}

@media (min-width: 576px) {
  .title-404 {
    font-size: 48px;
  }
}

.text-404 {
  text-align: center;
  font-size: 20px;
  font-family: "RobotoMedium";
  color: #535353;
}

@media (min-width: 576px) {
  .text-404 {
    font-size: 34px;
  }
}

.img-404 img {
  max-width: 250px;
  width: 100%;
}

@media (min-width: 576px) {
  .img-404 img {
    width: 380px;
    max-width: 380px;
  }
}

.to-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DCE6E8;
  border-radius: 5px;
  width: 26px;
  height: 26px;
  flex: 0 0 auto;
  transition: background-color 0.4s ease 0s;
}

.to-profile:hover {
  background-color: #cbe8ee;
}

.to-profile img {
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .to-profile {
    display: none;
  }
}

@media (max-width: 767px) {
  .content-page.hidden {
    display: none;
  }
}

.listing-search-sort .select__body {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}

.listing-search-sort .select__body::before {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 40px;
  height: 100%;
  background: #2B4764;
  border-bottom: none;
  border-right: none;
  margin-right: 0;
  transform: rotate(0deg);
  transition: all 0.4s ease 0s;
  pointer-events: none;
}

.listing-search-sort .select__body::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 40px;
  height: 100%;
  background: url("/images/profile/listing/select-arrow.svg") center no-repeat;
  transition: all 0.4s ease 0s;
  pointer-events: none;
}

.listing-search-sort .select__body.active::before {
  transform: rotate(0deg);
  right: 0px;
  top: 0px;
}

.listing-search-sort .select__body.active::after {
  transform: rotate(180deg);
}

.listing-search-sort .select__field {
  text-align: left;
  width: 100%;
  min-height: 40px;
  background: rgba(255, 255, 255, 0.74);
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 18px;
  font-family: "RobotoRegular";
  line-height: 140%;
  color: #434343;
  padding: 6px 46px 6px 16px;
}

.listing-search-sort .select__field--placeholder {
  color: #727272;
  font-size: 14px;
}

.listing-search-sort .select__values {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 11;
}

.listing-search-sort .select__options {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  border: none;
  margin-top: 10px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

.listing-search-sort .select__option {
  text-align: left;
  background: #F8F8F8;
  border: none;
  font-size: 14px;
  font-family: "RobotoMedium";
  line-height: 140%;
  min-height: 40px;
  color: #535353;
  padding: 6px 15px 6px 24px;
  transition: font-weight 1s ease 0s, background 0.5s ease 0s;
}

.listing-search-sort .select__option.active {
  font-weight: 700;
  background: #DCE6E8;
}

.listing-search-sort .select__option:hover {
  background: #DCE6E8;
}

.listing-search-sort .select__placeholder {
  color: #727272;
}

.listing-search-sort input {
  color: #434343;
  font-size: 18px;
}

.listing-search-sort input::-moz-placeholder {
  color: #727272;
  font-size: 14px;
}

.listing-search-sort input:-ms-input-placeholder {
  color: #727272;
  font-size: 14px;
}

.listing-search-sort input::placeholder {
  color: #727272;
  font-size: 14px;
}

.listing-search-sort .listing-search .select__body::after {
  display: none;
}

@media (min-width: 992px) {
  .listing-search-sort .search-field {
    padding: 0 130px 0 10px;
  }
}

.sort-listing .select__options {
  max-height: 100% !important;
  overflow-y: hidden !important;
}

.interaction-arpw {
  transition: all 0.4s ease 0s !important;
  border: 1px solid transparent !important;
}

.interaction-arpw:hover {
  background-color: #9FB5CB !important;
}

.interaction-arpw:active {
  border-color: #2B4764 !important;
  color: #2B4764 !important;
}

.interaction-white {
  transition: all 0.4s ease 0s !important;
}

.interaction-white:hover {
  background-color: #9FB5CB !important;
  border-color: #9FB5CB !important;
}

.interaction-white:active {
  color: #2B4764 !important;
}

.interaction-trnsp-mthrn {
  transition: all 0.4s ease 0s !important;
}

.interaction-trnsp-mthrn span {
  transition: color 0.4s ease 0s !important;
}

.interaction-trnsp-mthrn:hover {
  background-color: #9FB5CB !important;
}

.interaction-trnsp-mthrn:hover span {
  color: rgba(255, 255, 255, 0.74) !important;
}

.interaction-trnsp-mthrn:active {
  background-color: #2B4764 !important;
  border-color: #2B4764 !important;
  color: #fff !important;
}

.interaction-trnsp-arpw {
  transition: all 0.4s ease 0s !important;
}

.interaction-trnsp-arpw span {
  transition: color 0.4s ease 0s !important;
}

.interaction-trnsp-arpw:hover {
  background-color: #9FB5CB !important;
}

.interaction-trnsp-arpw:hover span {
  color: rgba(255, 255, 255, 0.74) !important;
}

.interaction-trnsp-arpw:active {
  background-color: #2B4764 !important;
  border-color: #2B4764 !important;
  color: #fff !important;
}

.interaction-prsnrd {
  transition: all 0.4s ease 0s !important;
  border: 1px solid transparent !important;
}

.interaction-prsnrd svg {
  fill: #fff;
}

.interaction-prsnrd:hover {
  background-color: #E46666 !important;
}

.interaction-prsnrd:active {
  background-color: #DCE6E8 !important;
  color: #C13838 !important;
}

.interaction-prsnrd:active svg {
  fill: #C13838;
}

.loading {
  position: relative;
}

.loading .preloader {
  display: block;
}

.loading .preloader-element {
  -webkit-animation: 1s spinning infinite;
  animation: 1s spinning infinite;
}

.loading .preloader-element::before {
  -webkit-animation: 1s movingRight infinite;
  animation: 1s movingRight infinite;
}

.loading .preloader-element::after {
  -webkit-animation-name: movingLeft;
  animation-name: movingLeft;
}

.preloader {
  display: none;
  position: absolute;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.preloader::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("/images/other/main-bg.png");
  opacity: 0.75;
}

.preloader-element {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 45px;
  height: 45px;
}

.preloader-element::before,
.preloader-element::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border: 15px solid #2B4764;
  border-top: none;
  border-bottom: none;
  margin-bottom: 15px;
  box-sizing: content-box !important;
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  40%,
  100% {
    transform: rotate(90deg);
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  40%,
  100% {
    transform: rotate(90deg);
  }
}

@-webkit-keyframes movingLeft {
  0% {
    transform: translateX(0%);
  }

  40% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes movingLeft {
  0% {
    transform: translateX(0%);
  }

  40% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes movingRight {
  0% {
    transform: translateX(0%);
  }

  40% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes movingRight {
  0% {
    transform: translateX(0%);
  }

  40% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

.text-dodgerBlue {
  color: #2394e7 !important;
}

.break-words {
  word-break: break-all !important;
}

.border-dimgray {
  border-color: #727272 !important;
}

.modal__chest {
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 20px;
  height: 20px;
  background-color: transparent;
}

.modal__chest::before,
.modal__chest::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  background-color: #787A80;
  transition: background-color 0.4s ease 0s;
}

.modal__chest::before {
  top: 9px;
  transform: rotate(45deg);
}

.modal__chest::after {
  bottom: 8px;
  transform: rotate(-45deg);
}

.modal__chest:hover::after,
.modal__chest:hover::before {
  background: #6592C1;
}

.select-main .select__body {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}

.select-main .select__body::before {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 40px;
  height: 100%;
  background: #2B4764;
  border-bottom: none;
  border-right: none;
  margin-right: 0;
  transform: rotate(0deg);
  transition: all 0.4s ease 0s;
  pointer-events: none;
}

.select-main .select__body::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 40px;
  height: 100%;
  background: url("/images/profile/listing/select-arrow.svg") center no-repeat;
  transition: all 0.4s ease 0s;
  pointer-events: none;
}

.select-main .select__body.active::before {
  transform: rotate(0deg);
  right: 0px;
  top: 0px;
}

.select-main .select__body.active::after {
  transform: rotate(180deg);
}

.select-main .select__field {
  text-align: left;
  width: 100%;
  min-height: 40px;
  background: rgba(255, 255, 255, 0.74);
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 18px;
  font-family: "RobotoRegular";
  line-height: 140%;
  color: #535353;
  padding: 6px 46px 6px 16px;
}

.select-main .select__field--placeholder {
  color: #727272;
  font-size: 14px;
}

.select-main .select__values {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 5;
}

.select-main .select__options {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  border: none;
  margin-top: 10px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

.select-main .select__option {
  text-align: left;
  background: #F8F8F8;
  border: none;
  font-size: 14px;
  font-family: "RobotoMedium";
  line-height: 140%;
  min-height: 40px;
  color: #434343;
  padding: 6px 15px 6px 24px;
  transition: font-weight 1s ease 0s, background 0.5s ease 0s;
}

.select-main .select__option.active {
  font-weight: 700;
  background: #DCE6E8;
}

.select-main .select__option:hover {
  background: #DCE6E8;
}

.select-main .select__placeholder {
  color: #727272;
}

.select-plan .select__options {
  max-height: 160px;
}

.textarea {
  position: relative;
}

.textarea span {
  position: absolute;
  right: 14px;
  bottom: 8px;
  font-size: 14px;
  line-height: 140%;
  color: #BCBCBC;
}

.textarea textarea {
  resize: none;
  width: 100%;
  height: 225px;
  padding: 12px 14px !important;
  font-size: 18px;
  line-height: 140%;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.textarea textarea:focus {
  outline-color: #2B4764;
  border-color: #2B4764;
}

.get-lead_request-new .forma__input::-moz-placeholder {
  color: #727272;
}

.get-lead_request-new .forma__input:-ms-input-placeholder {
  color: #727272;
}

.get-lead_request-new .forma__input::placeholder {
  color: #727272;
}

.get-lead_request-new .select-main .select__field {
  background: #F8F8F8;
}

.get-lead_request-new .select-main .select__field--placeholder {
  font-size: 18px;
}

.get-lead_request-new .checkbox__element {
  padding-top: 0;
}

.get-lead_request-new .forma__section:focus-within .field__icon {
  fill: #2B4764;
}

.get-lead_request-new .forma__section:focus-within .field__icon--email {
  fill: transparent;
  stroke: #2B4764;
}

.get-lead_request-new .forma__section:focus-within .forma__input {
  border-color: #2B4764;
  outline-color: #2B4764;
}

.checkbox__element.top::before {
  top: 0;
  transform: translateY(0);
}

.checkbox__element.top::after {
  top: 3px;
  transform: translateY(0);
}

.modal__window.lead_request .modal__content {
  flex: 0 1 680px;
}

@media (max-width: 600px) {
  .modal__window.lead_request .modal__content {
    padding: 50px 20px;
  }
}

.to-profile.to-profile-big {
  display: flex !important;
  width: 40px;
  height: 40px;
}

.to-profile.to-profile-big svg {
  fill: #2B4764;
  width: 15px;
  height: auto;
  transform: rotate(180deg);
}

.to-profile.to-profile-big:hover {
  background-color: #2B4764;
}

.to-profile.to-profile-big:hover svg {
  fill: #fff;
}

.pac-container {
  z-index: 100000;
}

.bg-solitude-1 {
  background-color: #e8f0fb !important;
}

.card-label {
  font-size: 18px;
  line-height: 140%;
  background-color: #2B4764;
  padding: 5px 10px;
  position: absolute;
  color: #fff;
  z-index: 2;
  left: 0;
  top: 0;
  font-family: "RobotoBold";
  text-transform: uppercase;
}

.tooltip {
  position: relative;
}

@media (any-hover: hover) {
  .tooltip:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
}

.tooltip-always:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.tooltip-content {
  pointer-events: none;
  visibility: hidden;
  width: 120px;
  background-color: #2B4764;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 125%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2B4764 transparent;
}

.tooltip-bottom-left .tooltip-content {
  right: 0;
  left: auto;
  transform: none;
}

.tooltip-bottom-left .tooltip-content::after {
  right: 15px;
  left: auto;
  transform: none;
}

.tooltip-content-analytics {
  background-color: #dce6e8;
  color: #2b4764;
}

.tooltip-content-analytics::after {
  border-color: transparent transparent #dce6e8 transparent;
}

.map-mark {
  position: fixed;
  z-index: 6;
  top: 70px;
  width: 100%;
  margin-right: 60px;
  right: 0;
  max-width: calc(40% - 270px);
}

@media(max-width:991px) {
  .map-mark {
    max-width: calc(100% - 270px);
  }
}

@media(max-width:500px) {
  .map-mark {
    top: 120px;
    max-width: 80%;
    margin-right: 0;
    right: 50%;
    transform: translate(50%, 0);
  }
}

@media(min-width:1600px) {
  .map-mark {
    max-width: calc(100% - 1245px);
  }
}

@media(min-width:1024px) {
  .map-mark {
    top: 110px;
  }
}

.map-mark span {
  background: none padding-box padding-box #fff;
  display: block;
  padding: 10px;
  font-size: 18px;
  max-width: fit-content;
  color: #565656;
  margin: 0 auto;
  text-align: center;
  box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px;
}

.to-admin-bar svg {
  fill: #2B4764;
}

.to-admin-bar span {
  transition: color 0.4s ease 0s;
}

.to-admin-bar:hover svg {
  fill: #30B9E4;
}

.to-admin-bar:hover span {
  color: #30B9E4 !important;
}

@media (max-width: 767px) {
  .cookie {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

.fixed-block {
  position: -webkit-sticky;
  position: sticky;
  /* top: 100px; */
  z-index: 99998;
}

/* @media (max-width: 1023px) {
  .fixed-block {
    top: 60px;
  }
} */

.rotate-180 {
  transform: rotate(180deg);
}

@media (min-width: 1024px) {
  .admin-active .edit-profile {
    padding-top: 155px;
  }

  .cookie-active .edit-profile {
    padding-top: 196px;
  }

  .admin-active.cookie-active .edit-profile {
    padding-top: 251px;
  }

  .cookie-active .listing-map {
    height: calc(100% - 196px);
  }

  .cookie-active .map-mark {
    top: 206px;
  }

  .admin-active .listing-map {
    height: calc(100% - 155px);
  }

  .admin-active .map-mark {
    top: 165px;
  }

  .admin-active.cookie-active .listing-map {
    height: calc(100% - 251px);
  }

  .admin-active.cookie-active .map-mark {
    top: 261px;
  }
}

@media (max-width: 1023px) {
  .cookie-active .main-header .nav-wrap {
    top: 96px;
    height: calc(100% - 96px);
  }

  .admin-active .main-header .nav-wrap {
    top: 60px;
    height: calc(100% - 55px);
  }

  .admin-active.cookie-active .main-header .nav-wrap {
    top: 156px;
    height: calc(100% - 151px);
  }

  .cookie-active .listing-map {
    height: calc(100% - 156px);
  }

  .admin-active .edit-profile {
    padding-top: 115px;
  }

  .admin-active.cookie-active .edit-profile {
    padding-top: 211px;
  }

  .cookie-active .edit-profile {
    padding-top: 156px;
  }

  .admin-active.cookie-active .map-mark {
    top: 221px;
  }

  .admin-active.cookie-active .listing-map {
    height: calc(100% - 211px);
  }

  .admin-active .map-mark {
    top: 125px;
  }

  .admin-active .listing-map {
    height: calc(100% - 115px);
  }

  .cookie-active .map-mark {
    top: 166px;
  }
}

@media (max-width: 767px) {
  .admin-active .edit-profile {
    padding-top: 115px;
  }

  .admin-active.cookie-active .edit-profile {
    padding-top: 115px;
  }

  .admin-active.cookie-active .listing-map {
    height: calc(100% - 115px);
  }

  .cookie-active .edit-profile {
    padding-top: 60px;
  }

  .admin-active.cookie-active .map-mark {
    top: 125px;
  }

  .cookie-active .map-mark {
    top: 70px;
  }

  .cookie-active .listing-map {
    height: calc(100% - 60px);
  }

  .cookie-active .main-header .nav-wrap {
    top: 0;
    height: 100%;
  }

  .admin-active .main-header .nav-wrap {
    top: 55px;
    height: calc(100% - 55px);
  }

  .admin-active.cookie-active .main-header .nav-wrap {
    top: 55px;
    height: calc(100% - 55px);
  }
}

@media (max-width: 500px) {
  .cookie-active .map-mark {
    top: 120px;
  }

  .admin-active.cookie-active .map-mark {
    top: 175px;
  }

  .admin-active .map-mark {
    top: 175px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.forma__input:focus {
  border-color: #294262;
  outline-color: #294262;
  background-color: #F8F8F8;
}

.forma__input:hover {
  background-color: #DCE6E8;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.forma__field:has(input:not(:-moz-placeholder-shown)) .field__icon {
  fill: #2B4764;
}

.forma__field:has(input:not(:-ms-input-placeholder)) .field__icon {
  fill: #2B4764;
}

.forma__field:has(input:not(:placeholder-shown)) .field__icon {
  fill: #2B4764;
}

.forma__field:has(input:not(:-moz-placeholder-shown)) .field__icon--email {
  fill: transparent;
  stroke: #2B4764;
}

.forma__field:has(input:not(:-ms-input-placeholder)) .field__icon--email {
  fill: transparent;
  stroke: #2B4764;
}

.forma__field:has(input:not(:placeholder-shown)) .field__icon--email {
  fill: transparent;
  stroke: #2B4764;
}

.forma__field:focus-within .field__icon {
  fill: #2B4764;
}

.forma__field:focus-within .field__icon--email {
  fill: transparent;
  stroke: #2B4764;
}

@media(min-width:1025px) {
  .flex-vlg-row {
    flex-direction: row !important;
  }
}

.banner-image {
  padding: 0 0 74%;
}

.no-events {
  pointer-events: none;
}

.text-mulled {
  color: #515055 !important;
}

.card-premium {
  position: relative;
  border: 3px solid transparent;
  -o-border-image: linear-gradient(225deg, #274666, #78DFFF, #274666) 1;
  border-image: linear-gradient(225deg, #274666, #78DFFF, #274666) 1;
}

.card-premium .card-content {
  border: none;
  background: radial-gradient(112% 1243.15% at 97.3% -20.4%, #2B4764 0%, #0B3344 36.52%, #2B4764 65.87%, #0E253D 92.19%, #2B4764 100%);
}

.card-premium .card-text {
  color: #fff !important;
}

.card-premium .card-text a,
.card-premium .card-text button[type=submit] {
  color: inherit !important;
}

@media (any-hover: hover) {

  .card-premium .card-text a:hover,
  .card-premium .card-text button[type=submit]:hover {
    color: #97b9db !important;
  }
}

.card-premium .card-flag,
.card-premium .menu-btn {
  background-color: transparent !important;
}

.card-premium .card-flag svg path {
  fill: #fff !important;
}

.card-premium .card-flag svg path:first-child {
  fill: transparent !important;
}

@media (any-hover: hover) {
  .card-premium .card-flag:hover svg path:first-child {
    fill: #fff !important;
  }
}

.card-premium .card-flag.fav-btn.active {
  background-color: #9FB5CB !important;
  border-color: #9FB5CB !important;
}

.card-premium .card-flag.fav-btn.active svg path {
  fill: #2B4764 !important;
}

.card-premium .menu-btn span {
  background-color: #fff !important;
}

.card-premium .card-target span.text-arapawa {
  color: #00F0FF !important;
}

.card-premium .menu-parameters.active .menu-btn {
  background-color: #9FB5CB !important;
  border-color: #9FB5CB !important;
}

.card-premium .menu-parameters.active .menu-btn span {
  background-color: #2B4764 !important;
}

.listings-list.display-type-row .card-premium .card-image {
  border-left: none;
}

.listings-list.display-type-row .card-premium .card-members {
  left: 0;
}

.btn-to-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  padding: 5px 6px 0;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50%;
}

.btn-to-profile svg {
  max-width: 100%;
  height: auto;
  margin-bottom: -5px;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (max-width: 370px) {
  .tooltip-outsider .tooltip-content {
    right: 0;
    left: auto;
    transform: none;
  }

  .tooltip-outsider .tooltip-content::after {
    right: 15px;
    left: auto;
    transform: none;
  }
}